/**
 * @file _form-item.js
 */
'use strict';

/**
 * @classname FormItem
 * @classdesc 商品登録
 */
export default class FormItem {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor(controller){
		this._controller = controller;
	};


	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormItem
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log('FormItem.preProcessing');
		// バリデーション後に行う処理を記載（開始）


		// バリデーション後に行う処理を記載（終了）
		this._controller.completePreProcessing($form);
	};


	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormItem
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log('FormItem.postProcessing');
		// バリデーション後に行う処理を記載（開始）

		// 公開開始日時
		const dispStartDate = $('#disp_start_date').val();
		$('#disp_start_date').val(`${dispStartDate}:00`);

		// 公開終了日時
		const dispEndDate = $('#disp_end_date').val();
		if(dispEndDate && dispEndDate !== '') $('#disp_end_date').val(`${dispEndDate}:00`);

		// バリデーション後に行う処理を記載（終了）
		this._controller.completePostProcessing($form);
	};
}

/**
 * @file Observer / Listener 系の処理
 */
'use strict';

/**
 * @classname Listener
 * @classdesc Observer / Listner 系、on off trigger を行う
 */
export default class Listener {

	constructor(){
		this._functions = {};
	};

	/**
	 * on
	 *
	 * @memberof Listener
	 * @param {string} name - イベント名
	 * @param {function} fn - 関数
	 * @return {object} - Listener インスタンス
	 */
	on(name, fn){
		this._functions[name] = this._functions[name] || [];
		this._functions[name].push(fn);

		return this;
	}


	/**
	 * off
	 *
	 * @memberof Listener
	 * @param {string} name - イベント名
	 * @param {function} fn - 関数
	 * @return {object} - Listener インスタンス
	 */
	off(name, fn){
		let functions = this._functions[name];
		if (!functions) {
			return this;
		}
		for (let i = 0, l = functions.length; i < l; i++) {
			let _fn = functions[i];
			if (fn === _fn) {
				functions.splice(i, 1);
				break;
			}
		}
		return this;
	}


	/**
	 * Trigger
	 * @param {string} name - 送信するイベント名
	 * @return {object} - Listener インスタンス
	 */
	trigger(name) {
		console.log('trigger: ' + name);
		let functions = this._functions[name] || [];
		for (let i = 0, l = functions.length; i < l; ++i) {
			let fn = functions[i];
			if (fn) {
				fn.apply(this, arguments);
			}
		}
		return this;
	}
}

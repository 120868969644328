/**
 * @file GetMenuTimeView
 *
 */
'use strict';
import ApplyClass from '../../util/_apply-class';
import FormSelect2View from './_form-select2-view';
import FormValidateView from './_form-validate-view';
import FormGetMenuView from './_form-get-menu-view';

/**
 * @classname GetMenuTimeView
 * @classdesc GetMenuTimeView
 */
export default class GetMenuTimeView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log('GetMenuTimeView.constructor');
		this._$e = $e;
		this._controller = controller;
		$e.on('change', () => this.onChange());
		controller.on('getMenuTimeComplete', () => this.setMenuTime());
	};


	/**
	 * 変更のコールバック
	 *
	 * @memberof GetMenuView
	 * @return {undefined}
	 */
	onChange() {
		console.log(`GetMenuView.onChange`);
		$('.menutime-select-wrap').html('<p class="mt8 text">店舗を選択してください。</p>');
		$('.menu-select-wrap').html('<p class="mt8 text">メニュー提供時間帯を選択してください。</p>');
		if($('#shop_id').val() !== ''){
			this.getMenuTime();
		}
	};


	/**
	 * メニュー提供時間帯取得
	 *
	 * @memberof GetMenuTimeView
	 * @return {undefined}
	 */
	getMenuTime() {
		console.log(`GetMenuTimeView.getMenuTime`);
		const shopId = this._$e.val();
		this._controller.getMenuTime(shopId);
	};


	/**
	 * メニュー提供時間帯設定
	 *
	 * @memberof GetMenuTimeView
	 * @return {undefined}
	 */
	setMenuTime() {
		console.log(`GetMenuTimeView.setMenuTime`);
		const menuTime = this._controller._menuTime.menu;
		let options = '<option value="0">未設定メニュー</option>';
		for(let i = 0; i < menuTime.length; i++){
			options += `<option value="${menuTime[i].menu_id}" data-menu-name="${menuTime[i].menu_name}">${menuTime[i].menu_name}（${menuTime[i].receivable_time_from}〜${menuTime[i].receivable_time_to}／L.O.${menuTime[i].order_deadline_time}）</option>`
		}
		const $select = '<select class="form-control validate select2 menu_id get-menu" name="menu_id" id="menu_id" data-validate="empty" multiple></select>';
		$('.menutime-select-wrap').html($select);
		const $menuId = $('#menu_id');
		$menuId.html(options);
		const FormController = this._controller;
		ApplyClass.apply(FormValidateView, $menuId, [$menuId, FormController]);
		ApplyClass.apply(FormSelect2View, $menuId, [$menuId, FormController]);
		ApplyClass.apply(FormGetMenuView, $menuId, [$menuId, FormController]);
	};
}

/**
 * @file multipleで選択された店舗に対応した情報を取得・表示する
 */
'use strict';
import ApplyClass from '../../util/_apply-class';
import FormSelect2View from './_form-select2-view';
import FormValidateView from './_form-validate-view';

export default class FormGetSupporterShopsMultipleView {
    constructor($e, controller){
        console.log('FormGetSupporterShopsMultipleView.constructor');
		this._$e = $e;
		this._controller = controller;
		this.checkShop();
        this._$block = $('.block-os-item');
        $e.on('change', () => this.onChange());
        this._controller.on('openOSBlock', () => this.openOSBlock());
		this._controller.on('closeOSBlock', () => this.closeOSBlock());
        this._controller.on('setMenu', () => this.setMenu());
        this._controller.on('clearShop', () => this.clearShop());
    }


    /**
	 * 店舗選択部分がsingleかmultipleか確認
	 *
	 * @memberof FormGetSupporterShopsMultipleView
	 * @return {undefined}
	 */
	checkShop(){
		console.log('FormGetSupporterShopsMultipleView.checkShop');
		$('#company_id').attr('data-shop-select') == 'multiple'
			? this._multiple = true
			: this._multiple = false
		console.log(this._multiple)
	}


    onChange(){
        console.log('FormGetSupporterShopsMultipleView.onChange');
        if(this._multiple){
            const headsOptions = $('.shop-select-wrap .select2-selection__choice');
            const titles = [];
            for(let i = 0; i < headsOptions.length; i++){
                titles.push($(headsOptions[i]).attr('title'));
            }
            console.log(titles)
            
            const tailsOptions = $('#shop_id option');
            let shopIds= [];
            for(let i = 0; i < headsOptions.length; i++){
                for(let j = 0; j < tailsOptions.length; j++){
                    if(titles[i] === $(tailsOptions[j]).text()) shopIds.push(parseInt($(tailsOptions[j]).attr('value')));
                }
            }
            console.log(shopIds)
    
            if(shopIds.length){
                if(isNaN(shopIds[0])){
                    while(isNaN(shopIds[0])){
                        const temp = shopIds.shift();
                        if(!shopIds.length) return;
                    }
                    this._controller.checkOS(shopIds);
                } else {
                    this._controller.checkOS(shopIds);
                }
            } else {
                this.closeOSBlock();
            }
        } else {
            console.log(parseInt($('.check-os').val()))
            this._controller.checkOS(parseInt($('.check-os').val()))
        }
    }

    
    /**
	 * メニュー提供時間帯設定
	 *
	 * @memberof FormGetSupporterShopsMultipleView
	 * @return {undefined}
	 */
	setMenu() {
		console.log(`FormGetSupporterShopsMultipleView.setMenu`);
		const menus = this._controller._data.menus;
		let options = '';
		for(let i = 0; i < menus.length; i++){
			options += `<option value="${menus[i].menu_id}">${menus[i].menu_name}／（${menus[i].receivable_time_from}〜${menus[i].receivable_time_to}）</option>`
		}
		let $select = '<select class="form-control validate select2 menu_id" name="menu_id[]" id="menu_id" data-validate="empty" multiple></select>';
		$('.menu-wrap').html($select);
		const $menuId = $('#menu_id');
		$menuId.html(options);
		const FormController = this._controller;
		ApplyClass.apply(FormValidateView, $menuId, [$menuId, FormController]);
		ApplyClass.apply(FormSelect2View, $menuId, [$menuId, FormController]);
	};


	/**
	 * 対象ブロックを開く
	 *
	 * @memberof FormGetSupporterShopsMultipleView
	 * @param {event) e - イベント
     * @return {undefined}
     */
    openOSBlock() {
        console.log('FormGetSupporterShopsMultipleView.openOSBlock');
        this._$block.show();
    };


    /**
    * 対象ブロックを閉じる
    *
    * @memberof FormGetSupporterShopsMultipleView
    * @return {undefined}
    */
    closeOSBlock() {
        console.log('FormGetSupporterShopsMultipleView.closeBlock');
        this._$block.hide().find('input').prop('checked', false);
        $('.menu-wrap').html('<p class="mt10 fs16 text">店舗を選択してください。</p>');
    };


    /**
	 * 店舗消去
	 *
	 * @memberof FormGetSupporterShopsMultipleView
	 * @return {undefined}
	 */
	clearShop() {
		console.log(`FormGetSupporterShopsMultipleView.clearShop`);
        $('.shop-select-wrap').html(`
            <p class="mt10 fs16 text">企業を選択してください。</p>
        `);
        this.closeOSBlock();
	};

}
/**
 * @file クロスブラウザファイルダウンロード
 *
 */
'use strict';
import FileSaver from 'file-saver';
import axios from 'axios';

/**
 * @classname FileDownloadView
 * @classdesc クロスブラウザファイルダウンロード
 */
export default class FileDownloadView {
	/**
	 * @constructor
	 * @param {object} controller - controllerオブジェクト
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('FileDownloadView.constructor');
		this._$e = $e;
		this._name = $e.closest('tr').find('td:nth-child(4)').text();
		this._url = $e.attr('data-image');
		$e.on('click', () => this.onClick());
	};



	/**
	 * クリックのコールバック
	 *
	 * @memberof FileDownloadView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FileDownloadView.onClick');
		return axios.get(this._url, {responseType: 'blob'})
			.then((res)=>{
				const blob = res.data;
				FileSaver.saveAs(blob, `${this._name}.jpg`);
			})
	};
}

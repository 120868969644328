/**
 * @file クーポンタイプ変更
 */
'use strict';


export default class FormChangeOptionConditionView {
    /**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log('FormChangeOptionConditionView.constructor');
		this._$e = $e;
        this._controller = controller;
        this._type0wrap = $('#option-condition-0-wrap');
        this._type1wrap = $('#option-condition-1-wrap');
        this._$e.on('change', e => this.onChange(e.target));
	};

	/**
	 * selectのvalueが変更された際の表示切替
	 *
	 * @memberof FormChangeOptionConditionTypeView
	 * @return {undefined}
	 */
    setDisplayOption(idx){
        console.log('FormChangeOptionConditionTypeView.setDisplayOption');
        switch(idx){
            case '':
                this.inactivateInputField([this._type0wrap, this._type1wrap]);
                break;
            case '1':
                this.inactivateInputField([this._type1wrap]);
                this._type0wrap.slideDown().find('.validate').removeClass('ignore-validate');
                break;
            case '2':
                this.inactivateInputField([this._type0wrap]);
                this._type1wrap.slideDown().find('.validate').removeClass('ignore-validate');                
                break;
            default:
                console.log('FormChangeOptionConditionTypeView.setDisplayOption.Error');
        }
    }

    
	/**
	 * radioボタンをクリックした際の表示切替
	 *
	 * @memberof FormChangeOptionConditionView
	 * @return {undefined}
	 */
    onChange(e){
        console.log('FormChangeOptionConditionView.onChange');
        this.setDisplayOption($(e).val());
    }


	/**
	 * 入力欄の非アクティブ化
	 *
	 * @memberof FormChangeOptionCampaignTypeView
	 * @return {undefined}
	 */
    inactivateInputField(targets){
        console.log('FormChangeOptionCampaignTypeView.inactivateInputField');
        for(let i = 0; i < targets.length; i++){
            $(targets[i]).slideUp();
            $(targets[i]).find('.validate').removeClass('is-invalid').addClass('ignore-validate').val('');
            $(targets[i]).find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
        }
    }
}
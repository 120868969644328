/**
 * @file テーブル検索
 *
 */
'use strict';
import Common from '../../util/_common';

/**
 * @classname TableSearchView
 * @classdesc テーブル検索
 */
export default class TableSearchView {
    /**
     * @constructor
     * @param {object} controller - controllerオブジェクト
     * @param {jQuery} $e - 該当する jQuery オブジェクト
     */
    constructor($e, controller) {
        console.log('TableSearchView.constructor');
        this._$e = $e;
        this._controller = controller;
        this._value = '';
        this._$kanaSearch = $('#search-katakana') || null;
        if(this._$kanaSearch){
            this._$kanaSearch.on('blur', () => this.checkKatakana());
        }
        $e.on('click', () => this.onClick());
    };

    /**
     * クリックのコールバック
     *
     * @memberof TableSearchView
     * @return {undefined}
     */
    onClick() {
        console.log('TableSearchView.onClick');
        let post  = false;
        if(this._$e.hasClass('btn-point-individual')){
            console.log('個別付与');
            $('.form-control').each((idx, e) => {
                const $e = $(e);
                if($e.val() && $e.val() !== '') post = true;
            });
            if(post){
                this._controller.search();
            }else{
                Common.showErrorModal('少なくとも1つは条件を設定してください。');
            }
        } else {
            if(document.getElementById('search-katakana')){
                $('.form-control').each((idx, e) => {
                    const $e = $(e);
                    if($e.val() && $e.val() !== ''){
                        if($e.attr('data-sort-default') == 'registDateDesc') return;
                        post = true;
                    }
                });

                if(post){
                    this._controller._kanaValidate
                        ? this._controller.search()
                        : Common.showErrorModal('「店舗名カナ」はカタカナで入力してください。')
                }else{
                    Common.showErrorModal('少なくとも1つは条件を設定してください。');
                }
            } else {
                this._controller.search();
            }
        }
    };


    /**
     * カタカナチェック
     *
     * @memberof TableSearchView
     * @return {undefined}
     */
    checkKatakana() {
        console.log('TableSearchView.checkKatakana');
        const $el = $('#search-katakana');
        this._value = $el.val();
        if (this._value === "") {
            $el.removeClass('is-invalid');
            $el.closest('.form-group').find('.error-tip').removeClass('show')
            this._controller._kanaValidate = true;
        } else if (!this._value.match(/^[ァ-ヾ０-９－\s　！”＃＄％＆’（）＝～｜‘｛＋＊｝＜＞？＿－＾￥＠「；：」、。・]+$/)) {
            $el.addClass('is-invalid');
            $el.closest('.form-group').find('.error-tip').addClass('show').css('left', 0).find('.error-tip-inner').html('<p>この項目は全角カナで入力してください。</p>')
            this._controller._kanaValidate = false;
        } else {
            $el.removeClass('is-invalid');
            $el.closest('.form-group').find('.error-tip').removeClass('show')
            this._controller._kanaValidate = true;
        }
    };
}

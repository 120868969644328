/**
 * @file _form-shop.js
 */
'use strict';
import Common from '../../../util/_common';

/**
 * @classname FormShop
 * @classdesc 店舗登録
 */
export default class FormShop {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor(controller){
		this._controller = controller;
	};


	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormShop
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log('FormShop.preProcessing');
		// バリデーション後に行う処理を記載（開始）

		if(!$('.shop-label')[0]){
			Common.showErrorModal('少なくとも一つは曜日と店舗を指定してください。');
			this._controller.initialize();
			return;
		}

		// バリデーション後に行う処理を記載（終了）
		this._controller.completePreProcessing($form);
	};


	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormShop
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log('FormShop.postProcessing');
		// バリデーション後に行う処理を記載（開始）

		// 郵便番号の統合
		const zipcode1 = $form.find('#zip_code1').val();
		const zipcode2 = $form.find('#zip_code2').val();
		$('#zip_code').val(`${zipcode1}-${zipcode2}`);

		// 基本締め切り時間の統合
		const defaultDeadlineTime1 = $form.find('#default_deadline_time1').val();
		const defaultDeadlineTime2 = $form.find('#default_deadline_time2').val();
		$('#default_deadline_time').val(`${defaultDeadlineTime1}:${defaultDeadlineTime2}`);

		// 公開開始日時
		const dispStartDate = $('#disp_start_date').val();
		$('#disp_start_date').val(`${dispStartDate}:00`);

		// 公開終了日時
		const dispEndDate = $('#disp_end_date').val();
		if(dispEndDate && dispEndDate !== '') $('#disp_end_date').val(`${dispEndDate}:00`);

		// バリデーション後に行う処理を記載（終了）
		this._controller.completePostProcessing($form);
	};
}

/**
 * @file _form-company.js
 */
'use strict';

/**
 * @classname FormCompany
 * @classdesc 店舗登録
 */
export default class FormCompany {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor(controller){
		this._controller = controller;
	};


	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormCompany
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log('FormCompany.preProcessing');
		// バリデーション後に行う処理を記載（開始）


		// バリデーション後に行う処理を記載（終了）
		this._controller.completePreProcessing($form);
	};


	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormCompany
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log('FormCompany.postProcessing');
		// バリデーション後に行う処理を記載（開始）

		// 郵便番号の統合
		const zipcode1 = $form.find('#zip_code1').val();
		const zipcode2 = $form.find('#zip_code2').val();
		$('#zip_code').val(`${zipcode1}-${zipcode2}`);

		// バリデーション後に行う処理を記載（終了）
		this._controller.completePostProcessing($form);
	};
}

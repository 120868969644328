/**
 * @file 表示時間削除
 */
'use strict';

/**
 * @classname FormBtnDeleteDisplayView
 * @classdesc 表示時間削除
 */
export default class FormBtnDeleteDisplayView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormBtnDeleteDisplayView.constructor');
		this._$e = $e;
		this._$timeWrap = $e.closest('.second');
		$e.on('click', () => this.onClick());
	};


	/**
	 * 休憩時間削除
	 *
	 * @memberof FormBtnDeleteDisplayView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormBtnDeleteDisplayView.onClick');
		this._$timeWrap.hide().find('.form-control').removeClass('.is-invalid').val('').addClass('ignore-validate');
 	};
}

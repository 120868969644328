/**
 * @file Form送信
 */
'use strict';
// utility class
import Common from '../../util/_common';


// 個別処理ファイルの読み込み
import FormShop from './form/_form-shop';
import FormItem from './form/_form-item';
import FormCompany from './form/_form-company';
import FormSupporterShop from './form/_form-supporter-shop';
import FormDisplay from './form/_form-display';

/**
 * @classname FormSubmitView
 * @classdesc フォームサブミット
 */
export default class FormSubmitView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - コントローラー
	 */
	constructor($e, controller) {
		console.log('FormSubmitView.constructor');
		this._$e = $e;
		this._controller = controller;
		this._form = $e.attr('data-form');
		this._$form = $(this._form);
		this._process = $e.attr('data-process') || null;
		this._timing = $e.attr('data-timing') || null;
		this._submit = true;
		this._validate = false;
		if(this._process === 'true') {
			// 個別処理ファイルのアクティベート
			switch(this._form){
				case '#form-shop':
					this._processing = new FormShop(controller);
					break;
				case '#form-item':
					this._processing = new FormItem(controller);
					break;
				case '#form-company':
					this._processing = new FormCompany(controller);
					break;
				case '#form-supporter-shop':
					this._processing = new FormSupporterShop(controller);
					break;
				case '#form-display':
					this._processing = new FormDisplay(controller);
					break;
			}
		}
		this._$e.on('click', (e) => {
			e.preventDefault();
			if(this._$e.hasClass('disabled')) return;
			if(!$e.hasClass('clicked')){
				$e.addClass('clicked');
				$e.append('<i class="fa fa-spinner fa-pulse ml-2">');
				this._flg = false;
				this.onClick();
			}
		});
		this._flg = true;
		this._controller.on('completePreProcessing', () => {
			if(this._controller._submit === this._$e){
				this.validate();
			}
		});
		this._controller.on('completeValidate', () => {
			if(this._controller._submit === this._$e) {
				this.completeValidate();
			}
		});
		this._controller.on('completePostProcessing', () => {
			if(this._controller._submit === this._$e) {
				this.addMinutes();
			}
		});
		this._controller.on('initialize', () => {
			if(this._controller._submit === this._$e) {
				this.initialize();
			}
		});
	};



	/**
	 * クリックのコールバック
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormSubmitView.onClick');
		console.log(this._$e);
		console.log(this._form);
		this._controller._submit = this._$e;
		if(this._process === 'true'){
			if(this._timing === 'pre' || this._timing === 'both') {
				console.log('前処理実施');
				this._processing.preProcessing(this._$form);
			}else{
				console.log('前処理SKIP');
				this.validate(this._$form);
			}
		}else{
			console.log('前処理SKIP');
			this.validate(this._$form);
		}
	};


	/**
	 * バリデーションチェック
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	validate() {
		if(this._validate) return;
		this._validate = true;
		console.log('FormSubmitView.validate');
		console.log('バリデーションチェック開始');
		this._controller.validate(this._$form);
	};


	/**
	 * バリデーション完了
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	completeValidate() {
		console.log('FormSubmitView.completeValidate');
		console.log('バリデーションチェック完了');
		$('.select2.validate[data-validate="empty"]').each((idx, e) => {
			const $e = $(e);
			if(!$e.hasClass('ignore-validate')){
				if($e.attr('multiple')){
					console.log('multiple');
					if($e.val().length === 0){
						$e.parent().find('.select2-selection').addClass('is-invalid');
						$e.closest('.form-group').find('.error-tip').addClass('show').find('.error-tip-inner').html('<p>この項目は必須です</p>');
					}
				}else{
					console.log('single');
					if($e.val() === ''){
						$e.parent().find('.select2-selection').addClass('is-invalid');
						$e.closest('.form-group').find('.error-tip').addClass('show').find('.error-tip-inner').html('<p>この項目は必須です</p>');
					}
				}
			}
		});
		if(this._$form.find('.validate').hasClass('is-invalid')) this._submit = false;
		if(this._$form.find('.validate-checkbox').hasClass('is-invalid')) this._submit = false;
		if(this._$form.find('.select2-selection').hasClass('is-invalid')) this._submit = false;
		if(this._$form.find('.validate').hasClass('has-error')) this._hasError = true;
		if(this._submit){
			console.log('エラーチェック：エラーなし');
			this.postProcessing();
		}else{
			Common.showErrorModal('入力項目に誤りがあります');
			this._flg = true;
			this.initialize();
		}
	};


	/**
	 * 後処理
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	postProcessing() {
		console.log('FormSubmitView.postProcessing');
		console.log(`process: ${this._process}`);
		if(this._process === 'true'){
			if(this._timing === 'post' || this._timing === 'both') {
				console.log('後処理実施');
				this._processing.postProcessing(this._$form);
			}else{
				console.log('後処理SKIP');
				this.addMinutes();
			}
		}else{
			console.log('後処理SKIP');
			this.addMinutes();
		}
	};


	/**
	 * 分を追加する
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	addMinutes() {
		console.log('FormSubmitView.addMinutes');
		$('input[data-time=true]').each((idx, e) => {
			let val = $(e).val();
			if(val.length === 16){
				val += ':00';
				console.log(val);
				$(this).val(val);
			}
		});
		this.submit();
	};


	/**
	 * submit
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	submit() {
		console.log('FormSubmitView.submit');
		$('.ignore-validate').removeAttr('name');
		console.log('サブミット');
		setTimeout(() => {
			Common.submit(this._$form);
			const action = this._$form.attr('action');
			if(action === '#' || action === '__TBA__' || action === ''){
				this.initialize();
			}
		}, 100);
	};


	/**
	 * 初期化
	 *
	 * @memberof FormSubmitView
	 * @return {undefined}
	 */
	initialize() {
		console.log('FormSubmitView.initialize');
		this._submit = true;
		this._validate = false;
		this._controller._$form = null;
		this._controller._submit = null;
		this._flg = true;
		this._$e.removeClass('clicked').find('.fa-spinner.fa-pulse').remove();
	};
}

/**
 * @file _common.js
 */
'use strict';

/**
 * @classname Common
 */

export default class Common {

	/**
	 * @constructor
	 */
	constructor() {
		console.log('Common.constructor');
	};


	/**
	 * ゼロパディングする
	 *
	 * @memberof Common
	 * @param {int} num - 元の数字
	 * @param {int} length - 桁数
	 * @return {string} - ゼロパディングした数字
	 */
	static zeroPadding(num, length) {
		return ('0000000000' + num).slice(-length);
	};


	/**
	 * 数字をカンマ区切りにする
	 *
	 * @memberof Common
	 * @param {string} num - 3桁区切りの数字
	 * @return {string} - カンマを削除した数字
	 */
	static separatedByComma(num) {
		return Number(num).toLocaleString();
	};


	/**
	 * 価格を数値に変換する
	 *
	 * @memberof Common
	 * @param {string} price - 数字文字列
	 * @return {string} - 数字
	 */
	static getPrice(price) {
		return parseInt(price.replace(/^¥/, '').replace(/,/ig, ''));
	};


	/**
	 * 日時を取得する
	 *
	 * @memberof Common
	 * @param {date} date - 日時
	 * @param {string} divider - 年月日区切り文字
	 * @param {boolean} showSec - 秒を表示するか？
	 * @param {boolean} padding - パディングするかどうか？
	 * @return {string} date - 現在日時
	 */
	static getCurrentDateTime(date, divider = '-', showSec = true, padding = false) {
		date = date ? date : new Date();
		const year 	= date.getFullYear();
		const month	= date.getMonth() + 1;
		const day 	= date.getDate();
		const hour 	= date.getHours();
		const min	= date.getMinutes();
		const sec	= date.getSeconds();
		let dateStr = '';
		if(padding){
			dateStr =  year + divider + this.zeroPadding(month, 2) + divider + this.zeroPadding(day,2) + ' ' + this.zeroPadding(hour, 2) + ':' + this.zeroPadding(min, 2);
			if(showSec) dateStr +=  ':' + this.zeroPadding(sec, 2)
		}else{
			dateStr =  year + divider + month + divider + day + ' ' + hour + ':' + min;
			if(showSec) dateStr += ':' + sec;
		}
		return dateStr;
	};



	/**
	 * 本日の日付を取得する
	 *
	 * @memberof Common
	 * @param {boolean} padding - パディングするかどうか？
	 * @param {string} divider - 年月日区切り文字
	 * @return {string} date - 本日
	 */
	static getCurrentDate(padding = false, divider = '-') {
		let date = new Date();
		if(padding){
			return date.getFullYear() + divider + this.zeroPadding(date.getMonth() + 1, 2) + divider + this.zeroPadding(date.getDate(),2);
		}else{
			return date.getFullYear() + divider + (date.getMonth() + 1) + divider + date.getDate();
		}
	};



	/**
	 * パラメーターなしのURLを取得する
	 *
	 * @memberof Common
	 * @return {string} url - パラメーターなしのURL
	 */
	static getCurrentURL() {
		let url = document.location.href;

		if (url.match(/(.+)\?/)) {
			const match = url.match(/(.+)\?/);
			url = match[1];
		} else if (url.match(/(.+)#/)) {
			const match = url.match(/(.+)#/);
			url = match[1];
		}
		return url;
	};


	/**
	 * URLパラメーターを取得する
	 *
	 * @memberof Common
	 * @return {array} param - URLパラメーター key value
	 */
	static getParam() {
		let url = document.location.href;
		if (url.match(/(.+)#/)) {
			const match1 = url.match(/(.+)#/);
			url = match1[1];
		}
		if (url.match(/\?(.+)$/)) {
			const match2 = url.match(/\?(.+)$/)
			const paramString = match2[1];
			let tmp = paramString.split('&');
			let param = {};
			let tmp2, key, val;
			for (let i = 0; i < tmp.length; i++) {
				// initialize
				tmp2 = [];
				key = '';
				val = '';

				tmp2 = tmp[i].split('=');
				key = tmp2[0];
				val = tmp2[1];
				param[key] = val;
			}
			return param;
		} else {
			return {};
		}
	};


	/**
	 * パラメーターを文字列で取得する
	 *
	 * @memberof Common
	 * @return {string} url - パラメーターなしのURL
	 */
	static getParamAll() {
		let url = document.location.href;
		let param = '';
		if (url.match(/\?(.+)/)) {
			param = $1;
		}
		return param;
	};


	/**
	 * エラーモーダルを表示する
	 *
	 * @memberof Common
	 * @return {string} url - パラメーターなしのURL
	 */
	static showErrorModal(errorText = '入力項目に誤りがあります。') {
		console.log('Common.showErrorModal');
		if($('.modal-backdrop')[0]) return;
		const $modalBackground = '<div class="modal-backdrop fade show"></div>';
		const $modal =
`<div class="modal modal-error fade show" tabindex="-1" role="dialog" style="display: block;">
 	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header bg-danger">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body tac">
				<div class="text-danger mb20" style="font-size: 60px;">
					<i class="fas fa-exclamation-circle"></i>
				</div>
 				<p>${errorText}</p>
			</div>
			<div class="modal-footer">
 				<button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
 			</div>
		</div>
	</div>
</div>`;
		$('body').append($modal).append($modalBackground);
		$('.modal-backdrop').click(() => {
			Common.hideErrorModal();
		});
		$('.modal-error button').click(() => {
			Common.hideErrorModal();
		});

	};


	/**
	 * エラーモーダルを表示する
	 *
	 * @memberof Common
	 * @return {string} url - パラメーターなしのURL
	 */
	static hideErrorModal() {
		console.log('Common.hideErrorModal');
		const $modal = $('.modal-error');
		const $modalBackground = $('.modal-backdrop');
		$modal.fadeOut(500);
		$modalBackground.fadeOut(500);
		setTimeout(() => {
			$modal.remove();
			$modalBackground.remove();
		}, 500);
		setTimeout(() => {
			if ($('.is-invalid')[0]) {
				const position = $('.is-invalid').eq(0).closest('.form-group').offset().top - 110;
				$('html, body').animate({scrollTop: position}, 500, 'swing');
			}
		},500);
	};


	/**
	 * 未修正モーダルを表示する
	 *
	 * @memberof Common
	 * @return {string} url - パラメーターなしのURL
	 */
	static showReviseModal() {
		console.log('Common.showReviseModal');
		if($('.modal-backdrop')[0]) return;
		const $modalBackground = '<div class="modal-backdrop fade show"></div>';
		const $modal =
			`<div class="modal modal-error fade show" tabindex="-1" role="dialog" style="display: block;">
 	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header bg-danger">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body tac">
				<div class="text-danger mb20" style="font-size: 60px;">
					<i class="fas fa-exclamation-circle"></i>
				</div>
 				<p>修正がされていない箇所があります</p>
			</div>
			<div class="modal-footer">
 				<button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
 			</div>
		</div>
	</div>
</div>`;
		$('body').append($modal).append($modalBackground);
		$('.modal-backdrop').click(() => {
			Common.hideReviseModal();
		});
		$('.modal-error button').click(() => {
			Common.hideReviseModal();
		});

	};


	/**
	 * 未修正モーダルを表示する
	 *
	 * @memberof Common
	 * @return {string} url - パラメーターなしのURL
	 */
	static hideReviseModal() {
		console.log('Common.hideReviseModal');
		const $modal = $('.modal-error');
		const $modalBackground = $('.modal-backdrop');
		$modal.fadeOut(500);
		$modalBackground.fadeOut(500);
		setTimeout(() => {
			$modal.remove();
			$modalBackground.remove();
		}, 500);
		setTimeout(() => {
			if ($('.has-error')[0]) {
				console.log($('.has-error')[0].attr('id'))
				const position = $('.has-error').eq(0).closest('.form-group').offset().top - 110;
				$('html, body').animate({scrollTop: position}, 500, 'swing');
			}
		},500);
	};


	/**
	 * フォームの送信をする
	 *
	 * @memberof Common
	 */
	static submit($form) {
		console.log('Common.submit');
		if($form.attr('action') === '#' || $form.attr('action') === '' || $form.attr('action') === '__TBA__'){
			console.log('dummy submit');
			this.showDummySubmit();
		}else{
			$form.submit();
		}
	};


	/**
	 * ダミーのフォーム送信モーダルを表示する
	 *
	 * @memberof Common
	 */
	static showDummySubmit() {
		console.log('Common.showDummySubmit');
		alert(
`フォーム送信完了
actionに値を設定するとフォームが送信されます。
実際のjavascript処理は正常に完了しています。`);
	};


	/**
	 * 配列から重複なく複数の値を取り出す
	 *
	 * @memberof Common
	 * @return {array / string} r - 配列の値
	 */
	static getRandomFromArray(array, num, isString) {
		let a = array;
		let t = [];
		let r = [];
		let l = a.length;
		let n = num < l ? num : l;
		while (n-- > 0) {
			let i = Math.random() * l | 0;
			r[n] = t[i] || a[i];
			--l;
			t[i] = t[l] || a[l];
		}
		if(isString){
			return r.toString();
		}else{
			return r;
		}
	};


	/**
	 * 機種判定を行う
	 *
	 * @memberof Common
	 * @return {undefined}
	 */
	static addHtmlClass() {
		let ua = window.navigator.userAgent.toLowerCase();
		let ver = window.navigator.appVersion.toLowerCase();

		// check browser
		let browser, device;
		if (ua.indexOf('edge') !== -1){
			browser = 'edge';
		} else if (ua.indexOf("iemobile") !== -1) {
			browser = 'iemobile';
		} else if (ua.indexOf('trident/7') !== -1) {
			browser =  'ie11';
		} else if (ua.indexOf("msie") !== -1 && ua.indexOf('opera') === -1){
			if(ver.indexOf("msie 6.")  !== -1) {
				browser = 'ie6';
			} else if (ver.indexOf("msie 7.")  !== -1) {
				browser = 'ie7';
			} else if (ver.indexOf("msie 8.")  !== -1) {
				browser = 'ie8';
			} else if (ver.indexOf("msie 9.")  !== -1) {
				browser = 'ie9';
			} else if (ver.indexOf("msie 10.") !== -1) {
				browser = 'ie10';
			}
		} else if (ua.indexOf('chrome')  !== -1 && ua.indexOf('edge') === -1) {
			browser =  'chrome';
		} else if (ua.indexOf('safari')  !== -1 && ua.indexOf('chrome') === -1) {
			browser =  'safari';
		} else if (ua.indexOf('opera')   !== -1) {
			browser = 'opera';
		} else if (ua.indexOf('firefox') !== -1) {
			browser = 'firefox';
		}

		// check device
		if(ua.indexOf('iphone') !== -1 || ua.indexOf('ipod') !== -1 ) {
			device = 'iphone';
		} else if (ua.indexOf('ipad')	!== -1) {
			device = 'ipad';
		} else if (ua.indexOf('android') !== -1) {
			device = 'android';
		} else if (ua.indexOf('windows') !== -1 && ua.indexOf('phone') !== -1) {
			device = 'windows_phone';
		}

		if(browser) $('html').addClass(browser);
		if(device) $('html').addClass(device);
	}

	/**
	 * loadingアイコンを表示する
	 *
	 * @memberof Common
	 * @return {undefined}
	 */
	static showLoading() {
		console.log('Common.showLoading');
		const loading = '<div id="loading"><img src="/img/spinner.svg" width="100" height="100"></div>';
		$('body').append(loading);
	}

	/**
	 * loadingアイコンを消去
	 *
	 * @memberof Common
	 * @return {undefined}
	 */
	static hideLoading() {
		console.log('Common.hideLoading');
		$('#loading').remove();
	}

	/**
	 * 年月から日を取得
	 *
	 * @memberof Common
	 * @return {undefined}
	 */
	static getDateFromYearMonth(year, month){
		const lastday = ['', 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
		if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0){
			lastday[2] = 29;
		}
		return lastday[month];
	}

}
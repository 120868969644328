/**
 * @file テーブル検索
 *
 */
'use strict';

/**
 * @classname TableClearSearchView
 * @classdesc テーブル検索
 */
export default class TableClearSearchView {
	/**
	 * @constructor
	 * @param {object} controller - controllerオブジェクト
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('TableClearSearchView.constructor');
		this._$e = $e;
		this._controller = controller;
		$e.on('click', () => this.onClick());
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof TableClearSearchView
	 * @return {undefined}
	 */
	onClick() {
		console.log('TableClearSearchView.onClick');
		this._controller.clearSearch();
	};
}

/**
 * @file 時間分割
 */
'use strict';

/**
 * @classname FormTimeDivideView
 * @classdesc 時間分割
 */
export default class FormTimeDivideView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormTimeDivideView.constructor');
		if($e.val() && $e.val() !== ''){
			let time = $e.val();
			console.log(time);
			time = time.split(':');
			const id = $e.attr('id');
			console.log(`${id} is divided (${time[0]}:${time[1]})`);
			$(`#${id}1`).val(time[0]);
			$(`#${id}2`).val(time[1]);
		}
	};
}

/**
 * @file 休憩時間追加
 */
'use strict';

/**
 * @classname FormBtnAddBreakView
 * @classdesc 休憩時間追加
 */
export default class FormBtnAddBreakView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormBtnAddBreakView.constructor');
		this._$e = $e;
		this._$break = $e.closest('.list-item');
		this.init();
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックイベントのコールバック
	 *
	 * @memberof FormBtnAddBreakView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormBtnAddBreakView.onClick');
		this._$break.find('.second').show().find('.form-control').removeClass('ignore-validate');
 	};


	/**
	 * 曜日確認
	 *
	 * @memberof FormBtnAddBreakView
	 * @return {undefined}
	 */
	init() {
		console.log('FormBtnAddBreakView.init');
		if(this._$break.find('.break_from').val() !== ''){
			this._$break.find('.second').show().find('.form-control').removeClass('ignore-validate');
		}
	};
}

/**
 * @file 文字カウンター
 */
'use strict';

/**
 * @classname FormCounterView
 * @classdesc 文字カウンター
 */
export default class FormZipView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormCounterView.constructor');
		this._$e = $e;
		this._max = parseInt($e.attr('maxlength'));
		$e.removeAttr('maxlength');
		const $formGroup = $e.closest('.form-group');
		if($formGroup.find('.w-100')[0]){
			$formGroup.find('.w-100').eq(0).prepend(`<div class="col-sm-8 offset-sm-3 mb-2 tar"><p class="text-counter">残り<span>${this._max}</span>文字</p></div>`);
		}else{
			$formGroup.append(`<div class="w-100"><div class="col-sm-8 offset-sm-3 tar"><p class="text-counter">残り<span>${this._max}</span>文字</p></div></div>`);
		}
		this._$counter = $formGroup.find('.text-counter span');
		$e.on('keyup', () => this.calculate());
		$e.on('blur', () => this.calculate());
		this.calculate();
	};


	/**
	 * keyupのコールバック
	 *
	 * @memberof FormDiffModalView
	 * @return {undefined}
	 */
	calculate() {
		console.log('FormCounterView.calculate');
		let value = this._$e.val();
		if(value !== ''){
			let len = value.length;
			if(len > this._max){
				value = value.substr(0, this._max);
				this._$e.val(value);
				len = this._max;
			}
			const rest = this._max - len;
			console.log(rest);
			this._$counter.text(rest);
		}else{
			this._$counter.text(this._max);
		}
	};
}

/**
 * @file オプション追加
 */
'use strict';
import Template from '../../template/_template';
import Common from '../../util/_common';

/**
 * @classname FormBtnAddOptionView
 * @classdesc オプション追加
 */
export default class FormBtnAddOptionView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - コントローラー
	 */
	constructor($e, controller) {
		console.log('FormBtnAddOptionView.constructor');
		this._$e = $e;
		this._controller = controller;
		this._$option = $e.closest('.list-item');
		$e.on('click', () => this.onClick());
	};


	/**
	 * オプション追加
	 *
	 * @memberof FormBtnAddOptionView
	 * @param {event) e - イベント
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormBtnAddOptionView.onClick');
		let fill = true;
		if(this._$option.find('.option_name').val() === '') fill = false;
		if(this._$option.find('.option_price').val() === '') fill = false;
		if(this._$option.find('.option_price_tax').val() === '') fill = false;
		if(fill){
			const option = Template.getOption();
			$('.list-option').append(option);
			const $optionGroupName = this._$option.find('.option_group_name');
			const optionGroupValue = $optionGroupName.val();
			$optionGroupName.attr('data-value', optionGroupValue);
			$optionGroupName.find('option').each((idx, e) => {
				const $e = $(e);
				if($e.val() !== optionGroupValue) $e.remove();
			});
			this._$option.find('.option_name').prop('readonly', true);
			this._$option.find('.option_price').prop('readonly', true);
			const tax = parseInt(this._$option.find('.option_tax').val());
			if(tax === 8){
				this._$option.find('.option_tax [value="10"]').prop('disabled', true);
			}else{
				this._$option.find('.option_tax [value="8"]').prop('disabled', true);
			}
			this._$option.find('.option_tax').prop('readonly', true);
			this._$option.find('.option_price_tax').prop('readonly', true);
			this._controller.setOptionClass();
		}else{
			Common.showErrorModal('必須項目を入力後に次のオプションを追加してください。');
		}
	};
}

/**
 * @file 検索条件もっと見る
 *
 */
'use strict';

/**
 * @classname TableSearchConditionAccordionView
 * @classdesc 検索条件もっと見る
 */
export default class TableSearchConditionAccordionView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('TableSearchConditionAccordionView.constructor');
		this._$e = $e;
		$e.on('click', () => this.onClick());
		this._closeText = $e.attr('data-close-text') || 'もっと検索条件を見る';
		this._openText = $e.attr('data-open-text') || '検索条件を閉じる';
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof TableSearchConditionAccordionView
	 * @return {undefined}
	 */
	onClick() {
		console.log('TableSearchConditionAccordionView.onClick');
		const $target = $('.block-accordion-target');
		if(this._$e.hasClass('open')){
			this._$e.removeClass('open').text(this._closeText);
			$target.slideUp();
		}else{
			this._$e.addClass('open').text(this._openText);
			$target.slideDown();
		}
	};
}

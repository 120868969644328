/**
 * @file BasicModel Controller
 */

'use strict';
import Listener from '../util/_listener';
import Common from '../util/_common';

let instance = null;

/**
 * @classname BasicModel
 * @classdesc Basic Model
 */
export default class BasicModel {

	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance;
		}

		this._listener = new Listener();
		this._jqXHR = null;
		this._post = false;
		this._data = null;
		this._message = null;
		this._code = null;
		// csrf add
		$.ajaxSetup({
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		});

		instance = this;
		return instance;
	};


	/**
	 * 個別ID削除
	 * 
	 * @memberof BasicModel
	 * @param {number} individualId - 個別ID
	 * @return {undefined}
	 */
	deleteIndividualId(individualId) {
		if (this._post) return;
		console.log(`BasicModel.deleteIndividualId`);
		Common.showLoading();
		this._post = true;
		let formData = new FormData();
		formData = {
			service_id: $('[data-service-id]').data('service-id'),
			api_key: $('[data-api-key]').data('api-key'),
			individual_id: individualId,
		};
		const url = window.const.API_URL.DELETE_INDIVIDUAL_ID;
		let params = {};
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onDeleteIndividualIdComplete.bind(this));
	};


	/**
	 * 個別ID削除ajax通信完了
	 *
	 * @memberof BasicModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onDeleteIndividualIdComplete(data, textStatus) {
		console.log('BasicModel.onDeleteIndividualIdComplete');
		Common.hideLoading();
		console.log(data.result);
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false;
				Common.showErrorModal(`${this._message}<br>(${this._code})`);
			} else {
				this._post = false;
				this._listener.trigger('deleteIndividualIdComplete');
			}
		} else {
			this._post = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			Common.showErrorModal(`${this._message}<br>(${this._code})`);
			console.log(`ajax error (onDeleteIndividualIdComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 * listener on
	 *
	 * @memberof BasicModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listner off
	 *
	 * @memberof BasicModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn);
	};
}

/**
 * @file 上下スクロールバーを連動
 *
 */
'use strict';

/**
 * @classname TableScrollbarView
 * @classdesc 上下スクロールバーを連動
 */
export default class TableScrollbarView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('TableScrollbarView.constructor');
		this._$e = $e;
		const $clone = $e.closest('.table-wrap').find('.scroll-box');
		this._width = parseInt($('.table').css('min-width'));
		$e.find('.scroll-bar-inner').width(this._width);
		$e.on('scroll', () => {
			$clone.scrollLeft($e.scrollLeft());
		});
		$clone.on('scroll', () => {
			$e.scrollLeft($clone.scrollLeft());
		});
		this.show();
		$(window).on('resize', () => this.show());
		$('.card-main').on('resize', () => this.show());
	};


	/**
	 * 表示・非表示
	 *
	 * @memberof FormSelectToggleView
	 * @return {undefined}
	 */
	show() {
		console.log('TableScrollbarView.show');
		if($('.card-main').width() - 30 > this._width){
			this._$e.hide();
		}else{
			this._$e.show();
		}
	};
}
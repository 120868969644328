/**
 * @file 商品準備方法変更
 */
'use strict';

/**
 * @classname FormChangeItemTypeView
 * @classdesc 商品準備方法変更
 */
export default class FormChangeItemTypeView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormChangeItemTypeView.constructor');
		this._$e = $e;
		this._$block = $('.block-cooking-time');
		$e.on('click', () => this.onClick());
		this.onClick();
	};


	/**
	 * クリック
	 *
	 * @memberof FormChangeItemTypeView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormChangeItemTypeView.onClick');
		const value = $('[name=item_preparation_type]:checked').val();
		if(value === '1') {
			this._$block.hide().find('.form-control').removeClass('is-invalid').addClass('ignore-validate').val('');
			this._$block.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
		}else{
			this._$block.show().find('.form-control').removeClass('ignore-validate');
		}
	};
}

/**
 * @file もっと見る
 *
 */
'use strict';

/**
 * @classname BtnAccordionView
 * @classdesc もっと見る
 */
export default class BtnAccordionView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('BtnAccordionView.constructor');
		this._$e = $e;
		this._$target = $($e.attr('data-target'));
		this._closeText = $e.attr('data-close-text');
		this._openText = $e.attr('data-open-text');
		$e.on('click', () => this.onClick());
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof BtnAccordionView
	 * @return {undefined}
	 */
	onClick() {
		console.log('BtnAccordionView.onClick');
		if(this._$e.hasClass('open')){
			this._$e.removeClass('open');
			this._$target.slideUp();
			if(this._closeText) this._$e.find('span').text(this._closeText);
		}else{
			this._$e.addClass('open');
			this._$target.slideDown();
			if(this._openText) this._$e.find('span').text(this._openText);
		}
	};
}

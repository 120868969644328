/**
 * @file 全角→半角変換
 *
 */
'use strict';

/**
 * @classname FormZenkakuToHankakuView
 * @classdesc 全角→半角変換
 */
export default class FormZenkakuToHankakuView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormZenkakuToHankakuView.constructor');
		this._$e = $e;
		this._$e.on('blur', () => this.onBlur());
	};

	/**
	 * blurのコールバック
	 *
	 * @memberof FormZenkakuToHankakuView
	 * @return {undefined}
	 */
	onBlur() {
		console.log('FormZenkakuToHankakuView.onBlur');
		let str = this._$e.val();
		str = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
			return String.fromCharCode(s.charCodeAt(0) - 65248);
		});
		console.log(str);
		this._$e.val(str);
	}
}

/**
 * @file CSVダウンロード
 *
 */
'use strict';
import Common from '../../util/_common';

/**
 * @classname TableCsvDlDetailView
 * @classdesc CSVダウンロード
 */
export default class TableCsvDlDetailView {
	/**
	 * @constructor
	 * @param {object} controller - controllerオブジェクト
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('TableCsvDlDetailView.constructor');
		this._$e = $e;
		this._params = location.search;
		this._itemId = $('#item_id').val();
		this._shopId = $('#shop_id').val();
		this._companyId = $('#company_id').val();
		$e.on('click', () => { this.onClick(); });
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof TableCsvDlDetailView
	 * @return {undefined}
	 */
	onClick() {
		console.log('TableCsvDlDetailView.onClick');
		if (this._$e.hasClass('item-detail-csv')) {
			location.href = window.const.URL.CSV_ITEMDETAIL + '/' + this._itemId + this._params;
		} else if (this._$e.hasClass('shop-detail-csv')) {
			location.href = window.const.URL.CSV_SHOPDETAIL + '/' + this._shopId + this._params;
		} else if (this._$e.hasClass('company-detail-csv')) {
			location.href = window.const.URL.CSV_COMPANYDETAIL + '/' + this._companyId + this._params;
		}
	};
}

/**
 * @file 酒フラグ変更
 */
'use strict';

/**
 * @classname FormIsAlcoholView
 * @classdesc 酒フラグ変更
 */
export default class FormIsAlcoholView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormIsAlcoholView.constructor');
		this._$e = $e;
		$e.on('click', () => this.onClick());
	};


	/**
	 * オプション有無変更変更
	 *
	 * @memberof FormIsAlcoholView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormIsAlcoholView.onClick');
		if(this._$e.prop('checked')){
			$('#tax').val('10');
		}
	};
}

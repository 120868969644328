/**
 * @file Table Controller
 */

'use strict';
import Listener from '../util/_listener';
import Common from "../util/_common";

let instance = null;

/**
 * @classname Table
 * @classdesc Table Controller
 */
export default class Table {

	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance;
		}
		console.log('Table.constructor');
		this._listener = new Listener();

		this._error = false;
		this._open = false;
        this._kanaValidate = true;

		instance = this;
		return instance;
	};

	/**
	 * 検索
	 *
	 * @memberof Table
	 * @return {undefined}
	 */
	search() {
		console.log('Table.search');
		this._error = false;
		let query = this._getSearchQuery();
		query = Table._setSort(query);
		this._setCount(query);
	};


	/**
	 * ソート
	 *
	 * @memberof Table
	 * @return {undefined}
	 */
	sort() {
		console.log('Table.sort');
		this._error = false;
		let query = this._getSearchQuery();
		query = Table._setSort(query);
		this._setCount(query);
	};


	/**
	 * ページネーション
	 *
	 * @memberof Table
	 * @param {number} page - ページ番号
	 * @return {undefined}
	 */
	pagination(page) {
		console.log('Table.pagination');
		this._error = false;
		let query = this._getSearchQuery();
		query = Table._setSort(query);
		if(page !== 1){
			if(query !== ''){
				query += `&page=${page}`;
			}else{
				query += `?page=${page}`;
			}
		}
		this._setCount(query);
	};


	/**
	 * 表示件数変更
	 *
	 * @memberof Table
	 * @param {number} count - 件数
	 * @return {undefined}
	 */
	count(count) {
		console.log('Table.count');
		this._error = false;
		let query = this._getSearchQuery();
		query = Table._setSort(query);
		this._setCount(query, count);
	};


	/**
	 * 検索条件クリア
	 *
	 * @memberof Table
	 * @return {undefined}
	 */
	clearSearch() {
		console.log('Table.clearSearch');
		this._error = false;
		let query = Table._setSort();
		this._setCount(query);
	};


	/**
	 * 検索GETパラメーター取得
	 *
	 * @memberof Table
	 * @return {string} query - 検索GETパラメーター
	 */
	_getSearchQuery() {
		console.log('Table._getSearchQuery');
		let prefix = '?';
		let query = '';
		let radioName;
		$('[data-search-item]').each((idx, e) => {
			const $e = $(e);
			if($e.attr('data-search-type') === 'input') {
				const value = $e.val();
				if(value && value !== ''){
					query += `${prefix}${$e.attr('data-search-item')}=${value}`;
					prefix = '&';
				}
			}else if($e.attr('data-search-type') === 'radio'){
				const name = $e.attr('name');
				if(radioName !== name){
					const checked = $(`input[type=radio][name=${name}]:checked`);
					const value = checked.val();
					if(value !== 'all'){
						query += `${prefix}${$e.attr('data-search-item')}=${value}`;
						prefix = '&';
					}
					radioName = name;
					if(checked.attr('data-search-inside') === 'true' && value !== 'all'){
						this._open = true;
					}
				}
			}else if($e.attr('data-search-type') === 'checkbox'){
				if($e.prop('checked')) {
					const value = $e.val();
					query += `${prefix}${$e.attr('data-search-item')}=${value}`;
					prefix = '&';
					if($e.attr('data-search-inside') === 'true'){
						this._open = true;
					}
				}
			}else if($e.attr('data-search-type') === 'select'){
				const value = $e.val();
				if(value && value !== ''){
					query += `${prefix}${$e.attr('data-search-item')}=${value}`;
					prefix = '&';
				}
				if($e.attr('data-search-inside') === 'true'){
					this._open = true;
				}
			}else if($e.attr('data-search-type') === 'array_base'){
				const name = $e.attr('data-search-item');
				let value = '';
				value = $(`[data-search-item=${name}`).map((idx, e) => {
					const $e = $(e);
					if($e.prop('checked')) return $e.val();
				}).get().join('|');
				if(value.length > 0){
					query += `${prefix}${name}=${value}`;
					prefix = '&';
					if($e.attr('data-search-inside') === 'true'){
						this._open = true;
					}
				}
			}else if($e.attr('data-search-type') === 'array'){

			}else if($e.attr('data-search-type') === 'multiple'){
				const name = $e.attr('data-search-item');
				let value = '';
				value = $e.find('option:selected').map((idx, e) => {
					return $(e).val();
				}).get().join('|');
				if(value.length > 0){
					query += `${prefix}${name}=${value}`;
					prefix = '&';
					if($e.attr('data-search-inside') === 'true'){
						this._open = true;
					}
				}
			}else{
				if($e.is(':invalid')){
					console.log($e.attr('data-search-item') + ': ' + $e.is(':valid'));
					Common.showErrorModal();
					this._error = true;
				}
				const value = encodeURIComponent($e.val());
				console.log(value);
				if(value !== ''){
					query += `${prefix}${$e.attr('data-search-item')}=${value}`;
					prefix = '&';
					if($e.attr('data-search-inside') === 'true'){
						this._open = true;
					}
				}
			}
		});
		return query;
	};


	/**
	 * ソートパラメーターセット
	 *
	 * @memberof Table
	 * @param {string} query - 検索GETパラメーター
	 * @return {string} query - 検索GETパラメーター
	 */
	static _setSort(query = '') {
		console.log('Table._setSort');
		const $sortTable = $('.table-sort');
		const sortValue = $sortTable.val();
		const sortDefault = $sortTable.attr('data-sort-default');
		if(sortValue !== sortDefault){
			if(query !== ''){
				query += `&sort=${sortValue}`;
			}else{
				query += `?sort=${sortValue}`;
			}
		}
		console.log(query);
		return query;
	};


	/**
	 * カウントパラメーターセット
	 *
	 * @memberof Table
	 * @param {string} query - 検索GETパラメーター
	 * @param {number} count - 件数
	 * @return {string} query - 検索GETパラメーター
	 */
	_setCount(query = '', count) {
		console.log('Table._setCount');
		const cnt = count || parseInt($('.btn-search-count.active').attr('data-search-count'));
		if(cnt === 50){
			if(query !== ''){
				query = `${query}&count=50`;
			}else{
				query = '?count=50';
			}
		}
		if(this._open){
			if(query !== ''){
				query = `${query}&open=true`;
			}else{
				query = '?open=true';
			}
		}
		if($('body').hasClass('page-favorite')){
			const active = $('.nav-link.active').attr('href').replace(/^#/, "");
			console.log(active);
			if(active === 'from-company'){
				if(query !== ''){
					query = `${query}&type=fromCompany`;
				}else{
					query = '?type=fromCompany';
				}
			}
		}
		const url = Common.getCurrentURL();
		if(!this._error) {
			location.href = `${url}${query}`;
		}else{
			this._open = false;
		}
	};


		/**
	 * listener on
	 *
	 * @memberof Table
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listener off
	 *
	 * @memberof Table
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn);
	};
}

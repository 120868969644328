/**
 * @file select2
 *
 */
'use strict';

/**
 * @classname Select2View
 * @classdesc select2
 */
export default class Select2View {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log('Select2View.constructor');
		controller._$select = $e.select2({
			language: {"noResults": function(){ return '検索対象が存在しません。';}},
			escapeMarkup: function (markup) { return markup; }
		});
		const value = $e.val();
		if(value && value !== '') controller._$select.val($e.val()).trigger('change');
		if($e.hasClass('validate') && $e.attr('data-validate').match(/empty/)){
			controller._$select.on('select2:close', () => {
				if($e.attr('multiple')){
					if($e.val().length === 0){
						$e.parent().find('.select2-selection').addClass('is-invalid');
						$e.closest('.form-group').find('.error-tip').addClass('show').find('.error-tip-inner').html('<p>この項目は必須です</p>')
					}else{
						$e.parent().find('.select2-selection').removeClass('is-invalid');
						$e.closest('.form-group').find('.error-tip').removeClass('show').find('.error-tip-inner').html('')
					}
				}else{
					if($e.val() === ''){
						$e.parent().find('.select2-selection').addClass('is-invalid');
						$e.closest('.form-group').find('.error-tip').addClass('show').find('.error-tip-inner').html('<p>この項目は必須です</p>')
					}else{
						$e.parent().find('.select2-selection').removeClass('is-invalid');
						$e.closest('.form-group').find('.error-tip').removeClass('show').find('.error-tip-inner').html('')
					}
				}
			});
		}
	};
}

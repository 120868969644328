/**
 * @file FormModel Controller
 */

'use strict';
import Listener from '../util/_listener';
import Common from '../util/_common';

let instance = null;

/**
 * @classname FormModel
 * @classdesc Form Model
 */
export default class FormModel {

	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance;
		}

		this._listener = new Listener();
		this._jqXHR = null;
		this._post = false;
		this._data = null;
		this._message = null;
		this._code = null;
		// csrf add
		$.ajaxSetup({
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		});

		instance = this;
		return instance;
	};


	/**
	 *
	 * 画像アップロード
	 * @memberof FormModel
	 * @param {blob} file - ファイル
	 * @param {string} type - タイプ 1:ロゴ 2:店舗写真 3:商品写真
	 * @return {undefined}
	 */
	uploadImage(file, type) {
		if (this._post) return;
		console.log(`FormModel.uploadImage`);
		Common.showLoading();
		console.log(this._data);
		this._post = true;
		let formData = new FormData();
		formData.append('imagefile', file);
		formData.append('type', type);
		let url;
		if (type === '1') {
			url = window.const.API_URL.UPLOAD_LOGO_IMAGE;
		} else if (type === '2') {
			url = window.const.API_URL.UPLOAD_SHOP_IMAGE;
		} else if (type === '3') {
			url = window.const.API_URL.UPLOAD_ITEM_IMAGE;
		} else if (type === '4') {
			url = window.const.API_URL.UPLOAD_COUPON_IMAGE;
		}
		let params = {};
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		params['processData'] = false;
		params['contentType'] = false;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onUploadImageComplete.bind(this));
	};


	/**
	 * 画像アップロードajax通信完了
	 *
	 * @memberof FormModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onUploadImageComplete(data, textStatus) {
		console.log('FormModel.onUploadImageComplete');
		Common.hideLoading();
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false;
				Common.showErrorModal(`${this._message}<br>(${this._code})`);
			} else {
				this._post = false;
				this._listener.trigger('uploadImageComplete');
			}
		} else {
			this._post = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			Common.showErrorModal(`${this._message}<br>(${this._code})`);
			console.log(`ajax error (onUploadImageComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 *
	 * 画像削除
	 * @memberof FormModel
	 * @param {string} imageKey - イメージID
	 * @return {undefined}
	 */
	deleteImage(imageKey) {
		if (this._post) return;
		console.log(`FormModel.deleteImage`);
		Common.showLoading();
		this._post = true;
		let formData = {
			key: imageKey
		};
		const url = window.const.API_URL.DELETE_IMAGE;
		let params = {};
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onDeleteImageComplete.bind(this));
	};


	/**
	 * 画像削除ajax通信完了
	 *
	 * @memberof FormModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onDeleteImageComplete(data, textStatus) {
		console.log('FormModel.onDeleteImageComplete');
		Common.hideLoading();
		console.log(this._data);
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false;
				Common.showErrorModal(`${this._message}<br>(${this._code})`);
			} else {
				this._post = false;
				this._listener.trigger('deleteImageComplete');
			}
		} else {
			this._post = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			Common.showErrorModal(`${this._message}<br>(${this._code})`);
			console.log(`ajax error (onDeleteImageComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 *
	 * 店舗取得
	 * @memberof FormModel
	 * @param {number} companyId - 企業ID
	 * @return {undefined}
	 */
	getShops(companyId) {
		if (this._post) return;
		if (!companyId) {
			this._listener.trigger('clearShop');
			return;
		}
		console.log(`FormModel.companyId`);
		Common.showLoading();
		this._post = true;
		let formData = new FormData();
		formData.append('company_id', companyId);
		const url = window.const.API_URL.GET_SHOPS;
		let params = {};
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		params['processData'] = false;
		params['contentType'] = false;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onGetShopsComplete.bind(this));
	};


	/**
	 * 店舗取得ajax通信完了
	 *
	 * @memberof FormModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onGetShopsComplete(data, textStatus) {
		console.log('FormModel.onGetShopsComplete');
		Common.hideLoading();
		this._data = data.result;
		console.log(this._data);
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false;
				this._listener.trigger('error');
			} else {
				this._post = false;
				this._listener.trigger('getShopsComplete');
			}
		} else {
			this._post = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			this._listener.trigger('error');
			console.log(`ajax error (onGetShopsComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 *
	 * オフィシャルサポーター対象店舗確認
	 * @memberof FormModel
	 * @param {string} shopId - 店舗ID
	 * @return {undefined}
	 */
	checkOS(shopIds) {
		if (this._post) return;
		console.log(`FormModel.checkOS`);
		Common.showLoading();
		this._post = true;
		let formData = {
			shop_id: shopIds
		};
		const url = window.const.API_URL.CHECK_OS;
		let params = {};
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onCheckOSComplete.bind(this));
	};


	/**
	 * オフィシャルサポーター対象店舗確認ajax通信完了
	 *
	 * @memberof FormModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onCheckOSComplete(data, textStatus) {
		console.log('FormModel.onCheckOSComplete');
		Common.hideLoading();
		console.log(this._data);
		this._data = data.result;
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false;
				Common.showErrorModal(`${this._message}<br>(${this._code})`);
			} else {
				this._post = false;
				this._listener.trigger('onCheckOSComplete');
			}
		} else {
			this._post = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			Common.showErrorModal(`${this._message}<br>(${this._code})`);
			console.log(`ajax error (onCheckOSComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 *
	 * オプショングループ取得
	 * @memberof FormModel
	 * @param {number} companyId - 企業ID
	 * @return {undefined}
	 */
	getOptionGroups(companyId) {
		if (this._post) return;
		console.log(`FormModel.getOptionGroups`);
		Common.showLoading();
		this._post = true;
		let formData = new FormData();
		formData.append('company_id', companyId);
		const url = window.const.API_URL.GET_OPTION_GROUPS;
		let params = {};
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		params['processData'] = false;
		params['contentType'] = false;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onGetOptionGroupsComplete.bind(this));
	};


	/**
	 * オプショングループ取得ajax通信完了
	 *
	 * @memberof FormModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onGetOptionGroupsComplete(data, textStatus) {
		console.log('FormModel.onGetOptionGroupsComplete');
		Common.hideLoading();
		this._data = data.result;
		console.log(this._data);
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false;
				this._listener.trigger('error');
			} else {
				this._post = false;
				this._listener.trigger('getOptionGroupsComplete');
			}
		} else {
			this._post = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			this._listener.trigger('error');
			console.log(`ajax error (onGetOptionGroupsComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 *
	 * サポーター店舗取得
	 * @memberof FormModel
	 * @param {number} companyId - 企業ID
	 * @return {undefined}
	 */
	getSupporterShops(companyId) {
		if (this._post) return;
		console.log(`FormModel.getSupporterShops`);
		Common.showLoading();
		this._post = true;
		let formData = new FormData();
		formData.append('company_id', companyId);
		const url = window.const.API_URL.GET_SUPPORTER_SHOPS;
		let params = {};
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		params['processData'] = false;
		params['contentType'] = false;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onGetSupporterShopsComplete.bind(this));
	};


	/**
	 * サポーター店舗取得ajax通信完了
	 *
	 * @memberof FormModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onGetSupporterShopsComplete(data, textStatus) {
		console.log('FormModel.onGetSupporterShopsComplete');
		Common.hideLoading();
		this._data = data.result;
		console.log(this._data);
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false;
				this._listener.trigger('error');
			} else {
				this._post = false;
				this._listener.trigger('getShopsComplete');
			}
		} else {
			this._post = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			this._listener.trigger('error');
			console.log(`ajax error (onGetShopsComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 *
	 * メニュー提供時間帯取得
	 * @memberof FormModel
	 * @param {number} shopId - 店舗ID
	 * @return {undefined}
	 */
	getMenuTime(shopId) {
		if (this._post) return;
		console.log(`FormModel.getMenuTime`);
		Common.showLoading();
		this._post = true;
		let formData = new FormData();
		formData.append('shop_id', shopId);
		const url = window.const.API_URL.GET_MENU_TIME;
		let params = {};
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		params['processData'] = false;
		params['contentType'] = false;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onGetMenuTimeComplete.bind(this));
	};


	/**
	 * メニュー提供時間帯取得ajax通信完了
	 *
	 * @memberof FormModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onGetMenuTimeComplete(data, textStatus) {
		console.log('FormModel.onGetMenuTimeComplete');
		Common.hideLoading();
		this._data = data.result;
		console.log(this._data);
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false;
				this._listener.trigger('error');
			} else {
				this._post = false;
				this._listener.trigger('getMenuTimeComplete');
			}
		} else {
			this._post = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			this._listener.trigger('error');
			console.log(`ajax error (onGetMenuTimeComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 *
	 * メニュー取得
	 * @memberof FormModel
	 * @param {number} menuId - メニュー提供時間帯ID
	 * @return {undefined}
	 */
	getMenu(shopId, menuId) {
		if (this._post) return;
		console.log(`FormModel.getMenu`);
		Common.showLoading();
		this._post = true;
		let formData = new FormData();
		formData.append('shop_id', shopId);
		for (let i = 0; i < menuId.length; i++) {
			formData.append("menu_id[]", menuId[i]);
		}
		for (let value of formData.entries()) {
			console.log(value);
		}
		const url = window.const.API_URL.GET_MENU;
		let params = {};
		params['data'] = formData;
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		params['processData'] = false;
		params['contentType'] = false;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.onGetMenuComplete.bind(this));
	};


	/**
	 * メニュー取得ajax通信完了
	 *
	 * @memberof FormModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	onGetMenuComplete(data, textStatus) {
		console.log('FormModel.onGetMenuComplete');
		Common.hideLoading();
		this._data = data.result;
		console.log(this._data);
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false;
				this._listener.trigger('error');
			} else {
				this._post = false;
				this._listener.trigger('getMenuComplete');
			}
		} else {
			this._post = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			this._listener.trigger('error');
			console.log(`ajax error (onGetMenuComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 * 全店舗のID / 店舗名のリストを取得
	 *
	 * @memberof FormModel
	 * @return {undefined}
	 */
	getSelectDisplayList(idx) {
		if (this._post) return;
		console.log(`FormModel.getSelectDisplayList`);
		Common.showLoading();
		this._post = true;
		const url = idx == '2'
			? window.const.API_URL.GET_ALL_SHOPS
			: window.const.API_URL.GET_ALL_COMPANIES
		let params = {};
		params['method'] = 'POST';
		params['dataType'] = 'json';
		params['url'] = url;
		params['processData'] = false;
		params['contentType'] = false;
		console.log(JSON.stringify(params));
		this._jqXHR = $.ajax(params).always(this.getSelectDisplayListComplete.bind(this));
	};


	/**
	 * 全店舗のID / 店舗名のリストを取得ajax通信完了
	 *
	 * @memberof FormModel
	 * @param {Object} data - data|jqXHR
	 * @param {String} textStatus - "success", "notmodified", "nocontent", "error", "timeout", "abort", or "parsererror"
	 * @return {undefined}
	 */
	getSelectDisplayListComplete(data, textStatus) {
		console.log('FormModel.getSelectDisplayListComplete');
		Common.hideLoading();
		this._data = data.result;
		console.log(this._data);
		this._code = data.code;
		this._message = data.message;
		console.log(`code: ${this._code} / message: ${this._message}`);
		if (textStatus === 'success') {
			if (this._code !== 0) {
				this._post = false;
				this._listener.trigger('error');
			} else {
				this._post = false;
				this._listener.trigger('getSelectDisplayListComplete');
			}
		} else {
			this._post = false;
			this._code = 9999;
			this._message = '通信エラーが発生しました。<br>再度お試しください。';
			this._listener.trigger('error');
			console.log(`ajax error (getSelectDisplayListComplete): ${textStatus}`);
		}
		this._jqXHR = null;
	};


	/**
	 * listener on
	 *
	 * @memberof FormModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listner off
	 *
	 * @memberof FormModel
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn);
	};
}

/**
 * @file オプション有無変更
 */
'use strict';
import Template from '../../template/_template';
import ApplyClass from '../../util/_apply-class';
import FormSelect2View from './_form-select2-view';

/**
 * @classname FormChangeOptionView
 * @classdesc オプション有無変更
 */
export default class FormChangeOptionView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('FormChangeOptionView.constructor');
		this._$e = $e;
		this._controller = controller;
		if($e.closest('.custom-radio').hasClass('detail')){
			$e.on('click', () => this.onClick());
		}else{
			$e.on('click', () => this.onClickRegist());
			this._controller.on('getOptionGroupsComplete', () => this.onGetOptionGroupsComplete());
		}
	};


	/**
	 * オプション有無変更変更
	 *
	 * @memberof FormChangeOptionView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormChangeOptionView.onClick');
		const $options = $('#options');
		if(this._$e.val() === '0') {
			$options.slideUp();
			$options.find('.validate').val(null).trigger('change').addClass('ignore-validate');
			$options.find('.select2-selection').removeClass('is-invalid');
			$options.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
		}else{
			$options.slideDown();
			$options.find('.validate').removeClass('ignore-validate');
		}
	};


	/**
	 * オプション有無変更変更
	 *
	 * @memberof FormChangeOptionView
	 * @return {undefined}
	 */
	onClickRegist() {
		console.log('FormChangeOptionView.onClick');
		const $options = $('#options');
		if(this._$e.val() === '0') {
			$options.slideUp();
			$options.find('.validate').val(null).trigger('change').addClass('ignore-validate');
			$options.find('.select2-selection').removeClass('is-invalid');
			$options.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
		}else{
			const companyId = $('#company_id').val();
			if(!companyId || companyId === ''){
				$('#option_y').click();
			}else{
				this._controller.getOptionGroups(companyId);
			}
		}
	};


	/**
	 * オプショングループ取得完了
	 *
	 * @memberof FormChangeOptionView
	 * @return {undefined}
	 */
	onGetOptionGroupsComplete() {
		console.log('FormChangeOptionView.onGetOptionGroupsComplete');
		const $options = $('#options');
		const optionGroups = this._controller._optionGroups.option_groups;
		console.log(optionGroups);
		const optionGroup = Template.getOptionGroup(optionGroups);
		$options.html(optionGroup);
		const $select = $('#options .select2');
		ApplyClass.apply(FormSelect2View, $select, [$select, this._controller]);
		$options.slideDown();
		$options.find('.validate').removeClass('ignore-validate');
	};
}

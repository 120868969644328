/**
 * @file Basic Controller
 */

'use strict';
import Listener from '../util/_listener';
import ApplyClass from '../util/_apply-class';
import BasicModel from '../model/_basic-model';

let instance = null;

/**
 * @classname Basic
 * @classdesc Basic Controller
 */
export default class Basic {

	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance;
		}
		console.log('Basic.constructor');
		this._model = new BasicModel();
		this._listener = new Listener();

		this._holiday = null;
		this._date = null;

		this._model.on('getHolidaysComplete', () => {
			this._holidays = this._model._data;
			this._listener.trigger('getHolidaysComplete');
		});
		this._model.on('changeHolidayComplete', () => {
			if(this._holiday){
				this._listener.trigger('changeHolidayCompleteOn');
			}else{
				this._listener.trigger('changeHolidayCompleteOff');
			}
			this._holiday = null;
		});
		this._model.on('getDeadlineComplete', () => {
			this._deadline = this._model._data;
			this._listener.trigger('getDeadlineComplete');
		});
		this._model.on('changeDeadlineComplete', () => {
			this._listener.trigger('changeDeadlineComplete');
		});
		this._model.on('getStockComplete', () => {
			this._stock = this._model._data;
			this._listener.trigger('getStockComplete');
		});
		this._model.on('deleteIndividualIdComplete', () => {
			console.log(`deleteIndividualIdComplete on Basic`)
			console.log(this._processingDeleteIndividualId);
			this._listener.trigger('deleteIndividualIdComplete');
		});

		this._shops = [[],[],[],[],[],[],[]];

		instance = this;
		return instance;
	};


	/**
	 * ラベルの初期設定をする
	 *
	 * @memberof BtnRemoveLabelView
	 * @return {undefined}
	 */
	shopLabelInit() {
		console.log('Basic.shopLabelInit');
		$('.shop-label').each((idx, e) => {
			const $e = $(e);
			const $shopId = $e.find('[name^=shop_id]');
			const dow = parseInt($shopId.attr('data-dow'));
			const shopId = $shopId.val();
			this._shops[dow].push(shopId);
		});
		console.log(this._shops);
	};


	/**
	 * 個別ID削除
	 *
	 * @memberof Basic
	 * @return {undefined}
	 */
	deleteIndividualId(individualId) {
		console.log('Basic.deleteIndividualId');
		this._processingDeleteIndividualId = individualId;
		console.log(this._processingDeleteIndividualId);
		this._model.deleteIndividualId(individualId);
	};


	/**
	 * listener on
	 *
	 * @memberof Basic
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listener off
	 *
	 * @memberof Basic
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn);
	};
}

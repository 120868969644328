/**
 * @file 画像削除
 */
'use strict';

/**
 * @classname FormDeleteImageView
 * @classdesc 画像削除
 */
export default class FormDeleteImageView {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('FormDeleteImageView.constructor');
		this._$e = $e;
		this._controller = controller;
		this._imageKey = this._$e.attr('data-image-key');
		console.log(`${this._imageKey}`);
		this._$modal = $('.modal-image-modal');
		this._clicked = false;
		$e.on('click', () => this.onClick());
		controller.on('deleteImageComplete', () => {
			console.log(`${this._imageKey} / ${this._controller._imageKey}`);
			if(this._imageKey === this._controller._imageKey){
				this.onDeleteImageComplete();
			}
		});
	};


	/**
	 * 画像削除ボタンクリック
	 *
	 * @memberof FormDeleteImageView
	 * @param {event) e - イベント
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormDeleteImageView.onClick');
		if(this._$e.hasClass('anchor-photo')) {
			this._clicked = true;
		}else{
			this._controller.deleteImage(this._imageKey);
		}
	};


	/**
	 * 画像の削除
	 *
	 * @memberof FormDeleteImageView
	 * @return {undefined}
	 */
	onDeleteImageComplete() {
		console.log('FormDeleteImageView.onDeleteImageComplete');
		const imageKey = this._controller._imageKey;
		this._controller._imageKey = null;
		$(`.anchor-delete-image[data-image-key="${imageKey}"]`).closest('.upload-image-wrap').remove();
	};
}

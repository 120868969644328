/**
 * @file トグル（select）
 */
'use strict';

/**
 * @classname FormSelectToggleView
 * @classdesc トグル（select）
 */
export default class FormSelectToggleView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormSelectToggleView.constructor');
		this._$e = $e;
		this._$target = $($e.attr('data-select-toggle'));
		this._trigger = $e.attr('data-select-trigger');
		$e.on('change', () => this.onChange());
	}


	/**
	 * changeのコールバック
	 *
	 * @memberof FormSelectToggleView
	 * @return {undefined}
	 */
	onChange() {
		console.log('FormSelectToggleView.onChange');
		if(this._$e.val() === this._trigger){
			this._$target.slideDown().find('.validate').removeClass('ignore-form');
			this._$target.find('.error-tip').removeClass('show').find('.error-tip-inner').html('');
		}else{
			this._$target.slideUp().find('.validate').addClass('ignore-form').removeClass('is-invalid').val('');
			this._$target.find('.error-tip').removeClass('show').find('.error-tip-inner').html('');
		}
	};
}

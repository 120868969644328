/**
 * @file CSVダウンロード
 *
 */
'use strict';
import Common from '../../util/_common';

/**
 * @classname TableCsvDlView
 * @classdesc CSVダウンロード
 */
export default class TableCsvDlView {
	/**
	 * @constructor
	 * @param {object} controller - controllerオブジェクト
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('TableCsvDlView.constructor');
		this._$e = $e;
		this._params = location.search;
		$e.on('click', () => { this.onClick(); });
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof TableCsvDlView
	 * @return {undefined}
	 */
	onClick() {
		console.log('TableCsvDlView.onClick');
		if (this._$e.hasClass('btn-point')) {
			location.href = window.const.URL.CSV_POINT + this._params;
		} else if (this._$e.hasClass('btn-supporter')) {
			location.href = window.const.URL.CSV_SUPPORTER + this._params;
		}	else {
			location.href = window.const.URL.CSV + this._params;
		}
	};
}

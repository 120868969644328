/**
 * @file 店舗取得
 *
 */
'use strict';
import ApplyClass from '../../util/_apply-class';
import FormSelect2View from '../form/_form-select2-view';
import FormValidateView from '../form/_form-validate-view';

/**
 * @classname TableGetShopsView
 * @classdesc 店舗取得
 */
export default class TableGetShopsView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('TableGetShopsView.constructor');
		this._$e = $e;
		this._controller = controller;
		$e.on('change', () => this.onChange());
		controller.on('getShopsComplete', () => this.onGetShopsComplete());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof TableGetShopsView
	 * @return {undefined}
	 */
	onChange() {
		console.log('TableGetShopsView.onChange');
		console.log(this._$e.val());
		if(!this._$e.val() || this._$e.val() === ''){
			$('#shop_id').html('<option value="">企業を選択してください</option>');
		}else{
			this._controller.getShops();
		}
	};


	/**
	 * 店舗取得のコールバック
	 *
	 * @memberof TableGetShopsView
	 * @return {undefined}
	 */
	onGetShopsComplete() {
		console.log('TableGetShopsView.onGetShopsComplete');
		const shops = this._controller._shops.shops;
		let options = '<option value="">選択してください</option>';
		for(let i = 0; i < shops.length; i++){
			options += `<option value="${shops[i].shop_id}">${shops[i].shop_name}</option>`
		}
		const $select = '<select class="form-control validate select2 shop_id" name="shop_id" id="shop_id" data-validate="empty"></select>';
		$('.shop-select-wrap').html($select);
		const $shopId = $('#shop_id');
		$shopId.html(options);
		const FormController = this._controller;
		ApplyClass.apply(FormValidateView, $shopId, [$shopId, FormController]);
		ApplyClass.apply(FormSelect2View, $shopId, [$shopId, FormController]);
	};
}

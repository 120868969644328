/**
 * @file Form送信
 */
'use strict';


/**
 * @classname FormDatepickerView
 * @classdesc datepicker
 */
export default class FormDatepickerView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log('FormDatepickerView.constructor');
		this._$e = $e;
		this._controller = controller;
		const clear = !!($e.attr('data-clear'));
		let minDate = this._$e.attr('data-mindate') || '';
		let maxDate = this._$e.attr('data-maxdate') || '';
		if(minDate !== '') {
			if(minDate.match(/\d{4}\/\d{2}\/\d{2}/)){
				console.log('minDate match date');
				minDate = minDate.split('/');
				minDate = new Date(minDate[0], minDate[1] - 1, minDate[2], now.getHours(), 0, 0);
			}else if(minDate.match(/^-?\d+$/)){
				console.log('minDate match number');
				let mindate = new Date();
				mindate.setDate(mindate.getDate() + parseInt(minDate, 10));
				mindate.setMinutes(0);
				mindate.setSeconds(0);
				minDate = mindate;
			}
		}
		if(maxDate !== '') {
			if(maxDate.match(/\d{4}\/\d{2}\/\d{2}/)){
				console.log('maxDate match date');
				maxDate = maxDate.split('/');
				maxDate = new Date(maxDate[0], maxDate[1] - 1, maxDate[2], now.getHours(), 0, 0);
			}else if(maxDate.match(/^-?\d+$/)){
				console.log('maxDate match number');
				let maxdate = new Date();
				maxdate.setDate(maxdate.getDate() + parseInt(maxDate, 10));
				maxdate.setMinutes(0);
				maxdate.setSeconds(0);
				maxDate = maxdate;
			}else{
				maxDate = '';
			}
		}
		const time = !!($e.attr('data-time'));
		const navTitles = {
			days: 'yyyy 年 mm 月',
			months: 'yyyy',
			years: 'yyyy1 - yyyy2'
		};
		const opts = {
			language: 'ja',
			position: 'bottom left',
			minDate: minDate,
			maxDate: maxDate,
			timepicker: time,
			autoClose: true,
			clearButton: clear,
			navTitles: navTitles,
			onShow: (inst, animationCompleted) => {
				if(!clear){
					if(animationCompleted){
						const value = $e.val();
						$e.attr('data-value', value);
					}
				}
			},
			onHide: (inst, animationCompleted) =>{
				if(!clear){
					if(!animationCompleted){
						if(!$e.val() || $e.val() === ''){
							$e.val($e.attr('data-value'));
						}
						$e.removeAttr('data-value');
					}
				}
			},
			onSelect: (inst, animationCompleted) =>{
				$e.removeClass('is-invalid').closest('.col-sm-3').find('.error-tip').removeClass('show').find('.error-tip-inner').text();
			}
		};
		this._datepicker = $e.datepicker(opts);
		let value = $e.val();
		if(value) {
			// 値が入っている場合は時間や分を削る
			if (time) {
				$e.val(value.substr(0, 16));
			}else{
				$e.val(value.substr(0, 10));
			}
			console.log($e.val());
			// その日付を選択済にする
			if(time){
				let tmp = value.split(' ');
				let tmp2 = tmp[0].split('-');
				let tmp3 = tmp[1].split(':');
				value = new Date(tmp2[0], tmp2[1] - 1, tmp2[2], tmp3[0], tmp3[1], 0);
			}else{
				value = value.split('-');
				value = new Date(value[0], value[1] - 1, value[2], 0, 0, 0);
			}
		}
		console.log(value);
		const dp = this._$e.datepicker().data('datepicker');
		dp.selectDate(value);
		this._$e.next('.input-group-append').on('click', () => this.onClick());
	}


	/**
	 * クリックのコールバック
	 *
	 * @memberof FormDatepickerView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormDatepickerView.onClick');
		if(this._$e.attr('disabled')) return;
		const value = this._$e.val();
		const dp = this._$e.datepicker().data('datepicker');
		dp.show();
		if(value !== ''){
			this._$e.attr('data-value', 'value');
			this._$e.val(value);
		}
	};
}

/**
 * @file GetSupporterShopsView
 *
 */
'use strict';
import ApplyClass from '../../util/_apply-class';
import FormSelect2View from './_form-select2-view';
import FormValidateView from './_form-validate-view';
import FormGetMenuTimeView from './_form-get-menu-time-view';

/**
 * @classname GetSupporterShopsView
 * @classdesc GetSupporterShopsView
 */
export default class GetSupporterShopsView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log('GetSupporterShopsView.constructor');
		this._$e = $e;
		this._controller = controller;
		$e.on('change', () => this.onChange());
		controller.on('getShopsComplete', () => this.setShops());
	};


	/**
	 * 変更のコールバック
	 *
	 * @memberof GetSupporterShopsView
	 * @return {undefined}
	 */
	onChange() {
		console.log(`GetSupporterShopsView.onChange`);
		$('.shop-select-wrap').html('<p class="mt8 text">企業を選択してください。</p>');
		$('.menutime-select-wrap').html('<p class="mt8 text">店舗を選択してください。</p>');
		$('.menu-select-wrap').html('<p class="mt8 text">メニュー提供時間帯を選択してください。</p>');
		const companyId = $('#company_id').val();
		if(companyId && companyId !== ''){
			this.getSupporterShops();
		}else{
			this.clearShop();
		}
	};


	/**
	 * 店舗取得
	 *
	 * @memberof GetSupporterShopsView
	 * @return {undefined}
	 */
	getSupporterShops() {
		console.log(`GetSupporterShopsView.getSupporterShops`);
		const companyId = this._$e.val();
		this._controller.getSupporterShops(companyId);
	};


	/**
	 * 店舗設定
	 *
	 * @memberof GetSupporterShopsView
	 * @param {number} companyId - 企業ID
	 * @return {undefined}
	 */
	setShops() {
		console.log(`GetSupporterShopsView.setShop`);
		const shops = this._controller._shops.shops;
		let options = '<option value="">選択してください</option>';
		for(let i = 0; i < shops.length; i++){
			options += `<option value="${shops[i].shop_id}">${shops[i].shop_name}</option>`
		}
		let $select = '<select class="form-control validate select2 shop_id get-menutime" name="shop_id" id="shop_id" data-validate="empty"></select>';
		$('.shop-select-wrap').html($select);
		const $shopId = $('#shop_id');
		$shopId.html(options);
		const FormController = this._controller;
		ApplyClass.apply(FormValidateView, $shopId, [$shopId, FormController]);
		ApplyClass.apply(FormSelect2View, $shopId, [$shopId, FormController]);
		ApplyClass.apply(FormGetMenuTimeView, $shopId, [$shopId, FormController]);
	};


	/**
	 * 店舗消去
	 *
	 * @memberof GetSupporterShopsView
	 * @return {undefined}
	 */
	clearShop() {
		console.log(`GetSupporterShopsView.clearShop`);
		$('.shop-select-wrap').html('<p class="mt8 text">企業を選択してください。</p>');
		$('.menutime-select-wrap').html('<p class="mt8 text">店舗を選択してください。</p>');
		$('.menu-select-wrap').html('<p class="mt8 text">メニュー提供時間帯を選択してください。</p>');
	};
}

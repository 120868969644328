/**
 * @file 郵便番号
 *
 */
'use strict';

/**
 * @classname FormYubinbangoView
 * @classdesc 郵便番号
 */
export default class FormYubinbangoView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 名前かなオブジェクト
	 */
	constructor($e, controller) {
		console.log('FormYubinbangoView.constructor');
		this._$zip2 = $e;
		this._$zip1 = $e.closest('.block-zip').find('.zip1');
		this._$zip1.on('blur', () => this.onBlur());
		this._$zip2.on('blur', () => this.onBlur());
	};


	/**
	 * blurのコールバック
	 *
	 * @memberof FormYubinbangoView
	 * @return {undefined}
	 */
	onBlur() {
		console.log('FormYubinbangoView.onBlur');
		const zip1 = this._$zip1.val();
		const zip2 = this._$zip2.val();
		const $regionId = this._$zip1.closest('.h-adr').find('.p-region-id');
		const $locality = this._$zip1.closest('.h-adr').find('.p-locality');
		const zip = zip1 + zip2;
		if(zip.length !== 7 || $regionId.val() === '' || $locality.val() === '') return;
		$regionId
			.removeClass('is-invalid')
			.closest('.form-group')
			.find('.error-tip')
			.removeClass('show')
			.find('.error-tip-inner')
			.text('');
		$locality
			.removeClass('is-invalid')
			.closest('.form-group')
			.find('.error-tip')
			.removeClass('show')
			.find('.error-tip-inner')
			.text('');
	};

	/**
	 * フォーカスのコールバック
	 *
	 * @memberof FormYubinbangoView
	 * @return {undefined}
	 */
	onFocus() {
		console.log('FormYubinbangoView.onFocus');
		this.stateInput();
		this.setInterval();
	};

	/**
	 * keydownのコールバック
	 *
	 * @memberof FormYubinbangoView
	 * @return {undefined}
	 */
	onKeyDown() {
		console.log('FormYubinbangoView.onKeyDown');
		if (this._flagConvert) {
			this.stateInput();
		}
	};


	/**
	 * ステータスクリア
	 *
	 * @memberof FormYubinbangoView
	 * @return {undefined}
	 */
	stateClear() {
		//console.log('FormYubinbangoView.stateClear');
		this._baseKana = '';
		this._flagConvert = false;
		this._ignoreString = '';
		this._input = '';
		this._values = [];
	};


	/**
	 * 入力中
	 *
	 * @memberof FormYubinbangoView
	 * @return {undefined}
	 */
	stateInput() {
		//console.log('FormYubinbangoView.stateInput');
		this._baseKana = this._$nameKana.val();
		this._flagConvert = false;
		this._ignoreString = this._$name.val();
	};


	/**
	 * 変換中
	 *
	 * @memberof FormYubinbangoView
	 * @return {undefined}
	 */
	stateConvert() {
		//console.log('FormYubinbangoView.stateConvert');
		this._baseKana = this._baseKana + this._values.join('');
		this._flagConvert = true;
		this._values = [];
	};


	/**
	 * タイマー設定
	 *
	 * @memberof FormYubinbangoView
	 * @return {undefined}
	 */
	setInterval() {
		//console.log('FormYubinbangoView.setInterval');
		this._timer = setInterval(() => {this.checkValue()}, 20);
	};


	/**
	 * タイマー解除
	 *
	 * @memberof FormYubinbangoView
	 * @return {undefined}
	 */
	clearInterval() {
		console.log('FormYubinbangoView.clearInterval');
		clearInterval(this._timer);
	};


	/**
	 * ひらがな確認
	 *
	 * @memberof FormYubinbangoView
	 * @return {undefined}
	 */
	isHiragana(chara) {
		console.log('FormYubinbangoView.isHiragana');
		return ((chara >= 12353 && chara <= 12435) || chara == 12445 || chara == 12446);
	};


	/**
	 * 変換確認
	 *
	 * @memberof FormYubinbangoView
	 * @return {undefined}
	 */
	checkConvert(new_values) {
		console.log('FormYubinbangoView.checkConvert');
		if (!this._flagConvert) {
			if (Math.abs(this._values.length - new_values.length) > 1) {
				let tmp_values = new_values.join('').replace(this._kana_compacting_pattern, '').split('');
				if (Math.abs(this._values.length - tmp_values.length) > 1) {
					this.stateConvert();
				}
			} else {
				if (this._values.length === this._input.length && this._values.join('') !== this._input) {
					if (this._input.match(this._kana_extraction_pattern)) {
						this.stateConvert();
					}
				}
			}
		}
	};


	/**
	 * 値確認
	 *
	 * @memberof FormYubinbangoView
	 * @return {undefined}
	 */
	checkValue() {
		// console.log('FormYubinbangoView.checkValue');
		let new_input, new_values;
		new_input = this._$name.val();
		if (new_input === '') {
			this.stateClear();
			this.setKana();
		} else {
			new_input = this.removeString(new_input);
			if (this._input === new_input) {
				return;
			} else {
				this._input = new_input;
				if (!this._flagConvert) {
					new_values = new_input.replace(this._kana_extraction_pattern, '').split('');
					this.checkConvert(new_values);
					this.setKana(new_values);
				}
			}
		}
	};


	/**
	 * 文字列削除
	 *
	 * @memberof FormYubinbangoView
	 * @return {undefined}
	 */
	removeString(new_input) {
		console.log('FormYubinbangoView.removeString');
		if (new_input.indexOf(this._ignoreString) !== -1) {
			return new_input.replace(this._ignoreString, '');
		} else {
			let i, ignoreArray, inputArray;
			ignoreArray = this._ignoreString.split('');
			inputArray = new_input.split('');
			for (i = 0; i < ignoreArray.length; i++) {
				if (ignoreArray[i] === inputArray[i]) {
					inputArray[i] = '';
				}
			}
			return inputArray.join('');
		}
	};


	/**
	 * カナ設定
	 *
	 * @memberof FormYubinbangoView
	 * @return {undefined}
	 */
	setKana(new_values) {
		console.log('FormYubinbangoView.setKana');
		if (!this._flagConvert) {
			if (new_values) {
				this._values = new_values;
			}
			if (this._active) {
				let val = this.toKatakana(this._baseKana + this._values.join(''));
				this._$nameKana.val(val);
			}
		}
	};


	/**
	 * ひらがな→カタカナ変換
	 *
	 * @memberof FormYubinbangoView
	 * @return {undefined}
	 */
	toKatakana(src) {
		console.log('FormYubinbangoView.toKatakana');
		let c, i, str;
		str = new String;
		for (i = 0; i < src.length; i++) {
			c = src.charCodeAt(i);
			if (this.isHiragana(c)) {
				str += String.fromCharCode(c + 96);
			} else {
				str += src.charAt(i);
			}
		}
		return str;
	};
}

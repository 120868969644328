/**
 * @file 権限変更
 */
'use strict';

/**
 * @classname FormChangeLevelView
 * @classdesc 権限変更
 */
export default class FormChangeLevelView {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('FormChangeLevelView.constructor');
		this._$e = $e;
		this._controller = controller;
		$e.on('change', () => this.onChange());
	};


	/**
	 * 権限変更
	 *
	 * @memberof FormChangeLevelView
	 * @param {event) e - イベント
	 * @return {undefined}
	 */
	onChange() {
		console.log('FormChangeLevelView.onChange');
		const $affiliated = $('#affiliated');
		const $shopId = $('#shop_id');
		const $shopSelectWrap = $('.shop-select-wrap');
		if(this._$e.val() === '0') {
			$affiliated.slideUp().find('#shop_id').val('').removeClass('is-invalid').addClass('ignore-validate');
			$affiliated.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
			$shopId.val(null).trigger('change');
			$shopSelectWrap.html('');
		}else if(this._$e.val() === '1') {
			$affiliated.slideUp();
			$shopId.removeClass('is-invalid').addClass('ignore-validate').val('').trigger('change');
			$affiliated.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
			$shopSelectWrap.html('');
		}else{
			$shopId.removeClass('ignore-validate');
			console.log($shopId.val());
			$affiliated.slideDown();
		}
	};
}

/**
 * @file 表示件数変更
 *
 */
'use strict';

/**
 * @classname TableCountView
 * @classdesc 表示件数変更
 */
export default class TableCountView {
	/**
	 * @constructor
	 * @param {object} controller - controllerオブジェクト
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('TableCountView.constructor');
		this._$e = $e;
		this._controller = controller;
		this._count = parseInt($e.attr('data-search-count'));
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof TableCountView
	 * @return {undefined}
	 */
	onClick() {
		if(this._$e.hasClass('active')) return;
		console.log('TableCountView.onClick');
		this._controller.count(this._count);
	};
}

/**
 * @file 全てチェック
 *
 */
'use strict';

/**
 * @classname TableCheckAllView
 * @classdesc 全てチェック
 */
export default class TableCheckAllView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('TableCheckAllView.constructor');
		this._$e = $e;
		this._$target = $('.input-checkbox.check-target');
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof TableClearSearchView
	 * @return {undefined}
	 */
	onClick() {
		console.log('TableCheckAllView.onClick');
		if(this._$e.prop('checked')){
			this.checkAll();
		}else{
			this.uncheckAll();
		}
	};


	/**
	 * 全てチェック
	 *
	 * @memberof TableClearSearchView
	 * @return {undefined}
	 */
	checkAll() {
		console.log('TableCheckAllView.checkAll');
		this._$target.prop('checked', true);
	};


	/**
	 * 全てチェックを外す
	 *
	 * @memberof TableClearSearchView
	 * @return {undefined}
	 */
	uncheckAll() {
		console.log('TableCheckAllView.uncheckAll');
		this._$target.prop('checked', false);
	};
}

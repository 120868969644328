/**
 * @file 削除ボタン
 *
 */
'use strict';

/**
 * @classname TableBtnDeleteView
 * @classdesc 削除ボタン
 */
export default class TableBtnDeleteView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('TableBtnDeleteView.constructor');
		this._$e = $e;
		this._id = $e.attr('data-id');
		this._type = $e.attr('data-type');
		this._name = $e.attr('data-name');
		this._$modal = $('.modal-delete');
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof TableClearSearchView
	 * @return {undefined}
	 */
	onClick() {
		console.log('TableBtnDeleteView.onClick');
		this._$modal.find('.input-delete').attr('name', this._name).val(this._id);
		this._$modal.find('span.name').text(this._type);
	};
}

/**
 * @file 共通メッセージ
 *
 */
'use strict';
import Swal from 'sweetalert2/dist/sweetalert2.js';


/**
 * @classname SwalView
 * @classdesc 共通メッセージ
 */
export default class SwalView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('SwalView.constructor');
		this._message = $e.attr('data-message');
		if($e.hasClass('success')){
			this.success();
		}else if($e.hasClass('warning')){
			this.warning();
		}else if($e.hasClass('error')){
			this.error();
		}
	};


	/**
	 * 成功メッセージ
	 *
	 * @memberof SwalView
	 * @return {undefined}
	 */
	success() {
		console.log('SwalView.success');
		Swal.fire({
			type: 'success',
			html: this._message,
			confirmButtonColor: '#a5dc86',
			confirmButtonText: '閉じる'
		});
	};


	/**
	 * warningメッセージ
	 *
	 * @memberof SwalView
	 * @return {undefined}
	 */
	warning() {
		console.log('SwalView.warning');
		Swal.fire({
			type: 'warning',
			html: this._message,
			confirmButtonColor: '#f8bb86',
			confirmButtonText: '閉じる'
		});
	};


	/**
	 * エラーメッセージ
	 *
	 * @memberof SwalView
	 * @return {undefined}
	 */
	error() {
		console.log('SwalView.error');
		Swal.fire({
			type: 'error',
			title: 'エラー',
			html: this._message,
			confirmButtonColor: '#f27474',
			confirmButtonText: '閉じる'
		});
	};
}

/**
 * @file テーブルソート
 *
 */
'use strict';

/**
 * @classname TableSortView
 * @classdesc テーブルソート
 */
export default class TableSortView {
	/**
	 * @constructor
	 * @param {object} controller - controllerオブジェクト
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('TableSortView.constructor');
		this._$e = $e;
		this._controller = controller;
		$e.on('change', () => this.onChange());
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof TableSortView
	 * @return {undefined}
	 */
	onChange() {
		console.log('TableSortView.onChange');
		this._controller.sort();
	};
}

'use strict';

// utility class
import ApplyClass from '../util/_apply-class';

// form-class
import Form from '../controller/_form';
import FormValidateView from '../view/form/_form-validate-view';
import FormValidateCheckboxView from '../view/form/_form-validate-checkbox-view';
import FormSubmitView from '../view/form/_form-submit-view';
import FormDatepickerView from '../view/form/_form-datepicker-view';
import FormDeleteImageView from '../view/form/_form-delete-image-view';
import FormSelectToggleView from '../view/form/_form-select-toggle-view';
import FormFileDropView from '../view/form/_form-file-drop-view';
import FormPostCodeView from '../view/form/_form-postcode-view';
import FormZenkakuToHankakuView from '../view/form/_form-zenkaku-to-hankaku-view';
import FormAutoKanaView from '../view/form/_form-auto-kana-view';
import FormCheckGoogleMapView from '../view/form/_form-check-google-map-view';
import FormTelephoneView from '../view/form/_form-telephone-view';
import FormCounterView from '../view/form/_form-counter-view';
import FormSeparateCommaView from '../view/form/_form-separate-comma-view';
import FormTimeDivideView from '../view/form/_form-time-divide-view';
import FormChangeLevelView from '../view/form/_form-change-level-view';
import FormSelect2View from "../view/form/_form-select2-view";
import FormChangePasswordView from "../view/form/_form-change-password-view";
import FormChangeOptionView from "../view/form/_form-change-option-view";
import FormBtnAddOptionView from "../view/form/_form-btn-add-option-view";
import FormBtnDeleteOptionView from "../view/form/_form-btn-delete-option-view";
import FormUserPriceView from "../view/form/_form-user-price-view";
import FormGetShopsView from "../view/form/_form-get-shops-view";
import FormTableGetShopsView from "../view/table/_table-get-shops-view";
import FormCalculateTaxView from "../view/form/_form-calculate-tax-view";
import FormYubinbangoView from "../view/form/_form-yubinbango-view";
import FormIsAlcoholView from "../view/form/_form-is-alcohol-view";
import FormBtnAddDisplayView from "../view/form/_form-btn-add-display-view";
import FormBtnDeleteDisplayView from "../view/form/_form-btn-delete-display-view";
import FormBusinessHourView from "../view/form/_form-business-hour-view";
import FormBtnAddBreakView from "../view/form/_form-btn-add-break-view";
import FormBtnDeleteBreakView from "../view/form/_form-btn-delete-break-view";
import FormGetSupporterShopsView from "../view/form/_form-get-supporter-shops-view";
import FormGetMenuView from "../view/form/_form-get-menu-view";
import FormGetMenuTimeView from "../view/form/_form-get-menu-time-view";
import FormChangeDefaultStockOptionView from '../view/form/_form-change-default-stock-type-view';
import FormChangeItemTypeView from "../view/form/_form-change-item-type-view";
import FormChangeOptionPeriodTypeView from "../view/form/_form-change-option-period-type-view";
import FormChangeOptionCouponTypeView from "../view/form/_form-change-option-coupon-type-view";
import FormChangeOptionConditionTypeView from "../view/form/_form-change-option-condition-view";
import FormChangeOptionTargetTypeView from "../view/form/_form-change-option-target-type-view";
import FormOpenSearchTargetTypeModalView from '../view/form/_form-open-search-target-type-modal-view';

$(() => {
	const FormController = new Form();
	// バリデート
	$('.validate').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormValidateView, $e, [$e, FormController]);
	});
	// checkboxバリデート
	$('.validate-checkbox').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormValidateCheckboxView, $e, [$e, FormController]);
	});
	// フォームサブミット
	$('.btn[type=submit][data-form]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormSubmitView, $e, [$e, FormController]);
	});
	// デートピッカー
	$('input[data-datepicker=true]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormDatepickerView, $e, [$e, FormController]);
	});
	// トグル（select）
	$('[data-select-toggle]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormSelectToggleView, $e, [$e, FormController]);
	});
	// 画像アップロード
	$('.file-upload').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormFileDropView, $e, [$e, FormController]);
	});
	// 画像削除
	$('.anchor-delete-image').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormDeleteImageView, $e, [$e, FormController]);
	});
	//時間分割
	$('.time-divide').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormTimeDivideView, $e, [$e, FormController]);
	});
	//郵便番号分割
	$('.zip').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormPostCodeView, $e, [$e, FormController]);
	});
	//select2
	$('.select2').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormSelect2View, $e, [$e, FormController]);
	});
	//全角→半角変換
	$('.zen2han').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormZenkakuToHankakuView, $e, [$e, FormController]);
	});
	//カナ自動入力
	$('#shop_name').each(function (idx, e) {
		const $name = $('#shop_name');
		const $kana = $('#shop_name_kana');
		ApplyClass.apply(FormAutoKanaView, $name, [$name, $kana, FormController]);
	});
	$('#operator_name').each(function (idx, e) {
		const $name = $('#operator_name');
		const $kana = $('#operator_kana');
		ApplyClass.apply(FormAutoKanaView, $name, [$name, $kana, FormController]);
	});
	$('#company_name').each(function (idx, e) {
		const $name = $('#company_name');
		const $kana = $('#company_name_kana');
		ApplyClass.apply(FormAutoKanaView, $name, [$name, $kana, FormController]);
	});
	$('#contractor').each(function (idx, e) {
		const $name = $('#contractor');
		const $kana = $('#contractor_kana');
		ApplyClass.apply(FormAutoKanaView, $name, [$name, $kana, FormController]);
	});
	//Google Mapでの確認
	$('.btn-check-google-map').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormCheckGoogleMapView, $e, [$e, FormController]);
	});
	//電話番号自動区切り
	$('.telephone').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormTelephoneView, $e, [$e, FormController]);
	});
	//カウンター
	$('.text-length-counter').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormCounterView, $e, [$e, FormController]);
	});
	//3桁区切り
	$('.separate-comma').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormSeparateCommaView, $e, [$e, FormController]);
	});
	//権限変更
	$('.staff_level').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormChangeLevelView, $e, [$e, FormController]);
	});
	// パスワード変更
	$('#change').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormChangePasswordView, $e, [$e, FormController]);
	});
	// オプション有無変更
	$('[name="option_flg"]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormChangeOptionView, $e, [$e, FormController]);
	});
	// オプション追加
	$('.btn-add-option').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormBtnAddOptionView, $e, [$e, FormController]);
	});
	// オプション削除
	$('.btn-delete-option').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormBtnDeleteOptionView, $e, [$e, FormController]);
	});
	// 期間種別表示切替
	$('[name="period_type"]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormChangeOptionPeriodTypeView, $e, [$e, FormController]);
	});
	// クーポンタイプ表示切替
	$('[name="discount_type"]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormChangeOptionCouponTypeView, $e, [$e, FormController]);
	});
	// 利用条件表示切替
	$('#condition').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormChangeOptionConditionTypeView, $e, [$e, FormController]);
	});
	// 対象表示切替
	$('[name="target_type"]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormChangeOptionTargetTypeView, $e, [$e, FormController]);
	});
	// 対象選択モーダルを開く
	$('.select-search-target').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormOpenSearchTargetTypeModalView, $e, [$e, FormController]);
	});
	// ユーザー表示価格計算
	$('#delivery_fee_type').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormUserPriceView, $e, [$e, FormController]);
	});
	// 店舗取得
	$('.get-shops').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormGetShopsView, $e, [$e, FormController]);
	});
	// 店舗取得
	$('.get-table-shops').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormTableGetShopsView, $e, [$e, FormController]);
	});
	// 税金計算
	$('#item_price_tax').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormCalculateTaxView, $e, [$e, FormController]);
	});
	// 住所エラー削除
	$('.zip2').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormYubinbangoView, $e, [$e, FormController]);
	});
	// 酒フラグ変更
	$('#is_alcohol').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormIsAlcoholView, $e, [$e, FormController]);
	});
	// 表示時間追加
	$('.btn-add-display').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormBtnAddDisplayView, $e, [$e, FormController]);
	});
	// 表示時間削除
	$('.btn-delete-display').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormBtnDeleteDisplayView, $e, [$e, FormController]);
	});
	// 営業時間追加
	$('.btn-add-business-hour').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormBusinessHourView, $e, [$e, FormController]);
	});
	// 休憩時間追加
	$('.btn-add-break').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormBtnAddBreakView, $e, [$e, FormController]);
	});
	// 休憩時間削除
	$('.btn-delete-break').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormBtnDeleteBreakView, $e, [$e, FormController]);
	});
	// サポーター店舗取得
	$('.get-supporter-shops').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormGetSupporterShopsView, $e, [$e, FormController]);
	});
	// メニュー提供時間帯取得
	$('.get-menutime').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormGetMenuTimeView, $e, [$e, FormController]);
	});
	// メニュー取得
	$('.get-menu').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormGetMenuView, $e, [$e, FormController]);
	});
	// 商品在庫数設定要否
	$('[name=default_stock_limit]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormChangeDefaultStockOptionView, $e, [$e, FormController]);
	});
	// 商品準備方法変更
	$('[name=item_preparation_type]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(FormChangeItemTypeView, $e, [$e, FormController]);
	});
});
/**
 * @file Form Controller
 */

'use strict';
import Listener from '../util/_listener';
import ApplyClass from '../util/_apply-class';
import FormModel from '../model/_form-model';
import FormValidateView from '../view/form/_form-validate-view';
import FormBtnAddOptionView from '../view/form/_form-btn-add-option-view';
import FormBtnDeleteOptionView from '../view/form/_form-btn-delete-option-view';

let instance = null;

/**
 * @classname Form
 * @classdesc Form Controller
 */
export default class Form {

	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance;
		}
		console.log('Form.constructor');
		this._listener = new Listener();
		this._$form = null;
		this._submit = true;

		this._restRequiredNum = 0;

		this._prAreaNo = 1;

		// image upload
		this._model = new FormModel();
		this._imageKey = null;
		this._type = null;
		this._imageNo = null;
		this._favoriteActivate = null;
		this._model.on('uploadImageComplete', () => {
			this._imageData = this._model._data;
			this._listener.trigger('uploadImageComplete');
		});
		this._model.on('deleteImageComplete', () => {
			this._imageData = this._model._data;
			this._listener.trigger('deleteImageComplete');
		});
		this._model.on('getShopsComplete', () => {
			this._shops = this._model._data;
			this._listener.trigger('getShopsComplete');
		});
		this._model.on('getOptionGroupsComplete', () => {
			this._optionGroups = this._model._data;
			this._listener.trigger('getOptionGroupsComplete');
		});
		this._model.on('getSupporterShopsComplete', () => {
			this._shops = this._model._data;
			this._listener.trigger('getSupporterShopsComplete');
		});
		this._model.on('getMenuTimeComplete', () => {
			this._menuTime = this._model._data;
			this._listener.trigger('getMenuTimeComplete');
		});
		this._model.on('getMenuComplete', () => {
			this._menu = this._model._data;
			this._listener.trigger('getMenuComplete');
		});
		this._model.on('onCheckOSComplete', () => {
			this._data = this._model._data;
			console.log(this._data);
			if(this._data.enable_create_official_menu){
				this._listener.trigger('openOSBlock');
			}else{
				this._listener.trigger('closeOSBlock');
			}
			this._listener.trigger('setMenu');
		});
		this._model.on('clearShop', () => this._listener.trigger('clearShop'));
		this._model.on('error', () => this._listener.trigger('error'));
		this._model.on('getSelectDisplayListComplete', () => {
			this._selectDisplayList = this._model._data;
			this._listener.trigger('getSelectDisplayListComplete');
		});

		instance = this;
		return instance;
	};


	/**
	 * 前処理終了
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	completePreProcessing($form) {
		console.log('Form.completePreProcessing');
		this._$form = $form;
		this._listener.trigger('completePreProcessing');
	};


	/**
	 * バリデーションチェック
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	validate($form) {
		console.log('Form.validate');
		this._$form = $form;
		this._listener.trigger('validate');
		setTimeout(() => {
			this._listener.trigger('completeValidate');
		}, 500);
	};


	/**
	 * バリデーションチェック
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	validateModal($form) {
		console.log('Form.validate');
		this._$form = $form;
		this._listener.trigger('validate');
	};


	/**
	 * 後処理終了
	 *
	 * @memberof Form
	 * @param {jQuery} $form - フォームオブジェクト
	 * @return {undefined}
	 */
	completePostProcessing($form) {
		console.log('Form.completePostProcessing');
		this._$form = $form;
		this._listener.trigger('completePostProcessing');
	};


	/**
	 * 画像アップロード
	 *
	 * @memberof Form
	 * @param {blob} file - ファイル
	 * @param {string} type - タイプ 1:ロゴ 2:店舗写真 3:商品写真
	 * @return {undefined}
	 */
	uploadImage(file, type) {
		console.log('Form.uploadImage');
		this._type = type;
		this._model.uploadImage(file, type);
	};


	/**
	 * 画像削除（全体）
	 *
	 * @memberof Form
	 * @param {string} imageKey - イメージID
	 * @return {undefined}
	 */
	deleteImage(imageKey) {
		console.log(`Form.deleteImage imageId:${imageKey}`);
		this._imageKey = imageKey;
		this._model.deleteImage(imageKey);
	};


	/**
	 * オプションクラス設定
	 *
	 * @memberof Form
	 * @return {undefined}
	 */
	setOptionClass() {
		console.log(`Form.setOptionClass`);
		$('.btn-add-option').hide();
		$('.btn-delete-option').hide();
		const formController = this;
		const $newOption = $('.list-option .list-item:last-child');
		$newOption.find('.validate').each((idx, e) => {
			const $e = $(e);
			ApplyClass.apply(FormValidateView, $e, [$e, formController]);
		});
		const $addBtn = $newOption.find('.btn-add-option');
		const $deleteBtn = $newOption.find('.btn-delete-option');
		$addBtn.show();
		$deleteBtn.show();
		ApplyClass.apply(FormBtnAddOptionView, $addBtn, [$addBtn, formController]);
		ApplyClass.apply(FormBtnDeleteOptionView, $deleteBtn, [$deleteBtn, formController]);
	};


	/**
	 * 店舗取得
	 *
	 * @memberof Form
	 * @param {number} companyId - 企業ID
	 * @return {undefined}
	 */
	getShops(companyId) {
		console.log('Form.getShops');
		this._model.getShops(companyId);
	};


	/**
	 * 画像削除（全体）
	 *
	 * @memberof Form
	 * @param {string} imageId - イメージID
	 * @return {undefined}
	 */
	checkOS(shopIds) {
		console.log(`Form.checkOS shopId:${shopIds}`);
		this._model.checkOS(shopIds);
	};


	/**
	 * オプショングループ取得
	 *
	 * @memberof Form
	 * @param {number} companyId - 企業ID
	 * @return {undefined}
	 */
	getOptionGroups(companyId) {
		console.log('Form.getOptionGroups');
		this._model.getOptionGroups(companyId);
	};


	/**
	 * オフィシャルサポーター店舗取得
	 *
	 * @memberof Form
	 * @param {number} companyId - 企業ID
	 * @return {undefined}
	 */
	getSupporterShops(companyId) {
		console.log('Form.getSupporterShops');
		this._model.getSupporterShops(companyId);
	};


	/**
	 * メニュー提供時間帯取得
	 *
	 * @memberof Form
	 * @param {number} shopId - 店舗ID
	 * @return {undefined}
	 */
	getMenuTime(shopId) {
		console.log('Form.getMenuTime');
		this._model.getMenuTime(shopId);
	};


	/**
	 * メニュー取得
	 *
	 * @memberof Form
	 * @param {number} shopId - メニュー提供時間帯ID
	 * @return {undefined}
	 */
	getMenu(shopId, menuId) {
		console.log('Form.getMenu');
		this._model.getMenu(shopId, menuId);
	};


	/**
	 * 全店舗のID / 店舗名 / 企業名 のリストを取得
	 *
	 * @memberof Form
	 * @return {undefined}
	 */
	getSelectDisplayList(idx) {
		console.log('Form.getSelectDisplayList');
		this._model.getSelectDisplayList(idx);
	};


	/**
	 * 初期化
	 *
	 * @memberof Form
	 * @return {undefined}
	 */
	initialize() {
		console.log('Form.initialize');
		this._listener.trigger('initialize');
	};


	/**
	 * listener on
	 *
	 * @memberof Form
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listener off
	 *
	 * @memberof Form
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn);
	};
}

/**
 * @file パスワード変更
 */
'use strict';

/**
 * @classname FormChangePasswordView
 * @classdesc パスワード変更
 */
export default class FormChangePasswordView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormChangePasswordView.constructor');
		this._$e = $e;
		$e.on('click', () => this.onClick());
	};


	/**
	 * パスワード変更
	 *
	 * @memberof FormChangePasswordView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormChangePasswordView.onClick');
		const $block = $('.block-change-password');
		const $password = $('#password');
		if(this._$e.prop('checked')) {
			$block.slideDown();
			$password.removeClass('ignore-validate');
		}else{
			$password.addClass('ignore-validate').val('').removeClass('is-invalid');
			$block.find('.error-tip').removeClass('show').find('.error-tip-inner').text();
			$block.slideUp();
		}
	};
}

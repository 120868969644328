/**
 * @file 3桁区切り
 */
'use strict';
import Common from '../../util/_common';

/**
 * @classname FormSeparateCommaView
 * @classdesc 3桁区切り
 */
export default class FormSeparateCommaView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormSeparateCommaView.constructor');
		this._$e = $e;
		$e.on('blur', () => this.onBlur());
	};

	/**
	 * ブラーのコールバック
	 *
	 * @memberof FormAddFormGroupView
	 * @return {undefined}
	 */
	onBlur() {
		console.log('FormSeparateCommaView.onBlur');
		if(!this._$e.val() || this._$e.val() === '') return;
		let value = this._$e.val().replace(/[^0-9]/g,'');
		this._$e.val(Common.separatedByComma(value));
	};
}

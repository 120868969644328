/**
 * @file _form-shop.js
 */
'use strict';

import Common from "../../../util/_common";

/**
 * @classname FormShop
 * @classdesc 店舗登録
 */
export default class FormShop {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor(controller){
		this._controller = controller;
	};


	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormShop
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log('FormShop.preProcessing');
		// バリデーション後に行う処理を記載（開始）


		// バリデーション後に行う処理を記載（終了）
		this._controller.completePreProcessing($form);
	};


	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormShop
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log('FormShop.postProcessing');
		// バリデーション後に行う処理を記載（開始）

		// 郵便番号の統合
		const zipcode1 = $form.find('#zip_code1').val();
		const zipcode2 = $form.find('#zip_code2').val();
		$('#zip_code').val(`${zipcode1}-${zipcode2}`);

		// 営業時間
		const count = parseInt($('[data-business-hour-count]').attr('data-business-hour-count'));
		if(count === 1){
			this.setBusinessHour1();
			$('#opening_time2').val('');
			$('#closing_time2').val('');
			$('#last_order_time2').val('');
			$('#opening_time3').val('');
			$('#closing_time3').val('');
			$('#last_order_time3').val('');
		}else if(count === 2){
			this.setBusinessHour1();
			this.setBusinessHour2();
			$('#opening_time3').val('');
			$('#closing_time3').val('');
			$('#last_order_time3').val('');
		}else if(count === 3){
			this.setBusinessHour1();
			this.setBusinessHour2();
			this.setBusinessHour3();
		}

		let openingTimes = [];
		let closingTimes = [];
		let lastOrderTimes = [];

		let openingTime = '25:00';
		$('.opening_time').each((idx, e) => {
			let value = $(e).val();
			if(value !== '' && value > '24:00') value = '24:00';
			openingTimes.push(value);
			if(value !== '' && value < openingTime) openingTime = value;
			console.log(`opening time: ${openingTime}`);
		});
		if(openingTime === '24:00') openingTime = '23:59';
		$('#opening_time').val(openingTime);
		let closingTime = '00:00';

		$('.closing_time').each((idx, e) => {
			let value = $(e).val();
			if(value !== '' && value > '24:00') value = '24:00';
			closingTimes.push(value);
			if(value !== '' && value > closingTime) closingTime = value;
			console.log(`closing time: ${closingTime}`);
		});
		if(closingTime === '24:00') closingTime = '23:59';
		$('#closing_time').val(closingTime);
		let lastOrderTime = '00:00';

		$('.last_order_time').each((idx, e) => {
			let value = $(e).val();
			if(value !== '' && value > '24:00') value = '24:00';
			lastOrderTimes.push(value);
			if(value !== '' && value > lastOrderTime) lastOrderTime = value;
			console.log(`last order time: ${lastOrderTime}`);
		});
		if(lastOrderTime === '24:00') lastOrderTime = '23:59';
		$('#last_order_time').val(lastOrderTime);

		let post = true;
		for(let i = 0; i < 3; i++){
			if(openingTimes[i] !== ''){
				if(openingTimes[i] > closingTimes[i]) post = false;
				if(lastOrderTimes[i] > closingTimes[i]) post = false;
			}
		}

		if(post){
			let businessHour = '';
			for(let i = 0; i < openingTimes.length; i++){
				if(i !== 0) businessHour += "\n";
				if(openingTimes[i] !== '') businessHour += `${openingTimes[i]}〜${closingTimes[i]}（L.O.${lastOrderTimes[i]}）`;
			}
			$('#business_hour').val(businessHour);

			// 公開開始日時
			const dispStartDate = $('#disp_start_date').val();
			$('#disp_start_date').val(`${dispStartDate}:00`);

			// 公開終了日時
			const dispEndDate = $('#disp_end_date').val();
			if(dispEndDate && dispEndDate !== '') $('#disp_end_date').val(`${dispEndDate}:00`);

			// バリデーション後に行う処理を記載（終了）
			this._controller.completePostProcessing($form);
		}else{
			this._controller.initialize();
			Common.showErrorModal('時間が不正です。<br>開店時間 → 閉店時間の順にしてください。');
			return;
		}
	};


	/**
	 * 営業時間1設定
	 *
	 * @memberof FormShop
	 * @return {undefined}
	 */
	setBusinessHour1(){
		console.log('FormShop.setBusinessHour1');
		$('#opening_time1').val(`${$('#opening_time11').val()}:${$('#opening_time12').val()}`);
		$('#closing_time1').val(`${$('#closing_time11').val()}:${$('#closing_time12').val()}`);
		$('#last_order_time1').val(`${$('#last_order_time11').val()}:${$('#last_order_time12').val()}`);
	};


	/**
	 * 営業時間2設定
	 *
	 * @memberof FormShop
	 * @return {undefined}
	 */
	setBusinessHour2(){
		console.log('FormShop.setBusinessHour2');
		$('#opening_time2').val(`${$('#opening_time21').val()}:${$('#opening_time22').val()}`);
		$('#closing_time2').val(`${$('#closing_time21').val()}:${$('#closing_time22').val()}`);
		$('#last_order_time2').val(`${$('#last_order_time21').val()}:${$('#last_order_time22').val()}`);
	};


	/**
	 * 営業時間3設定
	 *
	 * @memberof FormShop
	 * @return {undefined}
	 */
	setBusinessHour3(){
		console.log('FormShop.setBusinessHour3');
		$('#opening_time3').val(`${$('#opening_time31').val()}:${$('#opening_time32').val()}`);
		$('#closing_time3').val(`${$('#closing_time31').val()}:${$('#closing_time32').val()}`);
		$('#last_order_time3').val(`${$('#last_order_time31').val()}:${$('#last_order_time32').val()}`);
	};
}

/**
 * @file 商品コピー
 *
 */
'use strict';

/**
 * @classname TableCopyItemView
 * @classdesc 商品コピー
 */
export default class TableCopyItemView {
	/**
	 * @constructor
	 * @param {object} controller - controllerオブジェクト
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('TableCopyItemView.constructor');
		this._$e = $e;
		this._itemId = $e.attr('data-item-id');
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof TableCopyItemView
	 * @return {undefined}
	 */
	onClick() {
		console.log('TableCopyItemView.onClick');
		$('#item_id').val(this._itemId);
	};
}

/**
 * @file 期間種別変更
 */
'use strict';


export default class FormChangeOptionPeriodTypeView {
    /**
     * @constructor
     * @param {jQuery} $e - 該当する jQuery オブジェクト
     * @param {object} controller - controllerオブジェクト
     */
    constructor($e, controller) {
        console.log('FormChangeOptionPeriodTypeView.constructor');
        this._$e = $e;
        this._controller = controller;
        this._type0wrap = $('#option-period-type-1-wrap');
        this._type1wrap = $('#option-period-type-2-wrap');
        this._$e.on('click', e => this.onClick(e));
    };


    /**
     * radioボタンをクリックした際の表示切替
     *
     * @memberof FormChangeOptionPeriodTypeView
     * @return {undefined}
     */
    setDisplayOption(idx) {
        console.log('FormChangeOptionPeriodTypeView.setDisplayOption');
        switch (idx) {
            case '1':
                this.inactivateInputField([this._type1wrap]);
                this._type0wrap.slideDown().find('.validate').removeClass('ignore-validate');
                break;
            case '2':
                this.inactivateInputField([this._type0wrap]);
                this._type1wrap.slideDown().find('.validate').removeClass('ignore-validate');
                break;
            case '3':
                this.inactivateInputField([this._type0wrap, this._type1wrap]);
                break;
            default:
                console.log('FormChangeOptionPeriodTypeView.setDisplayOption.Error');
        }
    }


    /**
     * radioボタンをクリックした際の表示切替
     *
     * @memberof FormChangeOptionPeriodTypeView
     * @return {undefined}
     */
    onClick(e) {
        console.log('FormChangeOptionPeriodTypeView.onClick');
        this.setDisplayOption(e.target.getAttribute('value'));
    }


    /**
     * 入力欄の非アクティブ化
     *
     * @memberof FormChangeOptionCampaignTypeView
     * @return {undefined}
     */
    inactivateInputField(targets) {
        console.log('FormChangeOptionCampaignTypeView.inactivateInputField');
        for (let i = 0; i < targets.length; i++) {
            $(targets[i]).slideUp();
            $(targets[i]).find('.validate').removeClass('is-invalid').addClass('ignore-validate').val('');
            $(targets[i]).find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
        }
    }
}
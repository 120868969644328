/**
 * @file オプション削除
 */
'use strict';

/**
 * @classname FormBtnDeleteOptionView
 * @classdesc オプション削除
 */
export default class FormBtnDeleteOptionView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - コントローラー
	 */
	constructor($e, controller) {
		console.log('FormBtnDeleteOptionView.constructor');
		this._$e = $e;
		this._controller = controller;
		this._$option = $e.closest('.list-item');
		$e.on('click', () => this.onClick());
	};


	/**
	 * オプション削除
	 *
	 * @memberof FormBtnDeleteOptionView
	 * @param {event) e - イベント
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormBtnDeleteOptionView.onClick');
		this._$option.remove();
		const $lastOption = $('.list-option .list-item:last-child');
		$lastOption.find('.btn').show();
		$lastOption.find('.validate').removeClass('ignore-validate').removeAttr('readonly');
		$lastOption.find('select option').removeAttr('disabled');
		const options = $('.option-group-base select').html();
		const $optionGroupName = $lastOption.find('.option_group_name');
		const value = $optionGroupName.attr('data-value');
		$optionGroupName.removeAttr('data-value');
		$optionGroupName.html(options).val(value);
	};
}

/**
 * @file Google mapで確認
 */
'use strict';
import Common from '../../util/_common';

/**
 * @classname FormCheckGoogleMapView
 * @classdesc Google mapで確認
 */
export default class FormCheckGoogleMapView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormCheckGoogleMapView.constructor');
		this._$e = $e;
		$e.on('click', () => this.onClick());
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof FormAddFormGroupView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormCheckGoogleMapView.onClick');
		const prefecture = $('#prefecture_id option:selected').text();
		const address1 = $('#address1').val();
		console.log(`${prefecture}${address1}`);
		if(!prefecture || !address1){
			Common.showErrorModal('都道府県、市区町村、番地の入力が必要です');
		}else{
			const adr = prefecture + address1;
			const url = `https://www.google.com/maps/search/${adr}`;
			window.open(url);
		}
	};
}

/**
 * @file クーポンタイプ変更
 */
'use strict';


export default class FormChangeOptionCouponTypeView {
    /**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log('FormChangeOptionCouponTypeView.constructor');
		this._$e = $e;
        this._controller = controller;
        this._type1wrap = $('#option-coupon-type-1-wrap');
        this._type2wrap = $('#option-coupon-type-2-wrap');
        this._$e.on('click', e => this.onClick(e));
	};


	/**
	 * radioボタンをクリックした際の表示切替
	 *
	 * @memberof FormChangeOptionCouponTypeView
	 * @return {undefined}
	 */
    setDisplayOption(idx){
        console.log('FormChangeOptionCouponTypeView.setDisplayOption');
        switch(idx){
            case '1':
                this.inactivateInputField(this._type2wrap);
                this._type1wrap.slideDown().find('.validate').removeClass('ignore-validate');
                break;
            case '2':
                this.inactivateInputField(this._type1wrap);
                this._type2wrap.slideDown().find('.validate').removeClass('ignore-validate');
                break;
            default:
                console.log('FormChangeOptionCouponTypeView.setDisplayOption.Error');
        }
    }

    
	/**
	 * radioボタンをクリックした際の表示切替
	 *
	 * @memberof FormChangeOptionCouponTypeView
	 * @return {undefined}
	 */
    onClick(e){
        console.log('FormChangeOptionCouponTypeView.onClick');
        this.setDisplayOption(e.target.getAttribute('value'));
    }


	/**
	 * 入力欄の非アクティブ化
	 *
	 * @memberof FormChangeOptionCampaignTypeView
	 * @return {undefined}
	 */
    inactivateInputField(target){
        console.log('FormChangeOptionCampaignTypeView.inactivateInputField');
		target.slideUp();
		target.find('.validate').removeClass('is-invalid').addClass('ignore-validate').val('');
		target.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
    }
}
/**
 * @file 個別ID（individual_id）を削除する
 */
'use strict';

/**
 * @classname BtnDeleteIndividualIdView
 * @classdesc 個別ID（individual_id）を削除する
 */
export default class BtnDeleteIndividualIdView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('BtnDeleteIndividualIdView.constructor');
		this._$e = $e;
		this._controller = controller;
		this._id = this._$e.attr('data-individual-id');
		this._$form = $('#form-delete-individual-id');
		this._$deleteBtn = $('.btn-delete-individual-id');
		this._$modal = $('.modal-delete-individual-id');
		this._controller.on('deleteIndividualIdComplete', () => this.onDeleteIndividualIdComplete());
		this._$deleteBtn.on('click', () => this.deleteIndividualId());
		this._$e.on('click', () => this.onClick());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof BtnDeleteIndividualIdView
	 * @return {undefined}
	 */
	onClick() {
		console.log('BtnDeleteIndividualIdView.onClick');
		console.log(`data-individual-id: ${this._id}`);
		$('#individual_id').val(this._id);
	};


	/**
	 * 個別ID削除
	 *
	 * @memberof BtnDeleteIndividualIdView
	 * @return {undefined}
	 */
	deleteIndividualId() {
		if (this._id !== $('#individual_id').val()) return;
		console.log('BtnDeleteIndividualIdView.deleteIndividualId');
		this._controller.deleteIndividualId(this._id);
	};


	/**
	 * 個別ID削除完了後
	 *
	 * @memberof BtnDeleteIndividualIdView
	 * @return {undefined}
	 */
	onDeleteIndividualIdComplete() {
		if (this._id !== this._controller._processingDeleteIndividualId) return;
		console.log('BtnDeleteIndividualIdView.onDeleteIndividualIdComplete');
		this._$e.closest('tr').remove();
		this._$modal.modal('hide');
		$('#individual_id').val('');
	};
}

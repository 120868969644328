/**
 * @file GetMenuView
 *
 */
'use strict';
import Common from '../../util/_common';
import ApplyClass from '../../util/_apply-class';
import FormValidateCheckboxView from './_form-validate-checkbox-view';

/**
 * @classname GetMenuView
 * @classdesc GetMenuView
 */
export default class GetMenuView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log('GetMenuView.constructor');
		this._$e = $e;
		this._controller = controller;
		$e.on('change', () => this.onChange());
		controller.on('getMenuComplete', () => this.setMenu());
	};


	/**
	 * 変更のコールバック
	 *
	 * @memberof GetMenuView
	 * @return {undefined}
	 */
	onChange() {
		console.log(`GetMenuView.onChange`);
		const menuId = $('#menu_id').val();
		if(menuId && menuId !== ''){
			this.getMenu();
		}else{
			$('.menu-select-wrap').html('<p class="mt8 text">メニュー提供時間帯を選択してください。</p>');
		}
	};


	/**
	 * メニュー取得
	 *
	 * @memberof GetMenuView
	 * @return {undefined}
	 */
	getMenu() {
		console.log(`GetMenuView.getMenu`);
		const shopId = $('#shop_id').val();
		const menuId = this._$e.val();
		console.log(menuId);
		if(shopId && shopId !== ''){
			this._controller.getMenu(shopId, menuId);
		}else{
			Common.showErrorModal('店舗が選択されていません。');
		}
	};


	/**
	 * メニュー設定
	 *
	 * @memberof GetMenuView
	 * @return {undefined}
	 */
	setMenu() {
		console.log(`GetMenuView.setMenu`);
		const menu = this._controller._menu.menu;
		let menuHtml = '';
		for(let i = 0; i < menu.length; i++){
			if(menu[i].option_groups.length > 0){
				let options = '';
				for(let j = 0; j < menu[i].option_groups.length; j++){
					if(j !== 0) options += "\n\n";
					options += "【" + menu[i].option_groups[j].option_group_name + "】\n";
					for(let k = 0; k < menu[i].option_groups[j].options.length; k++) {
						if(k !== 0) {
							options += '／';
						}
						options += menu[i].option_groups[j].options[k].option_name;
					}
				}
				menuHtml += `
<div class="custom-control custom-checkbox-btn custom-control-inline mb5">
	<input class="custom-control-input input-checkbox-btn item_id" id="menu-${i}" value="${menu[i].item_id}" type="checkbox">
	<label class="custom-control-label pr60" for="menu-${i}">${menu[i].item_name}（${menu[i].item_type_name}）</label>
	<button class="btn btn-option" data-option="${options}">OPTION</button>
</div>`;
			}else{
				menuHtml += `
<div class="custom-control custom-checkbox-btn custom-control-inline mb5">
	<input class="custom-control-input input-checkbox-btn item_id" id="menu-${i}" value="${menu[i].item_id}" type="checkbox">
	<label class="custom-control-label" for="menu-${i}">${menu[i].item_name}（${menu[i].item_type_name}）</label>
</div>`;
			}
		}
		$('.menu-select-wrap').html(menuHtml);
		const FormController = this._controller;
		$('.menu-select-wrap .input-checkbox-btn').each((idx, e) => {
			const $e =  $(e);
			ApplyClass.apply(FormValidateCheckboxView, $e, [$e, FormController]);
		});
		this.setOption();
	};


	/**
	 * メニュー設定
	 *
	 * @memberof GetMenuView
	 * @return {undefined}
	 */
	setOption() {
		console.log(`GetMenuView.setOption`);
		$('.btn-option').each((idx, e) => {
			const $e = $(e);
			const options = $e.attr('data-option');
			$e.on('click', () => {
				alert(options);
			})
		});
	};
}

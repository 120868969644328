/**
 * @file 対象変更
 */
'use strict';


export default class FormChangeOptionTargetTypeView {
    /**
     * @constructor
     * @param {jQuery} $e - 該当する jQuery オブジェクト
     * @param {object} controller - controllerオブジェクト
     */
    constructor($e, controller) {
        console.log('FormChangeOptionTargetTypeView.constructor');
        this._$e = $e;
        this._controller = controller;
        this._type2wrap = $('#target-type-2-wrap');
        this._type3wrap = $('#target-type-3-wrap');
        this._displayWrap = $('#target-type-checked');
        this._$e.on('click', e => this.onClick(e));
    };


    /**
     * radioボタンをクリックした際の表示切替
     *
     * @memberof FormChangeOptionTargetTypeView
     * @return {undefined}
     */
    setDisplayOption(idx) {
        console.log('FormChangeOptionTargetTypeView.setDisplayOption');
        switch (idx) {
            case '1':
                $('#checked-list').html('');
                this._type2wrap.slideUp();
                this._type3wrap.slideUp();
                this._displayWrap.slideUp();
                this._displayWrap.find('.validate').removeClass('is-invalid').addClass('ignore-validate');
                this._displayWrap.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
                break;
            case '2':
                $('#checked-list').html('');
                this._type3wrap.slideUp();
                this._type2wrap.slideDown();
                this._displayWrap.find('.validate').removeClass('is-invalid, ignore-validate');
                this._displayWrap.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
                this._displayWrap.find('input').val('');
                this._displayWrap.slideDown();
                this._controller._embedWord = '店舗';
                this._controller._isOpenModalIdx = 1;
                break;
            case '3':
                $('#checked-list').html('');
                this._type2wrap.slideUp();
                this._type3wrap.slideDown();
                this._displayWrap.find('.validate').removeClass('is-invalid, ignore-validate');
                this._displayWrap.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
                this._displayWrap.find('input').val('');
                this._displayWrap.slideDown();
                this._controller._embedWord = '企業';
                this._controller._isOpenModalIdx = 2;
                break;
            // case '3':
            //     break;
            default:
                console.log('FormChangeOptionTargetTypeView.setDisplayOption.Error');
        }
    }


    /**
     * radioボタンをクリックした際の表示切替
     *
     * @memberof FormChangeOptionTargetTypeView
     * @return {undefined}
     */
    onClick(e) {
        console.log('FormChangeOptionTargetTypeView.onClick');
        this.setDisplayOption(e.target.getAttribute('value'));
        console.log(e.target.getAttribute('value'))
    }
}
/**
 * @file テーブルソート
 *
 */
'use strict';

/**
 * @classname TablePaginationView
 * @classdesc テーブルソート
 */
export default class TablePaginationView {
	/**
	 * @constructor
	 * @param {object} controller - controllerオブジェクト
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('TablePaginationView.constructor');
		this._$e = $e;
		this._controller = controller;
		this._page = parseInt($e.attr('data-page'));
		$e.on('click', () => this.onClick());
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof TablePaginationView
	 * @return {undefined}
	 */
	onClick() {
		console.log('TablePaginationView.onChange');
		this._controller.pagination(this._page);
	};
}

/**
 * @file _template.js
 */
'use strict';
import Common from '../util/_common';

/**
 * @classname Template
 */

export default class Template {


	/**
	 * オプション
	 *
	 * @memberof Template
	 * @return {string} - テンプレート
	 */
	static getOption() {
		const option = `
<li class="list-item">
	<div class="form-group validateGroup row"><label class="col-sm-3 col-form-label" for="option_group_name">オプショングループ名</label>
		<div class="col-sm-3">
			<div class="error-tip">
				<div class="error-tip-inner"></div>
			</div>
			<select class="form-control validate option_group_name" id="option_group_name" name="option_group_name[]">
			</select>
		</div>
	</div>
	<div class="form-group validateGroup row"><label class="col-sm-3 col-form-label">オプション名<span class="badge badge-danger ml-2">必須</span></label>
		<div class="col-sm-6">
			<div class="error-tip">
				<div class="error-tip-inner"></div>
			</div><input class="form-control validate option_name mr10" type="text" name="option_name[]" data-validate="empty">
		</div>
	</div>
	<div class="form-group validateGroup row"><label class="col-sm-3 col-form-label">オプション価格（税抜）<span class="badge badge-danger ml-2">必須</span></label>
		<div class="col-sm-3">
			<div class="error-tip">
				<div class="error-tip-inner"></div>
			</div>
			<div class="d-flex align-items-center"><input class="form-control validate option_price w80 mr10" type="tel" name="option_price[]" data-validate="empty number nospace nosymbol" maxlength="5">円</div>
		</div>
	</div>
	<div class="form-group validateGroup row"><label class="col-sm-3 col-form-label">消費税率</label>
		<div class="col-sm-3">
			<div class="error-tip">
				<div class="error-tip-inner"></div>
			</div><select class="form-control validate w80 option_tax" name="option_tax[]" data-validate="empty">
				<option value="8">8%</option>
				<option value="10">10%</option>
			</select>
		</div>
	</div>
	<div class="form-group validateGroup row"><label class="col-sm-3 col-form-label">オプション価格（税込）<span class="badge badge-danger ml-2">必須</span></label>
		<div class="col-sm-3">
			<div class="error-tip">
				<div class="error-tip-inner"></div>
			</div>
			<div class="d-flex align-items-center"><input class="form-control validate option_price_tax w80 mr10" type="tel" name="option_price_tax[]" data-validate="empty number nospace nosymbol" maxlength="5">円</div>
		</div>
	</div>
	<button class="btn btn-success btn-lg btn-add-option" type="button"><i class="fas fa-plus"></i></button>
	<button class="btn btn-danger btn-lg btn-delete-option" type="button"><i class="fas fa-minus"></i></button>
</li>`;
		return option;
	};


	/**
	 * オプショングループ
	 *
	 * @memberof Template
	 * @return {string} - テンプレート
	 */
	static getOptionGroup(optionGroups) {
		console.log(optionGroups);
		let og = `
<div class="form-group validateGroup row"><label class="col-sm-3 col-form-label">オプショングループ<span class="badge badge-danger ml-1">必須</span></label>
	<div class="col-sm-8">
		<div class="error-tip">
			<div class="error-tip-inner"></div>
		</div>
		<select class="validate select2 option_group" id="option_group" name="option_group[]" data-validate="empty" multiple>`;
		for(let i = 0; i < optionGroups.length;i++) {
			const optionGroup = optionGroups[i];
			console.log(optionGroup);
			let displayName = '表示';
			if (optionGroup.display_name === 0) displayName = '非表示';
			og += `<option value="${optionGroup.option_group_id}">${optionGroup.option_group_name}（${displayName}：${optionGroup.option_num}オプション）</option>`
		}
		og += `
		</select>
	</div>
</div>`;
		return og;
	};
}
/**
 * @file _form-display.js
 */
'use strict';
import Common from '../../../util/_common';

/**
 * @classname FormDisplay
 * @classdesc 中断時間登録
 */
export default class FormDisplay {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 */
	constructor(controller){
		this._controller = controller;
	};


	/**
	 * フォーム送信前処理
	 *
	 * @memberof FormDisplay
	 * @return {undefined}
	 */
	preProcessing($form) {
		console.log('FormDisplay.preProcessing');
		// バリデーション後に行う処理を記載（開始）


		// バリデーション後に行う処理を記載（終了）
		this._controller.completePreProcessing($form);
	};


	/**
	 * フォーム送信後処理
	 *
	 * @memberof FormDisplay
	 * @return {undefined}
	 */
	postProcessing($form) {
		console.log('FormDisplay.postProcessing');
		// バリデーション後に行う処理を記載（開始）

		let post = true;
		// 中断時間
		// 日曜日
		let sun = [];
		const $sunFrom11 = $('#sunday_display_from11');
		const $sunFrom12 = $('#sunday_display_from12');
		const $sunTo11 = $('#sunday_display_to11');
		const $sunTo12 = $('#sunday_display_to12');
		const $sunFrom1 = $('#sunday_display_from1');
		const $sunTo1 = $('#sunday_display_to1');
		const sunFrom11 = $sunFrom11.val();
		const sunFrom12 = $sunFrom12.val();
		const sunTo11 = $sunTo11.val();
		const sunTo12 = $sunTo12.val();
		let sunFrom1;
		let sunTo1;
		if(sunFrom11 !== '' && sunFrom12 !== '' && sunTo11 !== '' && sunTo12 !== ''){
			sunFrom1 = `${sunFrom11}:${sunFrom12}`;
			sunTo1 = `${sunTo11}:${sunTo12}`;
			if(sunFrom1 >= sunTo1){
				post = false;
				$sunFrom11.val('');
				$sunFrom12.val('');
				$sunTo11.val('');
				$sunTo12.val('');
				$sunFrom1.val('');
				$sunTo1.val('');
			}else{
				$sunFrom1.val(sunFrom1);
				$sunTo1.val(sunTo1);
				const display1 = {
					from: sunFrom1,
					to: sunTo1
				};
				sun.push(display1);
			}
		}else{
			$sunFrom11.val('');
			$sunFrom12.val('');
			$sunTo11.val('');
			$sunTo12.val('');
			$sunFrom1.val('');
			$sunTo1.val('');
		}
		const $sunFrom21 = $('#sunday_display_from21');
		const $sunFrom22 = $('#sunday_display_from22');
		const $sunTo21 = $('#sunday_display_to21');
		const $sunTo22 = $('#sunday_display_to22');
		const $sunFrom2 = $('#sunday_display_from2');
		const $sunTo2 = $('#sunday_display_to2');
		const sunFrom21 = $sunFrom21.val();
		const sunFrom22 = $sunFrom22.val();
		const sunTo21 = $sunTo21.val();
		const sunTo22 = $sunTo22.val();
		let sunFrom2;
		let sunTo2;
		if(sunFrom21 !== '' && sunFrom22 !== '' && sunTo21 !== '' && sunTo22 !== ''){
			sunFrom2 = `${sunFrom21}:${sunFrom22}`;
			sunTo2 = `${sunTo21}:${sunTo22}`;
			if(sunFrom2 >= sunTo2){
				post = false;
				$sunFrom21.val('');
				$sunFrom22.val('');
				$sunTo21.val('');
				$sunTo22.val('');
				$sunFrom2.val('');
				$sunTo2.val('');
			}else{
				$sunFrom2.val(sunFrom2);
				$sunTo2.val(sunTo2);
				const display2 = {
					from: sunFrom2,
					to: sunTo2
				};
				sun.push(display2);
			}
		}else{
			$sunFrom21.val('');
			$sunFrom22.val('');
			$sunTo21.val('');
			$sunTo22.val('');
			$sunFrom2.val('');
			$sunTo2.val('');
		}
		console.log(sun);
		if(sun.length > 1){
			if(sunFrom1 < sunFrom2){
				if(sunTo1 > sunFrom2){
					post = false;
				}
			}else{
				if(sunTo2 > sunFrom1){
					post = false;
				}
			}
		}
		// 月曜日
		let mon = [];
		const $monFrom11 = $('#monday_display_from11');
		const $monFrom12 = $('#monday_display_from12');
		const $monTo11 = $('#monday_display_to11');
		const $monTo12 = $('#monday_display_to12');
		const $monFrom1 = $('#monday_display_from1');
		const $monTo1 = $('#monday_display_to1');
		const monFrom11 = $monFrom11.val();
		const monFrom12 = $monFrom12.val();
		const monTo11 = $monTo11.val();
		const monTo12 = $monTo12.val();
		let monFrom1;
		let monTo1;
		if(monFrom11 !== '' && monFrom12 !== '' && monTo11 !== '' && monTo12 !== ''){
			monFrom1 = `${monFrom11}:${monFrom12}`;
			monTo1 = `${monTo11}:${monTo12}`;
			if(monFrom1 >= monTo1){
				post = false;
				$monFrom11.val('');
				$monFrom12.val('');
				$monTo11.val('');
				$monTo12.val('');
				$monFrom1.val('');
				$monTo1.val('');
			}else{
				$monFrom1.val(monFrom1);
				$monTo1.val(monTo1);
				const display1 = {
					from: monFrom1,
					to: monTo1
				};
				mon.push(display1);
			}
		}else{
			$monFrom11.val('');
			$monFrom12.val('');
			$monTo11.val('');
			$monTo12.val('');
			$monFrom1.val('');
			$monTo1.val('');
		}
		const $monFrom21 = $('#monday_display_from21');
		const $monFrom22 = $('#monday_display_from22');
		const $monTo21 = $('#monday_display_to21');
		const $monTo22 = $('#monday_display_to22');
		const $monFrom2 = $('#monday_display_from2');
		const $monTo2 = $('#monday_display_to2');
		const monFrom21 = $monFrom21.val();
		const monFrom22 = $monFrom22.val();
		const monTo21 = $monTo21.val();
		const monTo22 = $monTo22.val();
		let monFrom2;
		let monTo2;
		if(monFrom21 !== '' && monFrom22 !== '' && monTo21 !== '' && monTo22 !== ''){
			monFrom2 = `${monFrom21}:${monFrom22}`;
			monTo2 = `${monTo21}:${monTo22}`;
			if(monFrom2 >= monTo2){
				post = false;
				$monFrom21.val('');
				$monFrom22.val('');
				$monTo21.val('');
				$monTo22.val('');
				$monFrom2.val('');
				$monTo2.val('');
			}else{
				$monFrom2.val(monFrom2);
				$monTo2.val(monTo2);
				const display2 = {
					from: monFrom2,
					to: monTo2
				};
				mon.push(display2);
			}
		}else{
			$monFrom21.val('');
			$monFrom22.val('');
			$monTo21.val('');
			$monTo22.val('');
			$monFrom2.val('');
			$monTo2.val('');
		}
		console.log(mon);
		if(mon.length > 1){
			if(monFrom1 < monFrom2){
				if(monTo1 > monFrom2){
					post = false;
				}
			}else{
				if(monTo2 > monFrom1){
					post = false;
				}
			}
		}
		// 火曜日
		let tue = [];
		const $tueFrom11 = $('#tuesday_display_from11');
		const $tueFrom12 = $('#tuesday_display_from12');
		const $tueTo11 = $('#tuesday_display_to11');
		const $tueTo12 = $('#tuesday_display_to12');
		const $tueFrom1 = $('#tuesday_display_from1');
		const $tueTo1 = $('#tuesday_display_to1');
		const tueFrom11 = $tueFrom11.val();
		const tueFrom12 = $tueFrom12.val();
		const tueTo11 = $tueTo11.val();
		const tueTo12 = $tueTo12.val();
		let tueFrom1;
		let tueTo1;
		if(tueFrom11 !== '' && tueFrom12 !== '' && tueTo11 !== '' && tueTo12 !== ''){
			tueFrom1 = `${tueFrom11}:${tueFrom12}`;
			tueTo1 = `${tueTo11}:${tueTo12}`;
			if(tueFrom1 >= tueTo1){
				post = false;
				$tueFrom11.val('');
				$tueFrom12.val('');
				$tueTo11.val('');
				$tueTo12.val('');
				$tueFrom1.val('');
				$tueTo1.val('');
			}else{
				$tueFrom1.val(tueFrom1);
				$tueTo1.val(tueTo1);
				const display1 = {
					from: tueFrom1,
					to: tueTo1
				};
				tue.push(display1);
			}
		}else{
			$tueFrom11.val('');
			$tueFrom12.val('');
			$tueTo11.val('');
			$tueTo12.val('');
			$tueFrom1.val('');
			$tueTo1.val('');
		}
		const $tueFrom21 = $('#tuesday_display_from21');
		const $tueFrom22 = $('#tuesday_display_from22');
		const $tueTo21 = $('#tuesday_display_to21');
		const $tueTo22 = $('#tuesday_display_to22');
		const $tueFrom2 = $('#tuesday_display_from2');
		const $tueTo2 = $('#tuesday_display_to2');
		const tueFrom21 = $tueFrom21.val();
		const tueFrom22 = $tueFrom22.val();
		const tueTo21 = $tueTo21.val();
		const tueTo22 = $tueTo22.val();
		let tueFrom2;
		let tueTo2;
		if(tueFrom21 !== '' && tueFrom22 !== '' && tueTo21 !== '' && tueTo22 !== ''){
			tueFrom2 = `${tueFrom21}:${tueFrom22}`;
			tueTo2 = `${tueTo21}:${tueTo22}`;
			if(tueFrom2 >= tueTo2){
				post = false;
				$tueFrom21.val('');
				$tueFrom22.val('');
				$tueTo21.val('');
				$tueTo22.val('');
				$tueFrom2.val('');
				$tueTo2.val('');
			}else{
				$tueFrom2.val(tueFrom2);
				$tueTo2.val(tueTo2);
				const display2 = {
					from: tueFrom2,
					to: tueTo2
				};
				tue.push(display2);
			}
		}else{
			$tueFrom21.val('');
			$tueFrom22.val('');
			$tueTo21.val('');
			$tueTo22.val('');
			$tueFrom2.val('');
			$tueTo2.val('');
		}
		console.log(tue);
		if(tue.length > 1){
			if(tueFrom1 < tueFrom2){
				if(tueTo1 > tueFrom2){
					post = false;
				}
			}else{
				if(tueTo2 > tueFrom1){
					post = false;
				}
			}
		}
		// 水曜日
		let wed = [];
		const $wedFrom11 = $('#wednesday_display_from11');
		const $wedFrom12 = $('#wednesday_display_from12');
		const $wedTo11 = $('#wednesday_display_to11');
		const $wedTo12 = $('#wednesday_display_to12');
		const $wedFrom1 = $('#wednesday_display_from1');
		const $wedTo1 = $('#wednesday_display_to1');
		const wedFrom11 = $wedFrom11.val();
		const wedFrom12 = $wedFrom12.val();
		const wedTo11 = $wedTo11.val();
		const wedTo12 = $wedTo12.val();
		let wedFrom1;
		let wedTo1;
		if(wedFrom11 !== '' && wedFrom12 !== '' && wedTo11 !== '' && wedTo12 !== ''){
			wedFrom1 = `${wedFrom11}:${wedFrom12}`;
			wedTo1 = `${wedTo11}:${wedTo12}`;
			if(wedFrom1 >= wedTo1){
				post = false;
				$wedFrom11.val('');
				$wedFrom12.val('');
				$wedTo11.val('');
				$wedTo12.val('');
				$wedFrom1.val('');
				$wedTo1.val('');
			}else{
				$wedFrom1.val(wedFrom1);
				$wedTo1.val(wedTo1);
				const display1 = {
					from: wedFrom1,
					to: wedTo1
				};
				wed.push(display1);
			}
		}else{
			$wedFrom11.val('');
			$wedFrom12.val('');
			$wedTo11.val('');
			$wedTo12.val('');
			$wedFrom1.val('');
			$wedTo1.val('');
		}
		const $wedFrom21 = $('#wednesday_display_from21');
		const $wedFrom22 = $('#wednesday_display_from22');
		const $wedTo21 = $('#wednesday_display_to21');
		const $wedTo22 = $('#wednesday_display_to22');
		const $wedFrom2 = $('#wednesday_display_from2');
		const $wedTo2 = $('#wednesday_display_to2');
		const wedFrom21 = $wedFrom21.val();
		const wedFrom22 = $wedFrom22.val();
		const wedTo21 = $wedTo21.val();
		const wedTo22 = $wedTo22.val();
		let wedFrom2;
		let wedTo2;
		if(wedFrom21 !== '' && wedFrom22 !== '' && wedTo21 !== '' && wedTo22 !== ''){
			wedFrom2 = `${wedFrom21}:${wedFrom22}`;
			wedTo2 = `${wedTo21}:${wedTo22}`;
			if(wedFrom2 >= wedTo2){
				post = false;
				$wedFrom21.val('');
				$wedFrom22.val('');
				$wedTo21.val('');
				$wedTo22.val('');
				$wedFrom2.val('');
				$wedTo2.val('');
			}else{
				$wedFrom2.val(wedFrom2);
				$wedTo2.val(wedTo2);
				const display2 = {
					from: wedFrom2,
					to: wedTo2
				};
				wed.push(display2);
			}
		}else{
			$wedFrom21.val('');
			$wedFrom22.val('');
			$wedTo21.val('');
			$wedTo22.val('');
			$wedFrom2.val('');
			$wedTo2.val('');
		}
		console.log(wed);
		if(wed.length > 1){
			if(wedFrom1 < wedFrom2){
				if(wedTo1 > wedFrom2){
					post = false;
				}
			}else{
				if(wedTo2 > wedFrom1){
					post = false;
				}
			}
		}
		// 木曜日
		let thu = [];
		const $thuFrom11 = $('#thursday_display_from11');
		const $thuFrom12 = $('#thursday_display_from12');
		const $thuTo11 = $('#thursday_display_to11');
		const $thuTo12 = $('#thursday_display_to12');
		const $thuFrom1 = $('#thursday_display_from1');
		const $thuTo1 = $('#thursday_display_to1');
		const thuFrom11 = $thuFrom11.val();
		const thuFrom12 = $thuFrom12.val();
		const thuTo11 = $thuTo11.val();
		const thuTo12 = $thuTo12.val();
		let thuFrom1;
		let thuTo1;
		if(thuFrom11 !== '' && thuFrom12 !== '' && thuTo11 !== '' && thuTo12 !== ''){
			thuFrom1 = `${thuFrom11}:${thuFrom12}`;
			thuTo1 = `${thuTo11}:${thuTo12}`;
			if(thuFrom1 >= thuTo1){
				post = false;
				$thuFrom11.val('');
				$thuFrom12.val('');
				$thuTo11.val('');
				$thuTo12.val('');
				$thuFrom1.val('');
				$thuTo1.val('');
			}else{
				$thuFrom1.val(thuFrom1);
				$thuTo1.val(thuTo1);
				const display1 = {
					from: thuFrom1,
					to: thuTo1
				};
				thu.push(display1);
			}
		}else{
			$thuFrom11.val('');
			$thuFrom12.val('');
			$thuTo11.val('');
			$thuTo12.val('');
			$thuFrom1.val('');
			$thuTo1.val('');
		}
		const $thuFrom21 = $('#thursday_display_from21');
		const $thuFrom22 = $('#thursday_display_from22');
		const $thuTo21 = $('#thursday_display_to21');
		const $thuTo22 = $('#thursday_display_to22');
		const $thuFrom2 = $('#thursday_display_from2');
		const $thuTo2 = $('#thursday_display_to2');
		const thuFrom21 = $thuFrom21.val();
		const thuFrom22 = $thuFrom22.val();
		const thuTo21 = $thuTo21.val();
		const thuTo22 = $thuTo22.val();
		let thuFrom2;
		let thuTo2;
		if(thuFrom21 !== '' && thuFrom22 !== '' && thuTo21 !== '' && thuTo22 !== ''){
			thuFrom2 = `${thuFrom21}:${thuFrom22}`;
			thuTo2 = `${thuTo21}:${thuTo22}`;
			if(thuFrom2 >= thuTo2){
				post = false;
				$thuFrom21.val('');
				$thuFrom22.val('');
				$thuTo21.val('');
				$thuTo22.val('');
				$thuFrom2.val('');
				$thuTo2.val('');
			}else{
				$thuFrom2.val(thuFrom2);
				$thuTo2.val(thuTo2);
				const display2 = {
					from: thuFrom2,
					to: thuTo2
				};
				thu.push(display2);
			}
		}else{
			$thuFrom21.val('');
			$thuFrom22.val('');
			$thuTo21.val('');
			$thuTo22.val('');
			$thuFrom2.val('');
			$thuTo2.val('');
		}
		console.log(thu);
		if(thu.length > 1){
			if(thuFrom1 < thuFrom2){
				if(thuTo1 > thuFrom2){
					post = false;
				}
			}else{
				if(thuTo2 > thuFrom1){
					post = false;
				}
			}
		}
		// 金曜日
		let fri = [];
		const $friFrom11 = $('#friday_display_from11');
		const $friFrom12 = $('#friday_display_from12');
		const $friTo11 = $('#friday_display_to11');
		const $friTo12 = $('#friday_display_to12');
		const $friFrom1 = $('#friday_display_from1');
		const $friTo1 = $('#friday_display_to1');
		const friFrom11 = $friFrom11.val();
		const friFrom12 = $friFrom12.val();
		const friTo11 = $friTo11.val();
		const friTo12 = $friTo12.val();
		let friFrom1;
		let friTo1;
		if(friFrom11 !== '' && friFrom12 !== '' && friTo11 !== '' && friTo12 !== ''){
			friFrom1 = `${friFrom11}:${friFrom12}`;
			friTo1 = `${friTo11}:${friTo12}`;
			if(friFrom1 >= friTo1){
				post = false;
				$friFrom11.val('');
				$friFrom12.val('');
				$friTo11.val('');
				$friTo12.val('');
				$friFrom1.val('');
				$friTo1.val('');
			}else{
				$friFrom1.val(friFrom1);
				$friTo1.val(friTo1);
				const display1 = {
					from: friFrom1,
					to: friTo1
				};
				fri.push(display1);
			}
		}else{
			$friFrom11.val('');
			$friFrom12.val('');
			$friTo11.val('');
			$friTo12.val('');
			$friFrom1.val('');
			$friTo1.val('');
		}
		const $friFrom21 = $('#friday_display_from21');
		const $friFrom22 = $('#friday_display_from22');
		const $friTo21 = $('#friday_display_to21');
		const $friTo22 = $('#friday_display_to22');
		const $friFrom2 = $('#friday_display_from2');
		const $friTo2 = $('#friday_display_to2');
		const friFrom21 = $friFrom21.val();
		const friFrom22 = $friFrom22.val();
		const friTo21 = $friTo21.val();
		const friTo22 = $friTo22.val();
		let friFrom2;
		let friTo2;
		if(friFrom21 !== '' && friFrom22 !== '' && friTo21 !== '' && friTo22 !== ''){
			friFrom2 = `${friFrom21}:${friFrom22}`;
			friTo2 = `${friTo21}:${friTo22}`;
			if(friFrom2 >= friTo2){
				post = false;
				$friFrom21.val('');
				$friFrom22.val('');
				$friTo21.val('');
				$friTo22.val('');
				$friFrom2.val('');
				$friTo2.val('');
			}else{
				$friFrom2.val(friFrom2);
				$friTo2.val(friTo2);
				const display2 = {
					from: friFrom2,
					to: friTo2
				};
				fri.push(display2);
			}
		}else{
			$friFrom21.val('');
			$friFrom22.val('');
			$friTo21.val('');
			$friTo22.val('');
			$friFrom2.val('');
			$friTo2.val('');
		}
		console.log(fri);
		if(fri.length > 1){
			if(friFrom1 < friFrom2){
				if(friTo1 > friFrom2){
					post = false;
				}
			}else{
				if(friTo2 > friFrom1){
					post = false;
				}
			}
		}
		// 土曜日
		let sat = [];
		const $satFrom11 = $('#saturday_display_from11');
		const $satFrom12 = $('#saturday_display_from12');
		const $satTo11 = $('#saturday_display_to11');
		const $satTo12 = $('#saturday_display_to12');
		const $satFrom1 = $('#saturday_display_from1');
		const $satTo1 = $('#saturday_display_to1');
		const satFrom11 = $satFrom11.val();
		const satFrom12 = $satFrom12.val();
		const satTo11 = $satTo11.val();
		const satTo12 = $satTo12.val();
		let satFrom1;
		let satTo1;
		if(satFrom11 !== '' && satFrom12 !== '' && satTo11 !== '' && satTo12 !== ''){
			satFrom1 = `${satFrom11}:${satFrom12}`;
			satTo1 = `${satTo11}:${satTo12}`;
			if(satFrom1 >= satTo1){
				post = false;
				$satFrom11.val('');
				$satFrom12.val('');
				$satTo11.val('');
				$satTo12.val('');
				$satFrom1.val('');
				$satTo1.val('');
			}else{
				$satFrom1.val(satFrom1);
				$satTo1.val(satTo1);
				const display1 = {
					from: satFrom1,
					to: satTo1
				};
				sat.push(display1);
			}
		}else{
			$satFrom11.val('');
			$satFrom12.val('');
			$satTo11.val('');
			$satTo12.val('');
			$satFrom1.val('');
			$satTo1.val('');
		}
		const $satFrom21 = $('#saturday_display_from21');
		const $satFrom22 = $('#saturday_display_from22');
		const $satTo21 = $('#saturday_display_to21');
		const $satTo22 = $('#saturday_display_to22');
		const $satFrom2 = $('#saturday_display_from2');
		const $satTo2 = $('#saturday_display_to2');
		const satFrom21 = $satFrom21.val();
		const satFrom22 = $satFrom22.val();
		const satTo21 = $satTo21.val();
		const satTo22 = $satTo22.val();
		let satFrom2;
		let satTo2;
		if(satFrom21 !== '' && satFrom22 !== '' && satTo21 !== '' && satTo22 !== ''){
			satFrom2 = `${satFrom21}:${satFrom22}`;
			satTo2 = `${satTo21}:${satTo22}`;
			if(satFrom2 >= satTo2){
				post = false;
				$satFrom21.val('');
				$satFrom22.val('');
				$satTo21.val('');
				$satTo22.val('');
				$satFrom2.val('');
				$satTo2.val('');
			}else{
				$satFrom2.val(satFrom2);
				$satTo2.val(satTo2);
				const display2 = {
					from: satFrom2,
					to: satTo2
				};
				sat.push(display2);
			}
		}else{
			$satFrom21.val('');
			$satFrom22.val('');
			$satTo21.val('');
			$satTo22.val('');
			$satFrom2.val('');
			$satTo2.val('');
		}
		console.log(sat);
		if(sat.length > 1){
			if(satFrom1 < satFrom2){
				if(satTo1 > satFrom2){
					post = false;
				}
			}else{
				if(satTo2 > satFrom1){
					post = false;
				}
			}
		}

		if(post){
			// バリデーション後に行う処理を記載（終了）
			this._controller.completePostProcessing($form);
		}else{
			this._controller.initialize();
			Common.showErrorModal('時間が不正です。<br>開始と終了が逆転しているか、時間が重複している可能性があります。');
			return;
		}
	};
}

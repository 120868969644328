'use strict';

// utility class
import ApplyClass from '../util/_apply-class';

// table-control-class
import Table from '../controller/_table';
import TableSearchConditionAccordionView from '../view/table/_table-search-condition-accordion-view';
import TableSearchView from '../view/table/_table-search-view';
import TableSortView from '../view/table/_table-sort-view';
import TablePaginationView from '../view/table/_table-pagination-view';
import TableClearSearchView from '../view/table/_table-clear-search-view';
import TableCountView from '../view/table/_table-count-view';
import TableCsvDlView from '../view/table/_table-csv-dl-view';
import TableScrollbarView from '../view/table/_table-scrollbar-view';
import TableCopyItemView from '../view/table/_table-copy-item-view';
import TableCopyOperatorView from '../view/table/_table-copy-operator-view';
import TableBtnDeleteView from '../view/table/_table-btn-delete-view';
import TableCheckAllView from '../view/table/_table-check-all-view';
import TablePaymentUpdateView from '../view/table/_table-payment-update-view';
import TableCsvDlDetailView from '../view/table/_table-csv-dl-detail-view';

$(() => {
	const TableController = new Table();
	// 検索条件をもっと見る
	$('.anchor-search-condition').each((idx, e)=>{
		const $e = $(e);
		ApplyClass.apply(TableSearchConditionAccordionView, $e, [$e]);
	});
	// 検索
	$('.btn-table-search').each((idx, e)=>{
		const $e = $(e);;
		ApplyClass.apply(TableSearchView, $e, [$e, TableController]);
	});
	// ソート
	$('.table-sort').each((idx, e)=>{
		const $e = $(e);
		ApplyClass.apply(TableSortView, $e, [$e, TableController]);
	});
	// ページネーション
	$('[data-page]').each((idx, e)=>{
		const $e = $(e);
		ApplyClass.apply(TablePaginationView, $e, [$e, TableController]);
	});
	// 検索条件クリア
	$('.anchor-clear-search').each((idx, e)=>{
		const $e = $(e);
		ApplyClass.apply(TableClearSearchView, $e, [$e, TableController]);
	});
	// ページ表示件数
	$('.btn-search-count').each((idx, e)=>{
		const $e = $(e);
		ApplyClass.apply(TableCountView, $e, [$e, TableController]);
	});
	// CSVダウンロード
	$('.btn-csv-dl').each((idx, e)=>{
		const $e = $(e);
		ApplyClass.apply(TableCsvDlView, $e, [$e, TableController]);
	});
	// 上下スクロールバーを連動
	$('.table-wrap .scroll-bar').each((idx, e)=>{
		const $e = $(e);
		ApplyClass.apply(TableScrollbarView, $e, [$e, TableController]);
	});
	// 商品コピー作成
	$('.btn-copy-item').each((idx, e)=>{
		const $e = $(e);
		ApplyClass.apply(TableCopyItemView, $e, [$e, TableController]);
	});
	// オペレーターコピー作成
	$('.btn-operator-shop').each((idx, e)=>{
		const $e = $(e);
		ApplyClass.apply(TableCopyOperatorView, $e, [$e, TableController]);
	});
	// 削除ボタン
	$('.btn-delete').each((idx, e)=>{
		const $e = $(e);
		ApplyClass.apply(TableBtnDeleteView, $e, [$e, TableController]);
	});
	// 全てチェックする
	$('.input-checkbox.check-all').each((idx, e)=>{
		const $e = $(e);
		ApplyClass.apply(TableCheckAllView, $e, [$e, TableController]);
	});
	// 支払済にする
	$('.btn-payment-update').each((idx, e)=>{
		const $e = $(e);
		ApplyClass.apply(TablePaymentUpdateView, $e, [$e, TableController]);
	});
	// 商品詳細、CSVダウンロード
	$('.btn-detail-csv').each((idx, e)=>{
		const $e = $(e);
		ApplyClass.apply(TableCsvDlDetailView, $e, [$e, TableController]);
	});
});
/**
 * @file ユーザー表示額計算
 */
'use strict';

/**
 * @classname FormUserPriceView
 * @classdesc ユーザー表示額計算
 */
export default class FormUserPriceView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormUserPriceView.constructor');
		this._$e = $e;
		this._value = $e.val();
		this.calculateUserPrice();
		$('#item_price_tax').on('blur', () => this.calculateUserPrice());
		$('.option_price_tax').on('blur', () => this.calculateUserPrice());
	};


	/**
	 * オプション有無変更変更
	 *
	 * @memberof FormUserPriceView
	 * @return {undefined}
	 */
	calculateUserPrice() {
		console.log('FormUserPriceView.calculateUserPrice');
		let price, userPrice, $output;
		if($('#item_price_tax')[0]) {
			price = $('#item_price_tax').val();
			$output = $('#user_disp_price');
		}else if($('.option_price_tax')[0]) {
			price = $('.option_price_tax').val();
			$output = $('#user_disp_option_price');
		}
		console.log(`price is ${price}`);
		if(price && price !== '' && price.match(/^[0-9]+$/)){
			console.log('this is number');
			if(this._value === '1') {
				userPrice = `${Math.floor(price / 9 * 10).toLocaleString()} 円`;
			}else{
				userPrice = `${price.toLocaleString()} 円`;
			}
			console.log(userPrice);
		}else{
			console.log('not number');
			userPrice = '';
		}
		$output.text(userPrice);
	};
}

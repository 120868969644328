/**
 * @file 表示時間追加
 */
'use strict';

/**
 * @classname FormBtnAddDisplayView
 * @classdesc 表示時間追加
 */
export default class FormBtnAddDisplayView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormBtnAddDisplayView.constructor');
		this._$e = $e;
		this._$display = $e.closest('.list-item');
		this.init();
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックイベントのコールバック
	 *
	 * @memberof FormBtnAddDisplayView
	 * @return {undefined}
	 */
	onClick() {
		console.log('FormBtnAddDisplayView.onClick');
		this._$display.find('.second').show().find('.form-control').removeClass('ignore-validate');
 	};


	/**
	 * 曜日確認
	 *
	 * @memberof FormBtnAddDisplayView
	 * @return {undefined}
	 */
	init() {
		console.log('FormBtnAddDisplayView.init');
		if(this._$display.find('.display_from').val() !== ''){
			this._$display.find('.second').show().find('.form-control').removeClass('ignore-validate');
		}
	};
}

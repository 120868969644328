/**
 * @file 勢計算
 */
'use strict';

/**
 * @classname FormCalculateTaxView
 * @classdesc 勢計算
 */
export default class FormCalculateTaxView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormCalculateTaxView.constructor');
		this._$e = $e;
		this._$itemPrice = $('#item_price');
		$e.on('blur', () => this.onBlur());
	};


	/**
	 * blurのコールバック
	 *
	 * @memberof FormDiffModalView
	 * @return {undefined}
	 */
	onBlur() {
		console.log('FormCalculateTaxView.onBlur');
		const taxRate = $('#tax').val() / 100 + 1;
		const itemPriceTax = this._$e.val();
		if(itemPriceTax.match(/^[0-9]+(\.[0-9]+)?$/)){
			const itemPrice = Math.ceil(parseInt(itemPriceTax) / taxRate);
			this._$itemPrice.val(itemPrice);
		}
	};
}

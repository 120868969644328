/**
 * @file オペレーターコピー
 *
 */
'use strict';

/**
 * @classname TableCopyOperatorView
 * @classdesc オペレーターコピー
 */
export default class TableCopyOperatorView {
	/**
	 * @constructor
	 * @param {object} controller - controllerオブジェクト
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('TableCopyOperatorView.constructor');
		this._$e = $e;
		this._operatorId = $e.attr('data-operator-id');
		$e.on('click', () => this.onClick());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof TableCopyOperatorView
	 * @return {undefined}
	 */
	onClick() {
		console.log('TableCopyOperatorView.onClick');
		$('#operator_id').val(this._operatorId);
	};
}

/**
 * @file GetShopsView
 *
 */
'use strict';
import ApplyClass from '../../util/_apply-class';
import FormSelect2View from './_form-select2-view';
import FormValidateView from './_form-validate-view';
import FormGetSupporterShopsMultipleView from './_form-get-supporter-shops-multiple-view';


/**
 * @classname GetShopsView
 * @classdesc GetShopsView
 */
export default class GetShopsView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - controllerオブジェクト
	 */
	constructor($e, controller) {
		console.log('GetShopsView.constructor');
		this._$e = $e;
		this._controller = controller;
		this.checkShop();
		$e.on('change', () => this.onChange());
		controller.on('getShopsComplete', () => this.setShops());
		if($('.check-os')){
			const FormController = this._controller;
			ApplyClass.apply(FormGetSupporterShopsMultipleView, $('.check-os'), [$('.check-os'), FormController]);
		}

	};


	/**
	 * 店舗選択部分がsingleかmultipleか確認
	 *
	 * @memberof GetShopsView
	 * @return {undefined}
	 */
	checkShop(){
		console.log('GetShopsView.checkShop');
		$('#company_id').attr('data-shop-select') == 'multiple'
			? this._multiple = true
			: this._multiple = false
		console.log(this._multiple)
	}


	/**
	 * 変更のコールバック
	 *
	 * @memberof GetShopsView
	 * @return {undefined}
	 */
	onChange() {
		console.log(`GetShopsView.onChange`);
		if($('#staff_level')[0]){
			const staffLevel = parseInt($('#staff_level').val());
			if(staffLevel === 2 || staffLevel === 3){
				if(this._$e.val() !== ''){
					this.getShops();
				}else{
					this.clearShop();
				}
			}else{
				this.clearShop();
			}
		}else{
			this.getShops();
			this.closeOSBlock();
		}
	};


	/**
	 * 店舗取得
	 *
	 * @memberof GetShopsView
	 * @return {undefined}
	 */
	getShops() {
		console.log(`GetShopsView.getShops`);
		const companyId = this._$e.val();
		this._controller.getShops(companyId);
	};


	/**
	 * 店舗設定
	 *
	 * @memberof GetShopsView
	 * @param {number} companyId - 企業ID
	 * @return {undefined}
	 */
	setShops() {
		console.log(`GetShopsView.setShop`);
		const shops = this._controller._shops.shops;
		let options = '<option value="">選択してください</option>';
		for(let i = 0; i < shops.length; i++){
			options += `<option value="${shops[i].shop_id}">${shops[i].shop_name}</option>`
		}

		let $select = this._multiple
			? '<select class="form-control validate select2 shop_id check-os" name="shop_id[]" id="shop_id" data-validate="empty" multiple></select>'
			: '<select class="form-control validate select2 shop_id check-os" name="shop_id" id="shop_id" data-validate="empty"></select>'
		$('.shop-select-wrap').html($select);
		const $shopId = $('#shop_id') || this._$e.closest('.list-item').find('.shop_id');
		$shopId.html(options);
		const FormController = this._controller;
		ApplyClass.apply(FormValidateView, $shopId, [$shopId, FormController]);
		ApplyClass.apply(FormSelect2View, $shopId, [$shopId, FormController]);
		ApplyClass.apply(FormGetSupporterShopsMultipleView, $('.check-os'), [$('.check-os'), FormController]);
	};


	/**
	 * 店舗消去
	 *
	 * @memberof GetShopsView
	 * @return {undefined}
	 */
	clearShop() {
		console.log(`GetShopsView.clearShop`);
		$('.shop-select-wrap').html('');
	};


	/**
	 * 対象ブロックを閉じる
	 *
	 * @memberof FormGetSupporterShopsMultipleView
	 * @return {undefined}
	 */
	closeOSBlock() {
		console.log('FormGetSupporterShopsMultipleView.closeBlock');
		const _$block = $('.block-os-item');
		_$block.hide().find('input').prop('checked', false);
		$('.menu-wrap').html('<p class="mt10 fs16 text">店舗を選択してください。</p>');
	};
}

/**
 * @file TOPへ戻る
 *
 */
'use strict';

/**
 * @classname GoTopView
 * @classdesc TOPへ戻る
 */
export default class GoTopView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('GoTopView.constructor');
		this._$e = $e;
		this._height = $(window).height() * .7;
		this._scroll = 0;
		$(window).on('scroll', () => {this.onScroll();});
	};

	/**
	 * スクロールのコールバック
	 *
	 * @memberof GoTopView
	 */
	onScroll() {
		this._scroll = $(window).scrollTop();
		if(this._scroll > this._height){
			this._$e.fadeIn();
		}else{
			this._$e.fadeOut();
		}
	}

}

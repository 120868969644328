/**
 * @file 郵便番号分割
 */
'use strict';

/**
 * @classname FormPostCodeView
 * @classdesc 郵便番号分割
 */
export default class FormPostCodeView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormPostCodeView.constructor');
		if($e.val() && $e.val() !== ''){
			let postCode = $e.val();
			console.log(postCode);
			postCode = postCode.split('-');
			const id = $e.attr('id');
			console.log(`${id} is divided (${postCode[0]}/${postCode[1]})`);
			$(`#${id}1`).val(postCode[0]);
			$(`#${id}2`).val(postCode[1]);
		}
	};
}

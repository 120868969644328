/**
 * @file 営業時間増減
 */
'use strict';
import Template from '../../template/_template';
import Common from '../../util/_common';

/**
 * @classname FormBusinessHourView
 * @classdesc 営業時間増減
 */
export default class FormBusinessHourView {
	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e) {
		console.log('FormBusinessHourView.constructor');
		this._$e = $e;
		this._$formGroup = $e.closest('.form-group');
		$e.on('click', () => this.onClick());
		this.setBusinessHour();
		$('.btn-close-business-hour').on('click', () =>  {
			this.deleteBusinessHour();
		});
	};


	/**
	 * クリック
	 *
	 * @memberof FormBusinessHourView
	 * @return {undefined}
	 */
	onClick(){
		console.log('FormBusinessHourView.onClick');
		let count = parseInt(this._$formGroup.attr('data-business-hour-count'));
		if(count === 3) return;
		count ++;
		this._$formGroup.attr('data-business-hour-count', count);
		this.setBusinessHour();
	};


	/**
	 * 営業時間設定
	 *
	 * @memberof FormBusinessHourView
	 * @return {undefined}
	 */
	setBusinessHour() {
		console.log('FormBusinessHourView.setBusinessHour');
		const $businessHour2 = $('#business-hour2');
		const $businessHour3 = $('#business-hour3');
		const $btn2 = $businessHour2.find('.btn-close-business-hour');
		const $btn3 = $businessHour3.find('.btn-close-business-hour');
		const count = parseInt(this._$formGroup.attr('data-business-hour-count'));
		console.log(count);
		if(count === 1){
			$businessHour2.hide().find('.form-control').removeClass('is-invalid').val('').addClass('ignore-validate');
			$businessHour2.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
			$businessHour3.hide().find('.form-control').removeClass('is-invalid').val('').addClass('ignore-validate');
			$businessHour3.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
			$btn2.hide();
			$btn3.hide();
			this._$e.show();
		}else if(count === 2){
			$businessHour2.show().find('.form-control').removeClass('ignore-validate');
			$businessHour3.hide().find('.form-control').removeClass('is-invalid').val('').addClass('ignore-validate');
			$businessHour3.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
			$btn2.show();
			$btn3.hide();
			this._$e.show();
		}else if(count === 3){
			$businessHour2.show().find('.form-control').removeClass('ignore-validate');
			$businessHour3.show().find('.form-control').removeClass('ignore-validate');
			$btn2.hide();
			$btn3.show();
			this._$e.hide();
		}
	};


	/**
	 * 営業時間設定
	 *
	 * @memberof FormBusinessHourView
	 * @return {undefined}
	 */
	deleteBusinessHour() {
		console.log('FormBusinessHourView.deleteBusinessHour');
		let count = parseInt(this._$formGroup.attr('data-business-hour-count'));
		count --;
		this._$formGroup.attr('data-business-hour-count', count);
		const $businessHour2 = $('#business-hour2');
		const $businessHour3 = $('#business-hour3');
		const $btn2 = $businessHour2.find('.btn-close-business-hour');
		const $btn3 = $businessHour3.find('.btn-close-business-hour');
		if(count === 2) {
			$businessHour2.show().find('.form-control').removeClass('ignore-validate');
			$businessHour3.hide().find('.form-control').removeClass('is-invalid').val('').addClass('ignore-validate');
			$businessHour3.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
			$btn2.show();
			$btn3.hide();
			this._$e.show();
		}else if(count === 1){
			$businessHour2.hide().find('.form-control').removeClass('is-invalid').val('').addClass('ignore-validate');
			$businessHour2.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
			$businessHour3.hide().find('.form-control').removeClass('is-invalid').val('').addClass('ignore-validate');
			$businessHour3.find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
			$btn2.hide();
			$btn3.hide();
			this._$e.show();
		}
	};
}

/**
 * @file バリデーションチェック
 */
'use strict';
// utility class
import Common from '../../util/_common';


/**
 * @classname FormValidateView
 * @classdesc フォームバリデーション
 */
export default class FormValidateView {

	/**
	 * @constructor
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 * @param {object} controller - コントローラー
	 */
	constructor($e, controller) {
		console.log('FormValidateView.constructor');
		this._controller = controller;
		this._$e		 = $e;
		this._validates  = $e.attr('data-validate');
		if (!this._validates || this._validates === '') return;
		this._element			= $e.prop('tagName').toLowerCase();
		this._type				= $e.attr('type') || null;
		this._value				= '';
		this._error				= [];
		this._$form				= this._$e.closest('form');
		this._$formGroup		= this._$e.closest('.form-group');
		this._$errortip			= this._$formGroup.find('.error-tip');
		this._$errortipInner	= this._$errortip.find('.error-tip-inner');
		this._errorMessage	= {
			empty			  : '<p>この項目は必須です。</p>',
			nospace			: '<p>この項目にスペースは利用できません。</p>',
			nonumber		   : '<p>この項目に数字は利用できません。</p>',
			nosymbol		   : '<p>この項目に記号は利用できません。</p>',
			zenkaku			: '<p>この項目は全角で入力してください。</p>',
			kana			   : '<p>この項目はひらがなもしくはカタカナで入力してください。</p>',
			hiragana		   : '<p>この項目は全角かなで入力してください。</p>',
			katakana		   : '<p>この項目は全角カナで入力してください。</p>',
			hankaku			: '<p>この項目は半角で入力してください。</p>',
			number			 : '<p>この項目は半角数字で入力してください。</p>',
			alphabet		   : '<p>この項目は半角アルファベットで入力してください。</p>',
			alnum			  : '<p>この項目は半角アルファベットもしくは半角数字で入力してください。</p>',
			date			   : '<p>日付が不正です。</p>',
			email			  : '<p>メールアドレスが不正です。</p>',
			url				: '<p>URLが不正です。</p>',
			jancode			   : '<p>JANコードが不正です。</p>',
			mixPasswordNumber  : '<p>パスワードには半角数字も使う必要があります。</p>',
			mixPasswordAlphabet: '<p>パスワードには半角英語も使う必要があります。</p>',
			birthDate		  : '<p>誕生日が不正です。</p>',
			retype			 : '<p class="retype">入力内容が一致しません。</p>',
			max1			   : '<p>文字数は',
			max2			   : '文字以下です。</p>',
			min1			   : '<p>文字数は',
			min2			   : '文字以上です。</p>',
			range1			 : '<p>文字数は',
			range2			 : '文字以上',
			range3			 : '文字以下です。</p>',
			len1			   : '<p>文字数は',
			len2			   : '文字です。</p>',
			file			   : '<p>ファイルが選択されていません。</p>',
			checkboxEmpty	  : '<p>この項目は必須です</p>'
		};
		this._controller.on('validate', () => this.onValidate());
		this.setEvent();
		$('[data-validate=checkboxempty]').on('click', e => {
			const $e = $(e.currentTarget);
			if($e.prop('checked')){
				$e.closest('.form-group').find('[data-validate=checkboxempty]').removeClass('is-invalid');
				this.hideError();
			}
		});
	};


	/**
	 * イベントトリガーを設定する
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	setEvent() {
		console.log('FormValidateView.setEvent');
		if (this._element === 'input') {
			if (this._type === 'checkbox' || this._type === 'radio') {
				this._$e.on('click', () => this.doValidate());
			} else if(this._type === 'file') {
				this._$e.on('change', () => this.doValidate());
			} else {
				if(this._$e.attr('data-datepicker') == 'true'){
					setTimeout(() => {
						this._$e.on('blur', () => this.doValidate());
					}, 150);
				} else {
					this._$e.on('blur', () => this.doValidate());
				}
			}
		} else if (this._element === 'select') {
			this._$e.on('change', () => this.doValidate());
		} else if (this._element === 'textarea') {
			setTimeout(() => {
				this._$e.on('blur', () => this.doValidate());
			}, 150);
		}
	};


	/**
	 * 送信ボタンクリック時のバリデーションのコールバック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	onValidate() {
		console.log('FormValidateView.onValidate');
		if(this._$e.hasClass('ignore-validate')) return;
		this.doValidate();
	};


	/**
	 * バリデーション実行
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	doValidate() {
		if(this._controller._$form && this._controller._$form.attr('id') !== this._$form.attr('id')) return;
		if(this._$e.hasClass('ignore-validate')){
			console.log('ignore validate');
		}else{
			if(this._$e.attr('id')){
				console.log('FormValidateView.doValidate: ' + this._$e.attr('id'));
			}else{
				console.log('FormValidateView.doValidate: ' + this._$e.attr('class'));
			}
			const validateArray = this._validates.split(' ');
			this.setValue();
			this._error = [];
			for (let i = 0; i < validateArray.length; i++) {
				this.validate(validateArray[i]);
				if (i === validateArray.length - 1) {
					if (this._$e.attr('data-datepicker') == 'true') {
						setTimeout(() => {
							this._error.length > 0 ? this.showError() : this.hideError()
						}, 300);
					} else {
						this._error.length > 0 ? this.showError() : this.hideError()
					}
				}
			}
		}
	};


	/**
	 * 値を設定する
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	setValue() {
		if (this._element === 'input') {
			if (this._type === 'checkbox') {
				if (this._$e.prop('checked')) {
					this._value = this._$e.val();
				} else {
					this._value = '';
				}
			} else if (this._type === 'radio') {
				const name = this._$e.attr('name');
				this._value = $('[name=' + name + ']:checked').val();
			} else if (this._type === 'file'){
				this._value = this._$e.val();
				console.log(`FILE: ${this._value}`);
			} else {
				this._value = $.trim(this._$e.val());
				this._$e.val(this._value);
			}
		} else if (this._element === 'textarea') {
			this._value = $.trim(this._$e.val());
			this._$e.val(this._value);
		} else {
			this._value = this._$e.val();
		}
	};


	/**
	 * バリデーションを実施する
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	validate(validate) {
		console.log(validate);
		let type = validate;
		if (validate.match(/^max-/)) {
			type = 'max';
		} else if (validate.match(/^min-/)) {
			type = 'min';
		} else if (validate.match(/^range-/)) {
			type = 'range';
		} else if (validate.match(/^len-/)) {
			type = 'len';
		} else if (validate.match(/^retype-/)) {
			type = 'retype';
		} else if (validate.match(/^typebase-/)) {
			type = 'typebase';
		}
		let args = validate.split('-');
		if (this._value === '' && type !== 'empty' && this._type !== 'checkbox') return;
		switch (type) {
			case 'empty':
				this.checkEmpty();
				break;
			case 'nospace':
				this.checkNoSpace();
				break;
			case 'nonumber':
				this.checkNoNumber();
				break;
			case 'nosymbol':
				this.checkNoSymbol();
				break;
			case 'zenkaku':
				this.checkZenkaku();
				break;
			case 'hankaku':
				this.checkHankaku();
				break;
			case 'kana':
				this.checkKana();
				break;
			case 'hiragana':
				this.checkHiragana();
				break;
			case 'katakana':
				this.checkKatakana();
				break;
			case 'number':
				this.checkNumber();
				break;
			case 'alphabet':
				this.checkAlphabet();
				break;
			case 'alnum':
				this.checkAlphabetNumber();
				break;
			case 'url':
				this.checkUrl();
				break;
			case 'email':
				this.checkEmail();
				break;
			case 'date':
				this.checkDate();
				break;
			case 'jancode':
				this.checkJancode();
				break;
			case 'mixpassword':
				this.checkMixPassword();
				break;
			case 'birthdate':
				this.checkBirthDate();
				break;
			case 'max':
				this.checkMax(args[1]);
				break;
			case 'min':
				this.checkMin(args[1]);
				break;
			case 'range':
				this.checkRange(args[1], args[2]);
				break;
			case 'len':
				this.checkLength(args[1]);
				break;
			case 'retype':
				this.checkRetype(args[1]);
				break;
			case 'typebase':
				this.checkTypebase(args[1]);
				break;
			case 'checkboxempty':
				this.checkCheckboxEmpty();
				break;
		}
	};


	/**
	 * エラーを表示する
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	showError() {
		console.log('FormValidateView.showError');
		this._$e.addClass('is-invalid');
		if(this._$e.hasClass('.group2')) {
			$('.group1').addClass('is-invalid');
		}
		let error = '';
		for (let i = 0; i < this._error.length; i++) {
			error += this._error[i];
		}
		this._$errortipInner.html(error);
		// 1 -> -30
		let top = -18 - (this._error.length * 15 - 3);
		this._$errortip.css('top', top + 'px').addClass('show');
	};


	/**
	 * エラーを非表示にする
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	hideError() {
		this._$e.removeClass('is-invalid');
		this._$errortip.removeClass('show').css('top', '-30px');
		this._$errortipInner.html('');
	};


	/**
	 * 郵便番号自動入力の場合、住所エラーを非表示にする
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkAddressError() {
		if((this._$e.hasClass('zip-group1') || this._$e.hasClass('zip-group2')) && this._$e.hasClass('p-postal-code')){
			const zip1 = $('.zip1').val().length;
			const zip2 = $('.zip2').val().length;
			const region = $('.p-region');
			const locality = $('.p-locality');
			const streetAddress = $('.p-street-address');
			const extendedAddress = $('.p-extended-address');
			if(zip1 + zip2 === 7){
				this.removeAddressError(region);
				this.removeAddressError(locality);
				this.removeAddressError(streetAddress);
				this.removeAddressError(extendedAddress);
			}
		}
	};


	/**
	 * 郵便番号自動入力の場合、住所エラーを非表示にする
	 *
	 * @memberof FormValidateView
	 * @params {object} obj - エラー削除対象オブジェクト
	 * @return {undefined}
	 */
	removeAddressError(obj) {
		obj
			.removeClass('is-invalid')
			.closest('.form-group')
			.find('.error-tip')
			.removeClass('show')
			.css('top', '-30px')
			.find('.error-tip-inner')
			.html('');
	};


	/**
	 * 空白チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkEmpty() {
		if (this._type === 'file') {
			if(this._$e.closest('.block-file-input').find('input').attr('data-validate') === 'empty'){
				this._error.push(this._errorMessage['file']);
			}
		}else{
			if (this._value === '') {
				if(this._$e.hasClass('zip-group1') && !$('button[type=submit]').hasClass('clicked')){
					if($('.zip-group2').val() !== ''){
						this._error.push(this._errorMessage['empty']);
					}
				}else{
					this._error.push(this._errorMessage['empty']);
				}
			}
		}
	};


	/**
	 * スペースチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkNoSpace() {
		if (this._value.match(/[　\s]/)) {
			this._error.push(this._errorMessage['nospace']);
		}
	};


	/**
	 * 数字チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkNoNumber() {
		if (this._value.match(/[０-９0-9]/)) {
			this._error.push(this._errorMessage['nonumber']);
		}
	};


	/**
	 * 記号チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkNoSymbol() {
		if (this._value.match(/[！”＃＄％＆’（）＝～｜‘｛＋＊｝＜＞？＿－＾￥＠「；：」、。・!"#\$%&'\(\)=~\|`{\+\*}<>\?_\-\^\\@\[;:\],\.\/\^]/)) {
			this._error.push(this._errorMessage['nosymbol']);
		}
	};


	/**
	 * 全角チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkZenkaku() {
		const valLength  = this._value.length;
		const byteLength = this.getByteLength();
		if (valLength * 2 !== byteLength) {
			this._error.push(this._errorMessage['zenkaku']);
		}
	};


	/**
	 * 半角チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkHankaku() {
		const valLength  = this._value.length;
		const byteLength = this.getByteLength();
		if (valLength !== byteLength) {
			this._error.push(this._errorMessage['hankaku']);
		}
	};


	/**
	 * バイト数確認
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	getByteLength() {
		const m = encodeURIComponent(this._value).match(/%[89ABab]/g);
		return this._value.length + (m ? m.length : 0);
	};


	/**
	 * かなチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkKana() {
		if (!this._value.match(/^[ぁ-んーァ-ヾ０-９\s　！”＃＄％＆’（）＝～｜‘｛＋＊｝＜＞？＿－＾￥＠「；：」、。・]+$/)) {
			this._error.push(this._errorMessage['kana']);
		}
	};


	/**
	 * ひらがなチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkHiragana() {
		if (!this._value.match(/^[ぁ-んー０-９－\s　！”＃＄％＆’（）＝～｜‘｛＋＊｝＜＞？＿－＾￥＠「；：」、。・]+$/)) {
			this._error.push(this._errorMessage['hiragana']);
		}
	};


	/**
	 * カタカナチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkKatakana() {
		console.log('FormValidateView.checkKatakana');
		if (!this._value.match(/^[ァ-ヾ０-９－\s　！”＃＄％＆’（）＝～｜‘｛＋＊｝＜＞？＿－＾￥＠「；：」、。・]+$/)) {
			this._error.push(this._errorMessage['katakana']);
		}
	};


	/**
	 * 数字チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkNumber() {
		if (!this._value.match(/^[0-9\s!"#\$%&'\(\)=~\|`{\+\*}<>\?_\-\^\\@\[;:\],\.\/\^]+$/)) {
			this._error.push(this._errorMessage['number']);
		}
	};


	/**
	 * アルファベットチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkAlphabet() {
		if (!this._value.match(/^[a-zA-Z\s!"#\$%&'\(\)=~\|`{\+\*}<>\?_\-\^\\@\[;:\],\.\/\^]+$/)) {
			this._error.push(this._errorMessage['alphabet']);
		}
	};


	/**
	 * アルファベット・数字チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkAlphabetNumber() {
		if (!this._value.match(/^[0-9a-zA-Z\s!"#\$%&'\(\)=~\|`{\+\*}<>\?_\-\^\\@\[;:\],\.\/\^]+$/)) {
			this._error.push(this._errorMessage['alnum']);
		}
	};


	/**
	 * URLアドレスチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkUrl() {
		console.log('FormValidateView.checkUrl');
		if(!this._$e.val().match(/https?:\/\/[a-zA-Z0-9\-_.:@!~*'(¥);/?&=+$,%#]+/)){
			this._error.push(this._errorMessage['url']);
		}
	};


	/**
	 * メールアドレスチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkEmail() {
		if (!this._$e.val().match(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i)) {
			this._error.push(this._errorMessage['email']);
		}
	};


	/**
	 * 日付チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkDate() {
		if (!this._$e.val().match(/^\d{4}-\d{2}-\d{2}$/)) {
			this._error.push(this._errorMessage['date']);
		}
	};


	/**
	 * JANコードチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkJancode() {
		const val = this._value;
		const digit = val.charAt(12);
		const even = parseInt(val.charAt(1)) + parseInt(val.charAt(3)) + parseInt(val.charAt(5)) + parseInt(val.charAt(7)) + parseInt(val.charAt(9)) + parseInt(val.charAt(11));
		const odd = parseInt(val.charAt(0)) + parseInt(val.charAt(2)) + parseInt(val.charAt(4)) + parseInt(val.charAt(6)) + parseInt(val.charAt(8)) + parseInt(val.charAt(10));
		let total = even * 3 + odd;
		total = new String(total);
		const totalLen = total.length;
		const last = parseInt(total.charAt(totalLen-1));
		let rest;
		if (last === 0){
			rest = 0;
		}else{
			rest = 10 - last;
		}
		if(rest !== digit){
			this._error.push(this._errorMessage['jancode']);
		}
	};


	/**
	 * 英数混在パスワードチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkMixPassword() {
		if (!this._$e.val().match(/[a-zA-Z]/)) {
			this._error.push(this._errorMessage['mixPasswordAlphabet']);
		} else if (!this._$e.val().match(/[0-9]/)) {
			this._error.push(this._errorMessage['mixPasswordNumber']);
		}
	};


	/**
	 * 誕生日チェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkBirthDate() {
		console.log('check birthDate');
		const val = this._$e.val();
		const max = Common.getCurrentDateTime().substr(0,10);
		const maxYear = max.substr(0,4);
		const minYear = maxYear - 80;
		const min = max.replace(maxYear, minYear);
		console.log(min + '/' +  val + '/' + max);
		if (val > max || val < min) {
			this._error.push(this._errorMessage['birthDate']);
		}
	};


	/**
	 * 最大文字数チェック
	 *
	 * @memberof FormValidateView
	 * @param {string} max - 最大文字数
	 * @return {undefined}
	 */
	checkMax(max) {
		if (this._value.length > max) {
			this._error.push(this._errorMessage['max1'] + max + this._errorMessage['max2']);
		}
	};


	/**
	 * 最小文字数チェック
	 *
	 * @memberof FormValidateView
	 * @param {string} min - 最小文字数
	 * @return {undefined}
	 */
	checkMin(min) {
		if (this._value.length < min) {
			this._error.push(this._errorMessage['min1'] + min + this._errorMessage['min2']);
		}
	};


	/**
	 * 文字数最大最小チェック
	 *
	 * @memberof FormValidateView
	 * @param {string} min - 最小文字数
	 * @param {string} max - 最大文字数
	 * @return {undefined}
	 */
	checkRange(min, max) {
		if (this._value.length > max || this._value.length < min) {
			this._error.push(this._errorMessage['range1'] + min + this._errorMessage['range2'] + max + this._errorMessage['range3']);
		}
	};


	/**
	 * 文字数チェック
	 *
	 * @memberof FormValidateView
	 * @param {string} len - 文字数
	 * @return {undefined}
	 */
	checkLength(len) {
		if (parseInt(this._value.length) !== parseInt(len)) {
			this._error.push(this._errorMessage['len1'] + len + this._errorMessage['len2']);
		}
	};


	/**
	 * 入力確認チェック（比較先）
	 *
	 * @memberof FormValidateView
	 * @param {string} id - 比較元ID
	 * @return {undefined}
	 */
	checkRetype(id) {
		const target	  = $('#' + id);
		const targetValue = $.trim(target.val());
		if (!targetValue || targetValue === '') return;
		if (this._value !== targetValue) {
			this._error.push(this._errorMessage['retype']);
		}
	};


	/**
	 * 入力確認チェック（比較元）
	 *
	 * @memberof FormValidateView
	 * @param {string} id - 比較先ID
	 * @return {undefined}
	 */
	checkTypebase(id) {
		const target	  = $('#' + id);
		const targetValue = $.trim(target.val());
		if (!targetValue || targetValue === '') return;
		const validateMsgBox = target.closest('.tr-form-group').find('.tr-form-group__form__validate');
		if (this._value !== targetValue) {
			if (validateMsgBox.find('p').hasClass('retype')) return;
			validateMsgBox.append(this._errorMessage['retype']);
		} else {
			validateMsgBox.find('.retype').remove();
		}
	};

	/**
	 * checkbox emptyチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkCheckboxEmpty() {
		const $formGroupContents = this._$e.closest('.form-group > div');

		let cnt = 0;
		$formGroupContents.find('[data-form=checkboxempty]').each(function (idx, e) {
			const $e = $(e);
			if ($e.prop('checked')) {
				cnt++;
			}
		});
		if (cnt === 0) {
			this._error.push(this._errorMessage['checkboxEmpty']);
		}
	};


	/**
	 * radio emptyチェック
	 *
	 * @memberof FormValidateView
	 * @return {undefined}
	 */
	checkRadioEmpty() {
		console.log('FormValidateView.checkRadioEmpty');
		const $formGroup = this._$e.closest('.form-group');

		if(!$formGroup.find('[data-form=radioempty]:checked')){
			this._error.push(this._errorMessage['radioEmpty']);
		}
	};
}

/**
 * @file 対象変更
 */
'use strict';


export default class FormOpenSearchTargetTypeModalView {
    /**
     * @constructor
     * @param {jQuery} $e - 該当する jQuery オブジェクト
     * @param {object} controller - controllerオブジェクト
     */
    constructor($e, controller) {
        console.log('FormOpenSearchTargetTypeModalView.constructor');
        this._$e = $e;
        this._controller = controller;
        this._alreadyCheckedList = [];
        this._$e.on('click', () => this.openModal());
        this._controller.on('getSelectDisplayListComplete', () => this.setSelectDisplay());
        
        this._alreadyChecked = $('#checked-list input');
        if(this._alreadyChecked.length > 0){
            for(let i = 0; i < this._alreadyChecked.length; i++){
                this._alreadyCheckedList.push($(this._alreadyChecked[i]).val());
            }
        }
    };


    /**
     * モーダルを開く
     *
     * @memberof FormOpenSearchTargetTypeModalView
     * @return {undefined}
     */
    openModal(){
        console.log('FormOpenSearchTargetTypeModalView.openModal');
        this._controller._isOpenModalIdx = $('[name="target_type"]:checked').val();
        this._controller._embedWord = this._controller._isOpenModalIdx === '2' ? '店舗' : '企業'
        // this._controller._embedWord = this._controller._isOpenModalIdx == '2' ? '店舗' : this._controller._isOpenModalIdx == '3' ? '企業' : 'エリア'
        this._toggleState = true;
        const $modal = `
<div id="search-target-modal" class="searchModalBg">
    <div class="searchModal">
        <div class="searchModal__header">
            <div class="searchModal__header--close">
                <p>×</p>
            </div>
        </div>
        <div class="searchModal__contents">
            <div class="searchModal__contents--search">
                <div class="searchModal__contents--search-input">
                    <p>${this._controller._embedWord}名</p><input type="text" class="form-control" />
                </div>
                <div class="searchModal__contents--search-btn">
                    <button type="button" class="btn btn-main search-by-input"><i class="fas fa-search mr10"></i>検索する</button>
                </div>
            </div>
            <div class="searchModal__contents--select">
                <div class="searchModal__contents--select-operation">
                    <button type="button" class="btn btn-primary all-check-toggle">全選択 / 解除</button>
                    <button type="button" class="btn btn-primary reset-search-by-input">検索リセット</button>
                </div>
                <div class="searchModal__contents--select-display">
                </div>
            </div>
        </div>
        <div class="searchModal__footer">
            <div class="searchModal__footer--btns">
                <button type="button" class="btn btn-secondary searchModal__footer--btns-cancel">キャンセル</button><button type="button" class="btn btn-main searchModal__footer--btns-confirm">決定</button>
            </div>
        </div>
    </div>
</div>
        `;
        $('body').append($modal);
        this.getSelectDisplayList();
        this.setBtns();
        $('.searchModal__contents--search-input input').on('blur', e => {
            if($(e.target).val() === '') this.resetSearchByInput();
        });
    }


    /**
     * リストの取得
     *
     * @memberof FormOpenSearchTargetTypeModalView
     * @return {undefined}
     */
    getSelectDisplayList(){
        console.log('FormOpenSearchTargetTypeModalView.getSelectDisplayList');
        this._controller.getSelectDisplayList(this._controller._isOpenModalIdx);
    }


    /**
     * 取得したリストをモーダル内に埋め込む
     *
     * @memberof FormOpenSearchTargetTypeModalView
     * @return {undefined}
     */
    setSelectDisplay(){
        console.log('FormOpenSearchTargetTypeModalView.setSelectDisplay');
        const $wrap = $('.searchModal__contents--select-display');
        const selectDisplayList = this._controller._selectDisplayList;
        let checkboxes = '';
        if($('#checked-list').html() === '') this._alreadyCheckedList = [];
		console.log(`_isOpenModalIdx: ${this._controller._isOpenModalIdx}`);
		for(let i = 0; i < selectDisplayList.length; i++){
            if(this._alreadyCheckedList.length){
                let flag = false;
                for(let k = 0; k < this._alreadyCheckedList.length; k++){
                    switch(this._controller._isOpenModalIdx){
                        case '2':
                            console.log('case 2');
                            if(this._alreadyCheckedList[k] === selectDisplayList[i].shop_id){
                                checkboxes += 
`<div>
    <input id="select-display-list-${i}" type="checkbox" name="select-display-list" value="${selectDisplayList[i].shop_id}" data-shop-name="${selectDisplayList[i].shop_name}" checked><label for="select-display-list-${i}">${selectDisplayList[i].shop_name}（${selectDisplayList[i].company_name}）</label>
</div>`;
                                flag = true;
                            }
                            break;
                        case '3':
							console.log('case 3');
							if(this._alreadyCheckedList[k] === selectDisplayList[i].company_id){
                                checkboxes += 
`<div>
    <input id="select-display-list-${i}" type="checkbox" name="select-display-list" value="${selectDisplayList[i].company_id}" data-company-name="${selectDisplayList[i].company_name}" checked><label for="select-display-list-${i}">${selectDisplayList[i].company_name}</label>
</div>`
                                flag = true;
                            }
                            break;
                        // エリア実装するとき追加
                        // case '4':
                        //     break;
                        default:
                            console.log('FormOpenSearchTargetTypeModalView.setSelectDisplay.switchError');
                    }
                } // for文ここまで
                if(flag == false){
                    checkboxes += this._controller._isOpenModalIdx === '2'
? `<div>
    <input id="select-display-list-${i}" type="checkbox" name="select-display-list" value="${selectDisplayList[i].shop_id}" data-shop-name="${selectDisplayList[i].shop_name}"><label for="select-display-list-${i}">${selectDisplayList[i].shop_name}（${selectDisplayList[i].company_name}）</label>
</div>`
: `<div>
    <input id="select-display-list-${i}" type="checkbox" name="select-display-list" value="${selectDisplayList[i].company_id}" data-company-name="${selectDisplayList[i].company_name}"><label for="select-display-list-${i}">${selectDisplayList[i].company_name}</label>
</div>`
                }
            } else {
                checkboxes += this._controller._isOpenModalIdx === '2'
? `<div>
    <input id="select-display-list-${i}" type="checkbox" name="select-display-list" value="${selectDisplayList[i].shop_id}" data-shop-name="${selectDisplayList[i].shop_name}"><label for="select-display-list-${i}">${selectDisplayList[i].shop_name}（${selectDisplayList[i].company_name}）</label>
</div>`
: `<div>
    <input id="select-display-list-${i}" type="checkbox" name="select-display-list" value="${selectDisplayList[i].company_id}" data-company-name="${selectDisplayList[i].company_name}"><label for="select-display-list-${i}">${selectDisplayList[i].company_name}</label>
</div>`
            }
        }
        $wrap.html(checkboxes);
        $('.searchModal__footer--btns-confirm').on('click', () => this.insertCheckedList());
    }


    /**
     * 各ボタンのイベントをセット
     *
     * @memberof FormOpenSearchTargetTypeModalView
     * @return {undefined}
     */
    setBtns(){
        $('.searchModal__header--close, .searchModal__footer--btns-cancel').on('click', () => {
            $('#search-target-modal').remove();
        });
        $('.all-check-toggle').on('click', () => {
            console.log('FormOpenSearchTargetTypeModalView.checkToggle');
            const $list = $('[name="select-display-list"]');
            if(this._toggleState){
                for(let i = 0; i < $list.length; i++){
                    if($($list[i]).css('display') == 'block') $($list[i]).attr('checked', true).prop('checked', true).change();
                }
                this._toggleState = !this._toggleState;
            }else{
                for(let i = 0; i < $list.length; i++){
                    if($($list[i]).css('display') == 'block') $($list[i]).removeAttr('checked').prop('checked', false).change();
                }
                this._toggleState = !this._toggleState;
            }
        });
        $('.search-by-input').on('click', () => this.searchByInput());
        $('.reset-search-by-input').on('click', () => this.resetSearchByInput());
    }


    /**
     * チェックしたものをhiddenでページ内に埋め込み
     *
     * @memberof FormOpenSearchTargetTypeModalView
     * @return {undefined}
     */
    insertCheckedList(){
        console.log('FormOpenSearchTargetTypeModalView.insertCheckedList');
        const $checkedList = $('[name="select-display-list"]:checked');
        let
            hiddenList = '',
            displayList = '';
        this._alreadyCheckedList = [];
        for(let i = 0; i < $checkedList.length; i++){
            hiddenList += this._controller._isOpenModalIdx === '2'
? `<input type="hidden" name="target_type_shop[]" value="${$checkedList[i].getAttribute('value')}" />`
: `<input type="hidden" name="target_type_company[]" value="${$checkedList[i].getAttribute('value')}" />`
            this._alreadyCheckedList.push($checkedList[i].getAttribute('value'));
            if(i < 2){
                i === 0
                    ? this._controller._isOpenModalIdx === '2'
                        ? displayList += `${$checkedList[i].getAttribute('data-shop-name')}`
                        : displayList += `${$checkedList[i].getAttribute('data-company-name')}`
                    : this._controller._isOpenModalIdx === '2'
                        ? displayList += ` / ${$checkedList[i].getAttribute('data-shop-name')}`
                        : displayList += ` / ${$checkedList[i].getAttribute('data-company-name')}`
                }
            if(i === $checkedList.length-1) displayList += ` …… （全${$checkedList.length}${this._controller._embedWord}選択中)`;
        }
        const insertList = new Promise((resolve, reject) => {
            $('#checked-list').html(hiddenList);
            resolve();
        });
        $('#display_checked').val(displayList);
        insertList.then(() => $('#search-target-modal').remove());
        $('#display_checked').blur();
        console.log(this._alreadyCheckedList);
    }


    /**
     * 検索ワードで絞り込み
     *
     * @memberof FormOpenSearchTargetTypeModalView
     * @return {undefined}
     */
    searchByInput(){
        console.log('FormOpenSearchTargetTypeModalView.searchByInput');
        const input = $('.searchModal__contents--search-input input').val();
        if(!input) return;
        const $rows = $('.searchModal__contents--select-display > div');
        for(let i = 0; i < $rows.length; i++){
            $($rows[i]).show();
        }
        for(let i = 0; i < $rows.length; i++){
            this._controller._isOpenModalIdx === '2'
                ? $($rows[i]).find('input').attr('data-shop-name').search(input) === -1 && $($rows[i]).hide()
                : $($rows[i]).find('input').attr('data-company-name').search(input) === -1 && $($rows[i]).hide()
        }
    }


    /**
     * 検索状態をリセット
     *
     * @memberof FormOpenSearchTargetTypeModalView
     * @return {undefined}
     */
    resetSearchByInput(){
        console.log('FormOpenSearchTargetTypeModalView.resetSearchByInput');
        const $rows = $('.searchModal__contents--select-display > div');
        for(let i = 0; i < $rows.length; i++){
            $($rows[i]).show();
        }
        $('.searchModal__contents--search-input input').val('');
    }
}
/**
 * @file ファイルドロップ
 */
'use strict';

import ApplyClass from '../../util/_apply-class';
import Common from '../../util/_common';
import FormValidateView from './_form-validate-view';
import FormDeleteImageView from './_form-delete-image-view';
import Template from '../../template/_template';

/**
 * @classname FormFileDropView
 * @classdesc ファイルドロップ
 */
export default class FormFileDropView {
	/**
	 * @constructor
	 * @param {object} controller - コントローラー
	 * @param {jQuery} $e - 該当する jQuery オブジェクト
	 */
	constructor($e, controller) {
		console.log('FormFileDropView.constructor');
		this._$e = $e;
		this._controller = controller;
		this._template = new Template();
		this._$blockFileUpload = $e.closest('.block-file-upload');
		this._$inputFile = this._$blockFileUpload.find('input');
		this._$inputFileWrap = this._$blockFileUpload.find('.block-file-input');
		this._inputFile = '<input type="file" accept="image/*" style="display: none;">';
		this._$modal = $('#modal-image-select');
		this._type = $e.attr('data-type');
		this._orientation = 1;
		this._$photoArea = $e.closest('.form-group').find('.block-photo-area');
		$(window).on('drop dragover', (e) => {
			e.preventDefault();
			e.stopPropagation();
		});
		this._$e.on('dragover', e => this.onDragover(e));
		this._$e.on('dragleave', () => this.onDragleave());
		this._$e.on('drop', e => this.onDrop(e));
		this._$e.on('click', e => this.onClick(e));
		this._controller.on('uploadImageComplete', () => {
			if(this._type === this._controller._type) this.onUploadImageComplete();
		});
		this._$inputFile.on('change', e => this.onChange(e));
		this._controller.on('deleteImageComplete', () => this.onDeleteImageComplete());
	};

	/**
	 * クリックのコールバック
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	onClick(e){
		console.log('FormFileDropView.onClick');
		e.preventDefault();
		e.stopPropagation();
		console.log('inputFile:');
		console.log(this._$inputFile);
		this._$inputFile.click();
	}
	/**
	 * ドラッグオーバーのコールバック
	 *
	 * @memberof FormFileDropView
	 * @param {object} e - イベント
	 * @return {undefined}
	 */
	onDragover(e) {
		e = e.originalEvent;
		e.dataTransfer.dropEffect = 'copy';
		e.preventDefault();
		this._$e.addClass('dragover');
	};


	/**
	 * ドラッグリーブのコールバック
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	onDragleave() {
		this._$e.removeClass('dragover');
	};


	/**
	 * ドロップのコールバック
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	onDrop(e) {
		console.log('FormFileDropView.onDrop');
		e = e.originalEvent;
		e.preventDefault();
		this._$e.removeClass('dragover');
		this._files = e.dataTransfer.files;
		if(this._files.length > 1) {
			Common.showErrorModal('画像は1枚ずつあげてください。');
			return;
		}
		const file = this._files[0];
		const maxMB = window.const.file.MAX_MB;
		const maxSize = maxMB * 1024 * 1024;
		if (file.size > maxSize) {
			Common.showErrorModal(`画像サイズは${maxMB}MB以下の必要があります。`);
			return;
		}
		this._controller.uploadImage(file, this._type);
	};


	/**
	 * ファイル変更のコールバック
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	onChange(e) {
		console.log('FormFileDropView.onChange');
		const file = e.target.files[0];
		const maxMB = window.const.file.MAX_MB;
		const maxSize = maxMB * 1024 * 1024;
		if (file.size > maxSize) {
			Common.showErrorModal(`画像サイズは${maxMB}MB以下の必要があります。`);
			this.onDeleteImageComplete();
			return;
		}
		const reader = new FileReader();
		reader.onload = this._controller.uploadImage(file, this._type);
	};


	/**
	 * 画像の表示
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	onUploadImageComplete() {
		console.log('FormFileDropView.onUploadImageComplete');
		const imageData = this._controller._imageData;
		let img = `<div class="upload-image-wrap"><div class="upload-image" style="background: #f2f2f2 url(${window.const.URL.S3_PATH}${imageData.key}) no-repeat 50% 50%/contain;"></div><a href="javascript:void(0);" class="anchor anchor-delete-image" data-image-key="${imageData.key}">&times;</a><input type="hidden" name="${window.const.file.FILE_NAME}[${this._type}][]" value="${imageData.key}"></div>`;
		if(this._type === '4'){
			img = `<div class="upload-image-wrap"><div class="upload-image" style="background: #f2f2f2 url(${window.const.URL.S3_PATH}${imageData.key}) no-repeat 50% 50%/contain;"></div><a href="javascript:void(0);" class="anchor anchor-delete-image" data-image-key="${imageData.key}">&times;</a><input type="hidden" name="${window.const.file.FILE_NAME}" value="${imageData.key}"></div>`;
		}
		this._$photoArea.html(img);
		this._$e.closest('.form-group').find('.error-tip').removeClass('show').find('.error-tip-inner').text('');
		this._$inputFileWrap.html(this._inputFile);
		this._$inputFile = this._$blockFileUpload.find('input');
		this._$inputFile.on('change', e => this.onChange(e));
		const $deleteImage = $('.anchor-delete-image');
		$deleteImage.off();
		$deleteImage.each((idx, e) => {
			const $e = $(e);
			ApplyClass.apply(FormDeleteImageView, $e, [$e, this._controller])
		});
	};


	/**
	 * 画像の削除
	 *
	 * @memberof FormFileDropView
	 * @return {undefined}
	 */
	onDeleteImageComplete() {
		console.log('FormFileDropView.onDeleteImageComplete');
		this._$inputFileWrap.html(this._inputFile);
		this._$inputFile = this._$blockFileUpload.find('input');
		const $fileValidate = $('.file-validate');
		$fileValidate.off('change');
		$fileValidate.each((idx, e) => {
			const $e = $(e);
			ApplyClass.apply(FormValidateView, $e, [$e, this._controller]);
		});
	};
}
